import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Courses from './Courses'
import Course from './Course'

const CourseRouter = ({ match: { path } }) => {
  return (
    <Switch>
      <Route path={path + '/:coursePath'} component={Course} />
      <Route path={path + '/'} component={Courses} />
    </Switch>
  )
}

export default CourseRouter
