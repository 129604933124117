import React from 'react'
import { Link } from 'react-router-dom'
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Translate } from '@lefapps/translations'

const ContentLanguageSwitcher = ({ translations, path, type }) => {
  return (
    <UncontrolledDropdown className='contentTranslationSwitcher'>
      <DropdownToggle nav caret data-cy='content_translation_switch'>
        <FontAwesomeIcon icon='flag' />
      </DropdownToggle>
      <DropdownMenu>
        <div className='dropdown-header'>
          <Translate _id='languageSwitcher/desc'>
            Deze content is ook in andere talen beschikbaar:
          </Translate>
          <ul className='dropdown-item text-body'>
            {translations.map((translation, i) => {
              if (translation.path === path || !translation.originId)
                return null
              else {
                return (
                  <div key={i}>
                    <Link to={`/${type}/${translation.path}`}>
                      {translation.language}
                    </Link>
                    <br />
                  </div>
                )
              }
            })}
          </ul>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default ContentLanguageSwitcher
