import React from 'react'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const readableDate = date => {
  return moment(date).format('DD/MM/YYYY')
}
export const readableTime = date => {
  return moment(date).format('HH:mm')
}

// Creates basic date block with calendar icon, starting date, and enddate
export const basicDate = dates => {
  return (
    <div className='infobox'>
      <div className='row'>
        <div className='col-md-1'>
          <FontAwesomeIcon icon='calendar-alt' />
        </div>
        {dates.map(date => {
          return (
            <div className='col-md-5' key={date}>
              {readableDate(date)}
              <br />
              {readableTime(date)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const validateDates = (startdate, enddate) => {
  if (moment(enddate).isBefore(startdate)) return false
  else return true
}
