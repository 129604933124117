import { gql } from 'apollo-boost'
import { sectionParts } from '../_shared/fragments'

const ADMIN_SECTION = gql`
  query Section($path: String!) {
    section(path: $path) {
      ...SectionParts
      classroom
      owners {
        _id
      }
      modules {
        path
        name
      }
    }
  }
  ${sectionParts}
`

const ADMIN_SECTIONS = gql`
  query Sections {
    sections {
      ...SectionParts
    }
  }
  ${sectionParts}
`

export { ADMIN_SECTION, ADMIN_SECTIONS }
