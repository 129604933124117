import React from 'react'

import { render } from '../../_helpers/markdown'

import { Image } from '../_shared/Image'

import { Container } from '../_layout/Containers'
import { Grid, Main, Aside } from '../_layout/Grid'
import { Icon } from '../_layout/Bits'
import { TeamList } from '../_layout/TeamList'

export const Team = ({ team = {}, owners, ...props }) => {
  const { image, info } = team
  return (
    <Grid collapse={'tablet'}>
      <Main>
        {(owners && !!owners.length && (
          <Container order={1} vertical>
            <TeamList members={owners} />
          </Container>
        )) ||
          null}
      </Main>
      <Aside>
        <Container order={0} vertical>
          <h3>
            <Icon icon={'team'} /> Team
          </h3>
        </Container>
        {image && (
          <Container vertical order={0}>
            <Image {...image} context={'aside'} />
          </Container>
        )}
        {info && (
          <Container order={0} vertical>
            {render(info)}
          </Container>
        )}
      </Aside>
    </Grid>
  )
}
