import pull from 'lodash/pull'

const checkCompletionStatus = (content, result) => {
  // Further finetune exact completion status, return false if not all content has been handled
  // Does a value exist for each bit of content with type of question?
  if (!result) return false
  const allQuestions = content
    .map(pageItem => (pageItem.type === 'question' ? pageItem.name : null))
    .sort()
  const answeredQuestions = pull(
    Object.keys(result).map(name =>
      name !== 'updatedAt' && name !== '__scoring' ? name : null
    ),
    null
  ).sort()
  let unAnswered
  allQuestions.map(mustBeAnswered => {
    if (mustBeAnswered === null) {
      return null
    } // Early exit
    if (!answeredQuestions.includes(mustBeAnswered)) {
      return (unAnswered = true) // We are missing an answer
    } else return null
  })
  if (unAnswered) return false
  return true
}

export default checkCompletionStatus
