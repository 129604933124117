import { library } from '@fortawesome/fontawesome-svg-core'

import { faAlignLeft } from '@fortawesome/free-solid-svg-icons/faAlignLeft'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faAward } from '@fortawesome/free-solid-svg-icons/faAward'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faBold } from '@fortawesome/free-solid-svg-icons/faBold'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp'
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons/faChalkboardTeacher'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs'
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns'
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload'
import { faFilm } from '@fortawesome/free-solid-svg-icons/faFilm'
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag'
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons/faFlagCheckered'
import { faGripLines } from '@fortawesome/free-solid-svg-icons/faGripLines'
import { faHeading } from '@fortawesome/free-solid-svg-icons/faHeading'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faItalic } from '@fortawesome/free-solid-svg-icons/faItalic'
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink'
import { faList } from '@fortawesome/free-solid-svg-icons/faList'
import { faListOl } from '@fortawesome/free-solid-svg-icons/faListOl'
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons/faMinusSquare'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faPhotoVideo } from '@fortawesome/free-solid-svg-icons/faPhotoVideo'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons/faPlusSquare'
import { faPollH } from '@fortawesome/free-solid-svg-icons/faPollH'
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons/faPuzzlePiece'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort'
import { faSplotch } from '@fortawesome/free-solid-svg-icons/faSplotch'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch'
import { faStrikethrough } from '@fortawesome/free-solid-svg-icons/faStrikethrough'
import { faSwatchbook } from '@fortawesome/free-solid-svg-icons/faSwatchbook'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt'
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable'
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons/faUndoAlt'
import { faUnlink } from '@fortawesome/free-solid-svg-icons/faUnlink'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends'
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons/faUserGraduate'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench'

// custom icons
import { lefCourse, lefSection, lefModule, lefPage, lefFile } from './custom'

library.add(
  faColumns,
  faFileAlt,
  faLocationArrow,
  faSplotch,
  faUserFriends,
  faUserGraduate,
  lefCourse,
  lefSection,
  lefModule,
  lefPage,
  lefFile
)

// e-learning: general icons
library.add(
  faAlignLeft,
  faArrowRight,
  faAward,
  faColumns,
  faChalkboardTeacher,
  faFileAlt,
  faLocationArrow,
  faSplotch,
  faUserGraduate,
  faBook,
  faBookOpen,
  faPhone,
  faPuzzlePiece,
  faStopwatch,
  faSwatchbook,
  faFileDownload,
  faPaperPlane,
  faDownload
)

// course view
library.add(faCalendarAlt, faMinusSquare, faPlusSquare, faSyncAlt, faCheck)

// module view
library.add(faListUl, faTasks, faFilm, faPhotoVideo, faLock, faFlagCheckered)

// navbar
library.add(faBars, faCogs, faHome, faUser)

// admin
library.add(
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCopy,
  faEdit,
  faEye,
  faFlag,
  faImage,
  faLink,
  faPen,
  faPlus,
  faSort,
  faTimes,
  faTrashAlt,
  faUnlink,
  faUsers,
  faInfoCircle,
  faExclamationCircle,
  faQuestionCircle,
  faCheckCircle,
  faWrench,
  faPollH,
  faUndoAlt,
  faSearch
)

// Text editor
library.add(
  faArrowLeft,
  faBold,
  faEye,
  faHeading,
  faGripLines,
  faItalic,
  faLink,
  faList,
  faListOl,
  faQuoteRight,
  faStrikethrough,
  faTable
)

export { library }
