import React from 'react'
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { Translate } from '@lefapps/translations'

export const StaticPageMenu = ({ pages }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle nav data-cy='userui_dropdown'>
        <FontAwesomeIcon icon='bars' />
      </DropdownToggle>
      <DropdownMenu right>
        {pages.map(page => {
          return (
            <Link
              key={page.path}
              to={`/${page.path}`}
              className='dropdown-item text-body'
            >
              <Translate _id={`staticPage/${page.label}`}>
                {page.label}
              </Translate>
            </Link>
          )
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
