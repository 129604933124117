import { gql } from 'apollo-boost'
import { moduleParts, pageParts } from '../_shared/fragments'

// Querying module as admin
const ADMIN_MODULE = gql`
  query Module($path: String!) {
    module(path: $path) {
      ...ModuleParts
      public
      owners {
        _id
      }
      pages {
        ...PageParts
      }
    }
  }
  ${moduleParts}
  ${pageParts}
`

// Querying modules as admin
const ADMIN_MODULES = gql`
  query Modules(
    $course: String
    $section: String
    $filterByParent: String
    $onAdmin: Boolean
  ) {
    modules(
      course: $course
      section: $section
      filterByParent: $filterByParent
      onAdmin: $onAdmin
    ) {
      ...ModuleParts
      owners {
        _id
      }
    }
    course(path: $course) {
      path
      name
      modules {
        ...ModuleParts
      }
      owners {
        _id
      }
    }
    section(path: $section) {
      path
      name
      modules {
        ...ModuleParts
      }
    }
  }
  ${moduleParts}
`

export { ADMIN_MODULE, ADMIN_MODULES }
