import { gql } from 'apollo-boost'
import { pageParts, moduleParts } from '../../_shared/fragments'

// Querying module
const PAGE = gql`
  query Page($path: String!) {
    page(path: $path) {
      ...PageParts
      estimatedTime
      module {
        ...ModuleParts
        showCorrect
        gateProgress
        gateConfig
        pages {
          path
          name
          completed
        }
      }
    }
  }
  ${pageParts}
  ${moduleParts}
`

export { PAGE }
