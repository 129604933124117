import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem
} from '@lefapps/admin-dashboard'

import { useQuery } from '@apollo/react-hooks'
import { queries, Editor, Translate } from '@lefapps/translations'

// import config from '../../config'
// const { assets = '' } = config.storage || {}

// const isImage = type => ['jpg', 'jpeg', 'png', 'webp'].includes(type)

export const Translation = ({ _id }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  // Delete file
  const alert = useAlert()

  // Components
  const actions = { edit: { onClick: toggle } }
  const name = (_id && _id.split('/')) || []

  return (
    <BoardListItem label={name.shift()} actions={actions}>
      {!!name.length && name.join(' › ')}
      {modal && (
        <Editor _id={_id} toggle={toggle} isOpen={modal} alert={alert} />
      )}
    </BoardListItem>
  )
}

export const TranslationsList = ({ items = [] }) => {
  if (items.length <= 0) {
    return (
      <BoardListItem label={0} actions={{}}>
        <Translate _id='translations/no_translations'>
          Geen vertalingen
        </Translate>
      </BoardListItem>
    )
  }
  return items.map((item, index) => (
    <Translation key={item._id} index={index} {...item} />
  ))
}

export const Translations = () => {
  const { loading, error, data } = useQuery(queries.TRANSLATION_LIST)

  if (loading) {
    return <BoardBody loading />
  }

  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_translations'>
          Er ging iets mis bij het laden.
        </Translate>
      </BoardBody>
    )
  }

  return (
    <>
      <BoardHead
        title={`Vertalingen (${data &&
          data.translations &&
          data.translations.length})`}
      />
      <BoardBody>
        <BoardList>
          <TranslationsList items={data && data.translations} />
        </BoardList>
      </BoardBody>
    </>
  )
}

export default Translations
