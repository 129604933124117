import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Form, FormGroup, Input, Label, Button, Row, Col } from 'reactstrap'
import has from 'lodash/has'

import { InfoBox } from '../../App/_layout/InfoBox'

import config from '../../App/config'
const { matomo } = config

const cookieName = 'matomoOptIn'
const cookieOptions = {
  path: '/',
  expires: new Date(Date.now() + 30 * 24 * 60 * 60),
  maxAge: 30 * 24 * 60 * 60,
  // secure: true,
  sameSite: 'strict'
}

const initMatomo = container => {
  var _mtm = (window._mtm = window._mtm || [])
  _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
  var d = document,
    g = d.createElement('script'),
    s = d.getElementsByTagName('script')[0]
  g.async = true
  g.src = 'https://cdn.matomo.cloud/' + container
  s.parentNode.insertBefore(g, s)
}

const OptIn = ({ onChange, allowCookies = '', allowTracking = '' }) => {
  const [cookie, setCookie] = useState(!!allowCookies)
  const [track, setTrack] = useState(!!allowTracking)
  return (
    <InfoBox
      title={
        'This site uses Matomo to analyze traffic and help us to improve your user experience.'
      }
      icon={'info'}
      color={'info'}
    >
      <p>
        We process your IP address and cookies are stored on your browser for 13
        months. This data is only processed by us. Please read our{' '}
        <a href={'/privacy'} target={'_blank'} rel='noopener noreferrer'>
          Privacy Policy
        </a>{' '}
        to learn more.
      </p>
      <p>
        You can change your choices at all times by going to your profile page.
      </p>

      <hr />

      <Row>
        <Col sm={8}>
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type='checkbox'
                  // role='switch'
                  checked={!!cookie}
                  onClick={() => {
                    const value = !cookie
                    if (!value) setTrack(false)
                    setCookie(value)
                  }}
                />
                Remember my choice (saves a cookie)
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  disabled={!cookie}
                  type='checkbox'
                  role='switch'
                  checked={!!track}
                  onClick={() => setTrack(!track)}
                />
                Allow tracking (requires cookies)
              </Label>
            </FormGroup>
          </Form>
        </Col>
        <Col sm={4}>
          <Button color={'success'} onClick={() => onChange(!!cookie, !!track)}>
            {'Confirm choice'}
          </Button>
        </Col>
      </Row>
    </InfoBox>
  )
}

export const Matomo = ({ optIn = false, force = false, children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([cookieName])
  const hasOpted = has(cookies, cookieName)
  const value = hasOpted ? !!Number(cookies[cookieName]) : false

  // manage cookie according to chosen value
  const manageCookies = (allowCookies, allowTracking) => {
    if (!allowCookies) return removeCookie(cookieName)
    setCookie(cookieName, !!allowTracking ? 1 : 0, cookieOptions)
  }

  const [opted, setOpted] = useState(false) // user has chosen in this session
  const [optedIn, setOptin] = useState(optIn ? value : true) // chosen value

  // renew chosen choice on visit when cookie exists
  // manageCookies(!!hasOpted, cookies[cookieName])

  useEffect(() => {
    matomo && optedIn && initMatomo(matomo)
  }, [optedIn])

  // resolve current choice
  const optInMatomo = (allowCookies, allowTracking) => {
    setOpted(true)
    manageCookies(allowCookies, allowTracking, value)
    setOptin(allowTracking)
  }

  return (
    <>
      {force ? (
        <OptIn
          onChange={optInMatomo}
          allowCookies={!!hasOpted}
          allowTracking={!!value}
        />
      ) : (
        optIn &&
        !hasOpted &&
        !opted && (
          <OptIn
            onChange={optInMatomo}
            allowCookies={!!hasOpted}
            allowTracking={!!value}
          />
        )
      )}
      {children}
    </>
  )
}

export default Matomo
