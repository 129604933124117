// Guard checks if you are allowed to perform a specific action. Returns false if you are not allowed, which you can use to hide the action in the frontend
const guard = ({ user, content, action }) => {
  // Exception to rule logic: edit and delete are always allowed but sometimes only on owned content
  // All other rules are binary: either always allowed or never allowed, regardless of ownership
  let result = false
  // Early exit for admin
  if (user.roles && user.roles.includes('admin')) return true
  const rules = user.rules
  if (rules[action] === true) {
    // The action is included as true in the ruleset
    result = true
  }
  if (action === 'edit' || action === 'delete') {
    // early exit: edit or delete is true, allow on all content
    if (rules[action] === true) return true
    // let's first do top level owner (course || module)
    result = false
    if (content.owners) {
      content.owners.map(owner => {
        if (owner._id === user._id) {
          result = true
          return result
        }
        return result
      })
    }
    return result
  }
  return result
}

export default guard
