import { gql } from 'apollo-boost'
import { moduleParts } from './_shared/fragments'

const LATEST_ACTIVITY = gql`
  query LatestActivity {
    latestActivity {
      ...ModuleParts
    }
  }
  ${moduleParts}
`

const LOG = gql`
  query Log {
    log {
      user
      courses
      sections
      files
      certificates
      lastLogin
    }
  }
`

export { LATEST_ACTIVITY, LOG }
