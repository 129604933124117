export default function getPageIcon (type, title) {
  switch (type) {
    case 'markdown':
      return 'align-left'
    case 'question':
      return 'question-circle'
    case 'video':
      return 'film'
    case null:
      return 'align-left'
    default:
      return 'align-left'
  }
}
