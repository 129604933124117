import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAlert } from 'react-alert'
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from 'react-sortable-hoc'
import cloneDeep from 'lodash/cloneDeep'
import {
  BoardBody,
  BoardHead,
  DashboardLink,
  BoardList,
  BoardListItem
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_MODULES } from './queries'
import { DELETE_MODULE } from './mutations'
import { UPDATE_SECTION } from '../Sections/mutations'
import { UPDATE_COURSE } from '../Courses/mutations'
import { REMOVE_ITEM_FROM_PARENT } from '../../mutations'
import { ADMIN_COURSE } from '../Courses/queries'
import { ADMIN_SECTION } from '../Sections/queries'
import { CURRENT_USER_ADMIN } from '../Users/queries'

import config from '../../config'

import guard from '../_shared/guard'
import { sortTranslatedContent } from '../_shared/TranslateContents'

const SortableBoardListItem = SortableElement(BoardListItem)
const SortableBoardList = SortableContainer(BoardList)
const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={'grip-lines'} />)

const ModuleItem = ({
  user,
  module,
  parentPath,
  parentType,
  removeItemMutation: [
    removeItemFromParent,
    { loading: unlinkLoading, error: unlinkError }
  ],
  index,
  history
}) => {
  const alert = useAlert()
  // Mutations
  const [deleteModule, { loading, error }] = useMutation(DELETE_MODULE, {
    onCompleted: ({ deleteModule: { success, message } }) =>
      alert.show(message, { type: success ? 'success' : 'danger' }),
    update: (
      cache,
      {
        data: {
          deleteModule: {
            module: { path: deletedPath }
          }
        }
      }
    ) => {
      const variables = {
        path: parentPath
      }
      let data = {}

      if (parentType && parentType === 'section') {
        data = cloneDeep(cache.readQuery({ query: ADMIN_SECTION, variables }))
        data.section.modules = data.section.modules.filter(
          ({ path }) => path !== deletedPath
        )
        cache.writeQuery({ query: ADMIN_SECTION, variables, data })
      } else if (parentType && parentType === 'course') {
        data = cloneDeep(cache.readQuery({ query: ADMIN_COURSE, variables }))
        data.course.modules = data.course.modules.filter(
          ({ path }) => path !== deletedPath
        )
        cache.writeQuery({ query: ADMIN_COURSE, variables, data })
      } else {
        data = cloneDeep(cache.readQuery({ query: ADMIN_MODULES }))
        data.modules = data.modules.filter(({ path }) => path !== deletedPath)
        cache.writeQuery({ query: ADMIN_MODULES, data })
      }
    }
  })

  // Methods
  const remove = () => {
    deleteModule({ variables: { path: module.path } })
  }

  const duplicate = () => {
    const initialModel = cloneDeep(module)
    initialModel.name = `Duplicate of ${module.name}`
    delete initialModel['path']
    history.push('modules/new-module', { initialModel })
  }

  const unlink = () => {
    removeItemFromParent({
      variables: {
        itemType: 'module',
        itemPath: module.path,
        parentType,
        parentPath
      }
    })
  }

  // Components (with guard)
  let actions = []
  if (parentPath) {
    actions.unlink = {
      onClick: unlink,
      loading: unlinkLoading,
      error: unlinkError
    }
  }
  if (guard({ user, content: module, action: 'edit' })) {
    actions.edit = { to: `modules/${module.path}` }
  }
  if (guard({ user, action: 'create' })) {
    actions.duplicate = {
      onClick: duplicate,
      type: 'duplicate'
    }
  }
  if (guard({ user, content: module, action: 'delete' })) {
    actions.remove = {
      onClick: remove,
      'data-type': `${module.path}__remove`,
      loading,
      error
    }
  }

  // Render
  return (
    <SortableBoardListItem
      label={<DragHandle />}
      index={index}
      actions={actions}
    >
      <span className={module.class}>
        {module.name}
        <br />
        {module.label && <small> {module.label}</small>}
      </span>
    </SortableBoardListItem>
  )
}

const Modules = ({
  match: {
    params: { coursePath, sectionPath }
  },
  history
}) => {
  const alert = useAlert()

  // Queries
  const queryVars = {
    course: coursePath,
    section: sectionPath,
    filterByParent: (sectionPath && 'section') || (coursePath && 'course'),
    onAdmin: true
  }

  const { loading, error, data } = useQuery(ADMIN_MODULES, {
    variables: queryVars
  })
  const { loading: userLoading, data: currentUserData } = useQuery(
    CURRENT_USER_ADMIN
  )

  const [updateCourse] = useMutation(UPDATE_COURSE)

  const [updateSection] = useMutation(UPDATE_SECTION)

  const removeItemMutation = useMutation(REMOVE_ITEM_FROM_PARENT, {
    onCompleted: ({ removeItemFromParent: { success, message } }) =>
      alert.show(message, { type: (success && 'success') || 'danger' }),
    update: (
      cache,
      {
        data: {
          removeItemFromParent: { parent }
        }
      }
    ) => {
      const data = cache.readQuery({
        query: ADMIN_MODULES,
        variables: queryVars
      })
      data.modules = parent.modules
      cache.writeQuery({ query: ADMIN_MODULES, variables: queryVars, data })
    }
  })

  // Loading / Early exit
  if (loading || userLoading) {
    return <BoardBody loading />
  }

  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_modules'>
          Er ging iets mis bij het laden van modules.
        </Translate>
      </BoardBody>
    )
  }

  const { currentUserWithRules } = currentUserData || {}
  const { course, section } = data
  let modules =
    (section && section.modules) || (course && course.modules) || data.modules
  const parent = section || course

  const sortModules = ({ oldIndex, newIndex }) => {
    const source = modules[oldIndex]
    modules.splice(oldIndex, 1)
    modules.splice(newIndex, 0, source)
    const doc = { modules: modules.map(page => page && page.path) }

    if (section) {
      const UpdateSectionInput = { path: section.path, doc }
      updateSection({
        variables: { UpdateSectionInput }
      })
    } else if (course) {
      const UpdateCourseInput = { path: course.path, doc }
      updateCourse({
        variables: { UpdateCourseInput }
      })
    }
  }

  // Sort multi-lingual content if multi-lingual is active in client's config
  if (config && config.multilingual) {
    modules = sortTranslatedContent(modules)
  }

  const actions = (
    <>
      {guard({ user: currentUserWithRules, action: 'create' }) && (
        <DashboardLink
          className='btn btn-lg'
          view='new'
          title='Create new module'
          data-cy='link_admin_dashboard_create_module'
        >
          <FontAwesomeIcon icon='plus' />
        </DashboardLink>
      )}
      {parent && (
        <DashboardLink
          className='btn btn-lg'
          view='link-existing'
          title={`Add Module to ${parent.name}`}
        >
          <FontAwesomeIcon icon='link' />
        </DashboardLink>
      )}
    </>
  )

  const sortableProps = {
    onSortEnd: sortModules,
    axis: 'y',
    lockAxis: 'y',
    transitionDuration: 300,
    useDragHandle: true,
    lockToContainerEdges: true
  }

  return (
    <>
      <BoardHead title={parent ? parent.name : 'Modules'} actions={actions}>
        <em>Modules</em>
      </BoardHead>
      <BoardBody>
        {modules.length <= 0 ? (
          <Translate _id='modules/no_modules'>There are no modules</Translate>
        ) : (
          <SortableBoardList {...sortableProps}>
            {modules.map((module, i) => (
              <ModuleItem
                key={i}
                user={currentUserWithRules}
                index={i}
                module={module}
                parentPath={parent && parent.path}
                parentType={(section && 'section') || (course && 'course')}
                removeItemMutation={removeItemMutation}
                history={history}
              />
            ))}
          </SortableBoardList>
        )}
      </BoardBody>
    </>
  )
}

export default Modules
