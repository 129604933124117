import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'reactstrap'

import { Translate } from '@lefapps/translations'

import { useQuery } from '@apollo/react-hooks'
import { CERTIFICATE } from './queries'

export default ({ module }) => {
  const { loading, error, data } = useQuery(CERTIFICATE, {
    variables: { modulePath: module.path }
  })
  if (error) console.error(error)
  return (
    <div className='text-center bg-light p-5'>
      <h3 className='text-center text-primary' style={{ fontSize: '4em' }}>
        <FontAwesomeIcon icon='award' />
      </h3>
      <p>
        <strong className='text-primary'>
          <Translate _id='courseCertificate/congratulations'>
            Gefeliciteerd
          </Translate>
        </strong>
        <br />
        <Translate _id='certificate/message'>
          u behaalde een certificaat voor deze lessenreeks!
        </Translate>
      </p>
      <br />
      {loading && <Spinner color='primary' />}
      {!loading && !error && !!data.moduleCertificate ? (
        <a
          href={data.moduleCertificate}
          download={data.moduleCertificate.split('/').pop()}
          className='btn btn-sm btn-primary'
        >
          <FontAwesomeIcon icon='download' /> Download…
        </a>
      ) : null}
    </div>
  )
}
