import React, { useEffect, useState, Fragment } from 'react'
import { Helmet } from 'react-helmet'

import omit from 'lodash/omit'
import pull from 'lodash/pull'
import get from 'lodash/get'

import { Translate } from '@lefapps/translations'

import { Grid, Main } from '../../_layout/Grid'
import { Container } from '../../_layout/Containers'
import { PrevNext, PrevNextLink } from '../../_layout/Bits'
import { Loading } from '../../_shared/Loading'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { PAGE } from './queries'
import { MODULE } from '../queries'
import { SAVE_RESULT } from './mutations'

import InteractiveVideoPage from './InteractiveVideoPage'
import ContentPage from './Content'
import ProgressBar from '../../_layout/Bits/ProgressBar'

// Don't show score if test or !showScore
const getWithoutScore = content => {
  return (content = content.map(pageItem => {
    if (pageItem.options) {
      pageItem.options = pageItem.options.map(option => omit(option, 'score'))
    }
    return pageItem
  }))
}

const Page = ({
  match: {
    url,
    params: { pagePath }
  }
}) => {
  let [score, setScore] = useState({})

  // Queries
  const { loading, error, data } = useQuery(PAGE, {
    variables: { path: pagePath },
    fetchPolicy: 'no-cache'
  })
  const modulePath = url.substring(url.indexOf('/module/')).split('/')[2] || ''
  const { loading: moduleLoading, error: moduleError } = useQuery(MODULE, {
    variables: { path: modulePath },
    fetchPolicy: 'no-cache'
  })

  // Gate
  // Gate variable and method: if true - gate is close, if false or null - gate is open
  const [gate, setGate] = useState()
  const allQuestionsAnswered = result => {
    if (!result) return false
    const allQuestions = page.content
      .map(pageItem => (pageItem.type === 'question' ? pageItem.name : null))
      .sort()
    const answeredQuestions = pull(
      Object.keys(result).map(name =>
        name !== 'updatedAt' && name !== '__scoring' ? name : null
      ),
      null
    ).sort()
    let unAnswered
    allQuestions.map(mustBeAnswered => {
      if (mustBeAnswered === null) {
        return null
      } // Early exit
      if (!answeredQuestions.includes(mustBeAnswered)) {
        return (unAnswered = true) // We are missing an answer
      } else return null
    })
    if (unAnswered) return false
    return true
  }
  const checkGate = newResult => {
    // All questions must be answered correctly before moving on, a new answer was submitted
    if (
      page &&
      page.module.gateProgress &&
      page.module.gateConfig &&
      page.module.gateConfig.includes('all-correct') &&
      newResult &&
      newResult.__scoring
    ) {
      return newResult.__scoring.__score === newResult.__scoring.__maxScore &&
        allQuestionsAnswered(newResult)
        ? setGate(false)
        : setGate(true)
    } else if (
      data &&
      data.page &&
      data.page.result &&
      page &&
      page.module.gateProgress &&
      page.module.gateConfig &&
      page.module.gateConfig.includes('all-correct')
    ) {
      // if no doc, see if initial scoring was right?
      return result.__scoring.__score === result.__scoring.__maxScore &&
        allQuestionsAnswered(result)
        ? setGate(false)
        : setGate(true)
    }
    // else: see if all questions were answered
    return allQuestionsAnswered(newResult || result || {})
      ? setGate(false)
      : setGate(true)
  }

  // Mutations
  const [createResult] = useMutation(SAVE_RESULT, {
    ignoreResults: true,
    onCompleted: ({ saveResult: result }) => {
      setScore(result.result.result[pagePath].__scoring)
      if (
        data &&
        data.page &&
        data.page.module &&
        data.page.module.gateProgress
      ) {
        checkGate(result.result.result[pagePath])
      }
      // TODO: [ITG-346] these refetches cause this component to rerender and lose focus on input fileds
      // refetch() // aggressively update cache with up to date result
      // moduleRefetch()
    }
  })

  // create (empty) result when opening the page
  useEffect(() => {
    if (
      !loading &&
      data &&
      data.page &&
      data.page.module &&
      data.page.module.path
    ) {
      createResult({
        variables: {
          ResultInput: {
            module: data.page.module.path,
            page: data.page.path,
            result: data.page && data.page.result
          }
        }
      })
      // Checking the gates when new answers added
      if (data.page.module.gateProgress) {
        // initialising the gate:
        // Check page for questions otherwise gate is always open
        let pageHasGatedQuestions
        page.content.map(pageItem => {
          if (pageItem.type === 'question') {
            return (pageHasGatedQuestions = true)
          } else return null
        })
        if (!pageHasGatedQuestions) setGate(false)
        else checkGate()
      }
    }
    // reset scroll
    const $progress = document.getElementById('progress')
    $progress && $progress.scrollIntoView({ behavior: 'smooth' })
    // eslint-disable-next-line
  }, [pagePath, data && data.page && data.page.path])

  // Early exit
  if (loading || moduleLoading) return <Loading />
  if (error || moduleError) {
    console.error(error || moduleError)
    return (
      <Loading color={'danger'}>
        <Translate _id='error/loading_page'>
          Er ging iets mis bij het laden van de pagina.
        </Translate>
      </Loading>
    )
  }

  const { page } = data

  if (!page) {
    return (
      <Loading color={'warning'}>
        <Translate _id='page/no_page'>
          Oeps, deze pagina bestaat niet.
        </Translate>
      </Loading>
    )
  }

  // For exams and timed content: if module is no longer published, don't show page
  if (page.module.published === false) {
    return (
      <>
        <Grid>
          <Main>
            <Container vertical>
              <h2>
                <Translate _id='page/unpublished/title'>
                  Deze pagina is niet langer beschikbaar.
                </Translate>
              </h2>
              <p>
                <Translate _id='page/unpublished/info'>
                  Een beheerder heeft de toegang tot deze inhoud geblokkeerd.
                </Translate>
              </p>
            </Container>
          </Main>
        </Grid>
      </>
    )
  }

  let { prevPage, nextPage, module, result, estimatedTime } = page

  let index
  index = module.pages.findIndex(({ path }) => path === pagePath) + 1
  // Anonymous pages
  if (module.anonymousPages && nextPage) {
    nextPage.name = null
  }
  // methods
  let saveTimer
  const saveResult = doc => {
    clearTimeout(saveTimer)
    saveTimer = setTimeout(async () => {
      if (page) {
        const ResultInput = {
          module: module.path,
          page: page.path,
          result: doc
        }
        createResult({ variables: { ResultInput } })
      }
    }, 500)
    return doc
  }

  // Components
  const topUrl = url.replace(`/page/${pagePath}`, '')
  let prev = prevPage || { path: topUrl }
  let next = gate
    ? { gate, path: topUrl, name: 'Waiting for answers...' } // passing agnostic url in case people inspect element
    : (nextPage && nextPage) || { path: topUrl + '/result', name: 'Result' }

  // Finally sets content, checks if score can be shown
  let content =
    !page.module.test && page.module.showCorrect
      ? page.content || {}
      : (page && page.content && getWithoutScore(page.content)) || {}

  return (
    <Fragment>
      <Helmet>
        <title>{page.name}</title>
      </Helmet>
      <ProgressBar
        value={(index / module.pages.length) * 100}
        levelUp={topUrl}
      />
      <Container vertical width='tablet' id='pageContent'>
        <Grid>
          <Main>
            {module.anonymousPages ? null : (
              <Container>
                <h2 className='titleOverride'>
                  {index}. {page.name}
                </h2>
                <span className='pageInfo'>
                  {estimatedTime
                    ? Math.round(estimatedTime / 60) === (1 || 0)
                      ? '1 minute'
                      : `${Math.round(estimatedTime / 60)} minutes`
                    : ''}
                </span>
              </Container>
            )}

            <Container vertical>
              {pagePath &&
              page.interactiveVideo &&
              page.interactiveVideo.show === true ? (
                <InteractiveVideoPage
                  page={page}
                  result={result}
                  saveResult={saveResult}
                />
              ) : (
                content &&
                content.map((c, k) => (
                  <ContentPage
                    key={k}
                    content={c}
                    context={page}
                    states={{ score: get(score, c.name, 0) }}
                    callbacks={{ saveResult }}
                  />
                ))
              )}
            </Container>
          </Main>
        </Grid>
      </Container>
      {(prev || next) && (
        <PrevNext>
          {next && !next.gate ? (
            <PrevNextLink action={next.path}>{next.name}</PrevNextLink>
          ) : (
            <PrevNextLink className='disabled' action={next.path}>
              {next.name}
            </PrevNextLink>
          )}
          {prev && (
            <PrevNextLink action={prev.path} prev>
              {prev.name}
            </PrevNextLink>
          )}
        </PrevNext>
      )}
    </Fragment>
  )
}

export default Page
