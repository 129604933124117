import { gql } from 'apollo-boost'
import { courseParts } from '../_shared/fragments'

const DELETE_COURSE = gql`
  mutation DeleteCourse($path: String!) {
    deleteCourse(path: $path) {
      success
      message
      course {
        ...CourseParts
      }
    }
  }
  ${courseParts}
`

const CREATE_COURSE = gql`
  mutation CreateCourse($CourseInput: CourseInput!) {
    createCourse(course: $CourseInput) {
      success
      message
      course {
        ...CourseParts
      }
    }
  }
  ${courseParts}
`

const UPDATE_COURSE = gql`
  mutation UpdateCourse($UpdateCourseInput: UpdateCourseInput!) {
    updateCourse(course: $UpdateCourseInput) {
      success
      message
      course {
        ...CourseParts
      }
    }
  }
  ${courseParts}
`

export { CREATE_COURSE, DELETE_COURSE, UPDATE_COURSE }
