import { gql } from 'apollo-boost'

export const CERTIFY = gql`
  mutation allowCertificate($userId: String!, $modulePath: String!) {
    certify(module: $modulePath, user: $userId) {
      success
      message
    }
  }
`
