import { gql } from 'apollo-boost'

export const fileParts = gql`
  fragment FileParts on File {
    path
    name
    url
    type
  }
`
export const imageParts = gql`
  fragment ImageParts on Image {
    _id
    name
    alt
  }
`
export const userParts = gql`
  fragment UserParts on User {
    _id
    name
    email
  }
`

export const courseParts = gql`
  fragment CourseParts on Course {
    path
    name
    originId
    language
    info
    showUserList
    location
    startdate
    enddate
    teacher
    published
    image {
      ...ImageParts
    }
  }
  ${imageParts}
`

export const sectionParts = gql`
  fragment SectionParts on Section {
    path
    name
    info
    published
    date
    location
    teacher
    image {
      ...ImageParts
    }
  }
  ${imageParts}
`
export const moduleParts = gql`
  fragment ModuleParts on Module {
    path
    name
    originId
    info
    published
    showCorrect
    test
    anonymousPages
    randomized
    gateProgress
    language
    completed
    ongoing
    pages {
      name
      path
      type
    }
    image {
      ...ImageParts
    }
    entryPoint {
      type
      path
    }
  }
  ${imageParts}
`
export const pageParts = gql`
  fragment PageParts on Page {
    path
    name
    content
    interactiveVideo
    completed
    type
    prevPage {
      path
      name
    }
    nextPage {
      path
      name
    }
    result
  }
`
