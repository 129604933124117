import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactPlayer from 'react-player'
import cloneDeep from 'lodash/cloneDeep'
import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem,
  DashboardLink
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_FILES } from './queries'
import { DELETE_FILE } from './mutations'
import { ADD_ITEM_TO_PARENT, REMOVE_ITEM_FROM_PARENT } from '../../mutations'

import config from '../../config'

import { sizes } from '../../../_helpers/thumbnails'

const { assets = '' } = config.storage || {}

const isImage = type => ['jpg', 'jpeg', 'png', 'webp'].includes(type)

export const FileViewer = ({ type, ...props }) => {
  props.width = '100%'
  switch (type) {
    // image
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
      return <img {...props} alt={props.name || 'image'} />
    // video
    case 'webm':
    case 'mp4':
    case 'ogg':
      return <ReactPlayer {...props} />
    // audio
    case 'mp3':
      return <ReactPlayer {...props} />
    // others: pdf, …
    default:
      return <iframe {...props} style={{ height: '70vh' }} title={type} />
  }
}

export const FilesListItem = ({
  file,
  parentPath,
  parentType,
  parent,
  queryVars
}) => {
  // const { name, type, url, options = {} } = file
  const { name, type, url } = file
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  // const actions = { view: { onClick: toggle }, ...options.actions }
  const fullPath = assets + url

  // Delete file
  const alert = useAlert()
  // Mutations
  const [deleteFile, { loading, error }] = useMutation(DELETE_FILE, {
    onCompleted: ({ deleteFile: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    },
    update: cache => {
      let data = {}
      const variables = queryVars
      // Parent
      if (parentType && parentType === 'section') {
        data = cloneDeep(cache.readQuery({ query: ADMIN_FILES, variables }))
        data.section.files = data.section.files.filter(
          ({ path }) => path !== file.path
        )
        data.files = data.section.files
        cache.writeQuery({ query: ADMIN_FILES, variables, data })
      } else if (parentType && parentType === 'course') {
        data = cloneDeep(cache.readQuery({ query: ADMIN_FILES, variables }))
        data.course.files = data.course.files.filter(
          ({ path }) => path !== file.path
        )
        data.files = data.course.files
        cache.writeQuery({ query: ADMIN_FILES, variables, data })
      } else {
        // Files
        // Always gets all files, filtering by parent not needed
        data = cache.readQuery({
          query: ADMIN_FILES,
          variables: queryVars
        })
        data.files = data.files.filter(({ path }) => path !== file.path)
        cache.writeQuery({ query: ADMIN_FILES, variables, data })
      }
    }
  })

  const [
    addItemToParent,
    { loading: linkLoading, error: linkError }
  ] = useMutation(ADD_ITEM_TO_PARENT, {
    onCompleted: ({ addItemToParent: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    },
    update: (
      cache,
      {
        data: {
          addItemToParent: { parent }
        }
      }
    ) => {
      const data = cache.readQuery({
        query: ADMIN_FILES,
        variables: queryVars
      })
      data.files = parent.files
      cache.writeQuery({ query: ADMIN_FILES, variables: queryVars, data })
    }
  })
  const [
    removeItemFromParent,
    { loading: unlinkLoading, error: unlinkError }
  ] = useMutation(REMOVE_ITEM_FROM_PARENT, {
    onCompleted: ({ removeItemFromParent: { success, message } }) =>
      alert.show(message, { type: (success && 'success') || 'danger' }),
    update: (
      cache,
      {
        data: {
          removeItemFromParent: { parent }
        }
      }
    ) => {
      const data = cache.readQuery({
        query: ADMIN_FILES,
        variables: queryVars
      })
      data.files = parent.files
      cache.writeQuery({ query: ADMIN_FILES, variables: queryVars, data })
    }
  })

  // Methods
  const remove = () => {
    deleteFile({ variables: { path: file.path } })
  }
  const link = () => {
    addItemToParent({
      variables: {
        itemType: 'file',
        itemPath: file.path,
        parentType,
        parentPath
      }
    })
  }
  const unlink = () => {
    removeItemFromParent({
      variables: {
        itemType: 'file',
        itemPath: file.path,
        parentType,
        parentPath
      }
    })
  }

  // Components
  let actions = []
  actions.view = { onClick: toggle }
  if (parent) {
    // check linked status
    const ownerFiles = parent.files.map(file => file['path'])
    console.log(ownerFiles)
    if (ownerFiles.includes(file.path)) {
      actions.unlink = {
        onClick: unlink,
        loading: unlinkLoading,
        error: unlinkError
      }
    } else {
      actions.link = {
        onClick: link,
        loading: linkLoading,
        error: linkError
      }
    }
  }

  actions.remove = {
    onClick: remove,
    'data-type': `${file.path}__remove`,
    loading,
    error
  }

  return (
    <BoardListItem label={type && type.toUpperCase()} actions={actions}>
      {name}
      <br />
      <Modal isOpen={modal} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>
          <small className={'text-primary mr-5'}>
            {type && type.toUpperCase()}
          </small>
          {name}
        </ModalHeader>
        <ModalBody>
          <FileViewer src={fullPath} type={type} />
          <br />
          <br />
          {(isImage(type) && (
            <Table>
              <thead>
                <tr>
                  <th>Size</th>
                  <th>
                    Link{' '}
                    <small>
                      (markdown: <code>![description](link)</code>)
                    </small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sizes.map(({ label, info }, key) => (
                  <tr key={key}>
                    <td>{info}</td>
                    <td>
                      <code>
                        {assets}
                        {label}
                        {'/'}
                        {url}
                      </code>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )) || <code>{fullPath}</code>}
        </ModalBody>
      </Modal>
    </BoardListItem>
  )
}

export const FilesList = ({
  items = [],
  parentPath,
  parentType,
  queryVars
}) => {
  if (items.length <= 0) {
    return (
      <BoardListItem label={0} actions={{}}>
        <Translate _id='files/no_files'>Geen documenten</Translate>
      </BoardListItem>
    )
  }
  return items.map((item, index) => (
    <FilesListItem
      file={item}
      key={index}
      index={index}
      parentPath={parentPath || null}
      parentType={parentType || null}
      queryVars={queryVars}
    />
  ))
}

export const Files = ({
  match: {
    params: { coursePath, sectionPath }
  }
}) => {
  // Query
  const queryVars = {
    course: coursePath,
    section: sectionPath,
    filterByParent: (sectionPath && 'section') || (coursePath && 'course')
  }

  const { loading, error, data } = useQuery(ADMIN_FILES, {
    variables: queryVars
  })

  if (loading) {
    return <BoardBody loading />
  }

  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_files'>
          Er ging iets mis bij het laden van documenten.
        </Translate>
      </BoardBody>
    )
  }

  const { course, section } = data

  const actions = [
    <DashboardLink
      className='btn btn-lg'
      view={`new`}
      data-cy='new_file'
      title={'Add Documents'}
      key={0}
    >
      <FontAwesomeIcon icon='plus' />
    </DashboardLink>
  ]
  if (sectionPath || coursePath) {
    actions.push(
      <DashboardLink
        className='btn btn-lg'
        view={`link-existing`}
        data-cy='link'
        title={'Link Documents'}
        key={1}
      >
        <FontAwesomeIcon icon='link' />
      </DashboardLink>
    )
  }

  return (
    <>
      <BoardHead title={'Documents'} actions={actions} />
      <BoardBody>
        <BoardList>
          <FilesList
            items={data && data.files}
            parentPath={
              (section && section.path) || (course && course.path) || null
            }
            parentType={(section && 'section') || (course && 'course') || null}
            queryVars={queryVars}
          />
        </BoardList>
      </BoardBody>
    </>
  )
}
export default Files
