import { gql } from 'apollo-boost'

const UPDATE_RESULT = gql`
  mutation UpdateResult($UpdateResultInput: UpdateResultInput!) {
    updateResult(result: $UpdateResultInput) {
      success
      message
      result {
        result
      }
    }
  }
`

export { UPDATE_RESULT }
