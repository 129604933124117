import React from 'react'
import PropTypes from 'prop-types'
import { initials } from '../../../_helpers/users'

const UserListItem = ({ overflow, image, name }) => {
  return (
    <span
      className={`list__user-item${image ? ' list__user-item__image' : ''}${
        overflow ? ' list__user-item__overflow' : ''
      }`}
      style={image && { backgroundImage: `url(${image})` }}
      title={name}
    >
      {overflow ? `+ ${overflow}` : initials(name)}
    </span>
  )
}

UserListItem.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string
}

export default UserListItem
