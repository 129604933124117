import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlockList, BlockListItem } from '../_layout/BlockList'

import config from '../config'

import { useMutation } from '@apollo/react-hooks'
import { UPDATE_LOG } from '../mutations'

const host = (config && config.storage && config.storage.assets) || '/'

export const FileList = ({ files }) => {
  // Mutations
  const [updateLog] = useMutation(UPDATE_LOG)
  return (
    <BlockList small>
      {files.map(file => {
        const header = (
          <a
            target='_blank'
            href={host + file.url}
            download={file.name + '.' + file.type}
            rel='noopener noreferrer'
            onClick={() => {
              // log downloads
              const doc = {
                tracking: {
                  type: 'files',
                  path: file.name + '/' + file.url,
                  lastSeen: new Date()
                }
              }
              const UpdateLogInput = { doc }
              updateLog({ variables: { UpdateLogInput } })
            }}
          >
            <h3 className='mb-0'>
              <FontAwesomeIcon icon='file-download' className='mr-3' />
              {file.name}
            </h3>
          </a>
        )
        return <BlockListItem key={file.path} color='dark' header={header} />
      })}
    </BlockList>
  )
}
