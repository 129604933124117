export const authDictionary = {
  en: {
    // titles
    'Sign in to your account': 'Sign in to your account',
    'Sign Up Account': 'Sign Up Account',
    'Create a new account': 'Create a new account',
    'Reset your password': 'Reset your password',

    // fields
    Username: 'Your username',
    Name: 'Firstname & Lastname',
    'Enter your username': 'Enter your username',
    Password: 'Password (min. 8 characters)',
    'Enter your password': 'min. 8 characters',
    Email: 'E-mail',
    'Enter your email': ' ',
    'Phone Number': 'Phone Number',
    'Enter your phone number': '01 234 56 78',
    Code: 'Your code',
    'New Password': 'Your new password (min. 8 characters)',
    Profession: 'Profession',
    Riziv: 'Riziv number',
    'Confirmation Code': 'Confirmation Code',

    // buttons
    'Sign In': 'Sign In',
    'Create Account': 'Create Account',
    'Sign Up': 'Sign Up',
    'Send Code': 'Send Code',
    Submit: 'Submit',

    // help texts
    'No account? ': ' ',
    'Create account': '→ Create account',
    'Forget your password? ': ' ',
    'Reset password': '→ Reset password',
    'Have an account? ': ' ',
    'Sign in': '→ I already have a profile',
    'Back to Sign In': '→ Back to Sign In',
    'Lost your code? ': 'Lost your code? ',
    'Resend Code': '→ Resend Code',

    Hello: ' '
  },
  nl: {
    // titles
    'Sign in to your account': 'Aanmelden met jouw profiel',
    'Sign Up Account': 'Een profiel maken',
    'Create a new account': 'Een nieuw profiel aanmaken',
    'Reset your password': 'Wachtwoord opnieuw instellen',

    // fields
    Username: 'Uw gebruikersnaam',
    Name: 'Voornaam & Naam',
    'Enter your username': 'gebruikersnaam',
    Password: 'Wachtwoord (min. 8 tekens)',
    'Enter your password': 'minimaal 8 tekens',
    Email: 'E-mailadres',
    'Enter your email': ' ',
    'Phone Number': 'Telefoonnummer',
    'Enter your phone number': '01 234 56 78',
    Code: 'Uw code',
    'New Password': 'Uw nieuw wachtwoord (min. 8 tekens)',
    Profession: 'Vakgebied',
    Riziv: 'Riziv nummer',
    'Confirmation Code': 'Verificatiecode',

    // buttons
    'Sign In': 'Aanmelden',
    'Create Account': 'Profiel maken',
    'Sign Up': 'Een profiel maken',
    'Send Code': 'Code versturen',
    Submit: 'Instellen',

    // help texts
    'No account? ': ' ',
    'Create account': '→ Ik heb nog geen profiel',
    'Forget your password? ': ' ',
    'Reset password': '→ Ik weet mijn wachtwoord niet',
    'Have an account? ': ' ',
    'Sign in': '→ Ik heb al een profiel',
    'Back to Sign In': '→ Aanmelden',
    'Lost your code? ': 'Code verloren? ',
    'Resend Code': '→ Code opnieuw versturen',

    Hello: ' '
  },
  fr: {
    // titles
    'Sign in to your account': 'Connectez-vous avec votre profil',
    'Sign Up Account': 'Créer un profil',
    'Create a new account': 'Créer un nouveau profil',
    'Reset your password': 'Réinitialiser le mot de passe',

    // fields
    Username: `votre nom d'utilisateur`,
    Name: 'Prénom & Nom',
    'Enter your username': `Nom d'utilisateur`,
    Password: 'Mot de passe (min. 8 caractères)',
    'Enter your password': 'min. 8 caractères',
    Email: 'Adresse e-mail',
    'Enter your email': ' ',
    'Phone Number': 'Numéro de téléphone',
    'Enter your phone number': '01 234 56 78',
    Code: 'Votre code',
    'New Password': 'Votre nouveau mot de passe (min. 8 caractères)',
    Profession: 'Discipline',
    Riziv: 'Riziv numero',
    'Confirmation Code': 'Code de vérification',

    // buttons
    'Sign In': `S'inscrire`,
    'Create Account': 'Créer un profil',
    'Sign Up': 'Créer un profil',
    'Send Code': 'Envoyer le code',
    Submit: 'Envoyez',

    // help texts
    'No account? ': ' ',
    'Create account': `→ je n'ai pas encore de profil`,
    'Forget your password? ': ' ',
    'Reset password': '→ je ne connais pas mon mot de passe',
    'Have an account? ': ' ',
    'Sign in': `→ j'ai déjà un profil`,
    'Back to Sign In': `→ S'inscrire`,
    'Lost your code? ': 'Code perdu? ',
    'Resend Code': '→ Renvoyer le code',

    Hello: ' '
  }
}
