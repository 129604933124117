import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

import { Translate } from '@lefapps/translations'

import './prevnext.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PrevNextCommon = ({ prev }) => (
  <Fragment>
    <FontAwesomeIcon icon={`arrow-${prev ? 'left' : 'right'}`} />
    <small>
      {prev ? (
        <Translate _id='prevnext/prev'>vorige</Translate>
      ) : (
        <Translate _id='prevnext/next'>volgende</Translate>
      )}
    </small>
  </Fragment>
)

const PrevNextLink = ({ action, prev, children, ...props }) => {
  const className = [
    'prevnext-link',
    `prevnext-link__${prev ? 'prev' : 'next'}`
  ]
  if (props.className) className.push(props.className)

  return (
    <Link {...props} to={action} className={className.join(' ')}>
      <PrevNextCommon prev={prev} />
      {!prev && children}
    </Link>
  )
}

const PrevNextButton = ({ action, prev, children, ...props }) => {
  const className = [
    'prevnext-link',
    `prevnext-link__${prev ? 'prev' : 'next'}`
  ]
  if (props.className) className.push(props.className)

  return (
    <Button {...props} type={action} className={className.join(' ')}>
      <PrevNextCommon prev={prev} />
      {!prev && children}
    </Button>
  )
}

const PrevNext = ({ children, ...props }) => {
  const className = ['prevnext']
  if (props.className) className.push(props.className)
  return (
    <footer {...props} className={className.join(' ')}>
      {children}
    </footer>
  )
}

PrevNext.propTypes = {
  children: PropTypes.node
}

PrevNextLink.propTypes = {
  children: PropTypes.node,
  action: PropTypes.string.isRequired,
  prev: PropTypes.bool
}

export default PrevNext
export { PrevNext, PrevNextLink, PrevNextButton }
