import React from 'react'
import { Button } from 'reactstrap'
import { useAlert } from 'react-alert'
import { withRouter } from 'react-router-dom'
import { BoardHead, BoardBody } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useMutation } from '@apollo/react-hooks'

import { ADMIN_FILES } from './queries'
import { CREATE_FILE } from './mutations'

import Form from '../../_shared/Form/index'

export const fileElements = [
  {
    name: 'name',
    label: {
      nl: 'Titel',
      en: 'Title',
      fr: 'Titre'
    },
    type: 'text',
    required: true
  },
  {
    name: 'url',
    label: {
      nl: 'Document of afbeelding',
      en: 'File or image',
      fr: 'Document ou image'
    },
    type: 'upload',
    required: true,
    attributes: {
      placeholder: 'Choose a file'
    }
  }
]

export const File = withRouter(
  ({
    history,
    match: {
      params: { coursePath, sectionPath }
    }
  }) => {
    // Hooks
    const alert = useAlert()

    // Mutations
    const [createFile, { loading }] = useMutation(CREATE_FILE, {
      onCompleted: ({ createFile: { success, message } }) => {
        alert.show(message, { type: (success && 'success') || 'danger' })
      },
      update: (
        cache,
        {
          data: {
            createFile: { file }
          }
        }
      ) => {
        const variables = {
          course: coursePath,
          section: sectionPath,
          filterByParent: (sectionPath && 'section') || (coursePath && 'course')
        }
        const clientData = cache.readQuery({
          query: ADMIN_FILES,
          variables
        })
        clientData.files = [...clientData.files, file]
        cache.writeQuery({ query: ADMIN_FILES, variables, data: clientData })
        history.goBack()
      }
    })

    // Early exit
    if (loading) return <BoardBody loading />

    // Methods
    const onSubmit = async FileInput => {
      FileInput.url = FileInput.url.name
      if (coursePath || sectionPath) {
        FileInput.parentType =
          (sectionPath && 'section') || (coursePath && 'course')
        FileInput.parentPath = sectionPath || coursePath
      }
      await createFile({ variables: { FileInput } })
    }

    // Render
    return (
      <>
        <BoardHead title={'Nieuw document'} />
        <BoardBody data-cy='fileForm'>
          <Form elements={fileElements} onSubmit={onSubmit}>
            <Button type='submit' color='success'>
              <Translate _id='button/save'>Opslaan</Translate>
            </Button>
          </Form>
        </BoardBody>
      </>
    )
  }
)

export default File
