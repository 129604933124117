import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAlert } from 'react-alert'
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from 'react-sortable-hoc'
import {
  BoardList,
  BoardListItem,
  BoardBody,
  BoardHead,
  DashboardLink
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import cloneDeep from 'lodash/cloneDeep'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_COURSE } from '../Courses/queries'
import { CURRENT_USER_ADMIN } from '../Users/queries'
import { UPDATE_COURSE } from '../Courses/mutations'
import { DELETE_SECTION } from './mutations'

import guard from '../_shared/guard'

const SortableBoardListItem = SortableElement(BoardListItem)
const SortableBoardList = SortableContainer(BoardList)
const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={'grip-lines'} />)

const SectionItem = ({ index, user, section, coursePath, history }) => {
  const alert = useAlert()

  // Mutations
  const [deleteSection, { loading, error }] = useMutation(DELETE_SECTION, {
    onCompleted: ({ deleteSection: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    },
    update: (
      cache,
      {
        data: {
          deleteSection: {
            section: { path: deletedPath }
          }
        }
      }
    ) => {
      const variables = {
        path: coursePath
      }
      const data = cloneDeep(
        cache.readQuery({ query: ADMIN_COURSE, variables })
      )
      data.course.sections = data.course.sections.filter(
        ({ path }) => path !== deletedPath
      )
      cache.writeQuery({ query: ADMIN_COURSE, variables, data })
    }
  })

  // Methods
  const remove = () => {
    deleteSection({ variables: { path: section.path } })
  }

  const duplicate = () => {
    const initialModel = cloneDeep(section)
    initialModel.name = `Duplicaat van ${section.name}`
    delete initialModel['path']
    history.push('sections/new-section', { initialModel })
  }

  // Components (with guard)
  let actions = {}
  if (guard({ user, content: section, action: 'edit' })) {
    actions.edit = { to: `sections/${section.path}` }
  }
  if (guard({ user, action: 'create' })) {
    actions.duplicate = { onClick: duplicate, type: 'duplicate' }
  }
  if (guard({ user, content: section, action: 'delete' })) {
    actions.remove = { onClick: remove, loading, error }
  }

  // Render
  return (
    <SortableBoardListItem
      index={index}
      label={<DragHandle />}
      actions={actions}
    >
      {section.name}
    </SortableBoardListItem>
  )
}

const Sections = ({
  match: {
    params: { coursePath }
  },
  history
}) => {
  // Mutations
  const [updateCourse, { loading: updateCourseLoading }] = useMutation(
    UPDATE_COURSE
  )

  // Queries
  const { loading, error, data } = useQuery(ADMIN_COURSE, {
    variables: { path: coursePath }
  })

  const { loading: userLoading, data: currentUserData } = useQuery(
    CURRENT_USER_ADMIN
  )

  // Early exit
  if (loading || updateCourseLoading || userLoading)
    return <BoardBody loading />
  if (error) {
    console.error('useQuery', 'ADMIN_COURSE', error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_sections'>
          Er ging iets mis bij het laden van deze secties.
        </Translate>
      </BoardBody>
    )
  }
  const course = data.course
  const { sections = [] } = course || {}

  const { currentUserWithRules } = currentUserData || {}

  // Methods
  const sortSections = async ({ oldIndex, newIndex }) => {
    const source = sections[oldIndex]
    sections.splice(oldIndex, 1)
    sections.splice(newIndex, 0, source)

    const doc = { sections: sections.map(section => section && section.path) }
    const UpdateCourseInput = { path: coursePath, doc }

    await updateCourse({
      variables: { UpdateCourseInput }
    })
  }

  // Components
  const actions = guard({ user: currentUserWithRules, action: 'create' }) && (
    <DashboardLink
      className='btn btn-lg'
      view='new'
      title='Create new section'
      data-cy='new_section'
    >
      <FontAwesomeIcon icon='plus' />
    </DashboardLink>
  )

  const sortableProps = {
    onSortEnd: sortSections,
    axis: 'y',
    lockAxis: 'y',
    transitionDuration: 300,
    useDragHandle: true,
    lockToContainerEdges: true
  }

  // Render
  return (
    <>
      <BoardHead title={course.name} actions={actions}>
        <em>Secties</em>
      </BoardHead>
      <BoardBody>
        {sections.length > 0 ? (
          <SortableBoardList {...sortableProps}>
            {sections.map((section, index) => (
              <SectionItem
                key={index}
                index={index}
                user={currentUserWithRules}
                section={section}
                coursePath={coursePath}
                history={history}
              />
            ))}
          </SortableBoardList>
        ) : (
          <Translate _id='sections/no_sections'>
            Deze cursus heeft geen secties.
          </Translate>
        )}
      </BoardBody>
    </>
  )
}

export default Sections
