import { gql } from 'apollo-boost'

const COURSE_CERTIFICATE_USERS = gql`
  query Users($course: String) {
    users(course: $course) {
      _id
      email
      name
      courses
      riziv
      courseCertificates {
        course
        path
      }
    }
    course(path: $course) {
      path
      name
    }
  }
`
export { COURSE_CERTIFICATE_USERS }
