import React, { useState, useEffect } from 'react'
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardFooter,
  Collapse
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import size from 'lodash/size'

import { Image } from '../../_shared/Image'

import './teamlist.scss'

const getResize = () => window.innerWidth >= 480

export const TeamListItem = ({
  name,
  phone,
  email,
  description,
  image = {},
  profession
}) => {
  const [isOpen, setOpen] = useState(getResize())
  const toggle = () => !getResize() && setOpen(!isOpen)
  const resized = () => setOpen(getResize())
  useEffect(() => {
    window.addEventListener('resize', resized)
    return () => window.removeEventListener('resize', resized)
  })
  return (
    <Card tag={'li'} className={'list__team-item'}>
      {!!size(image) && (
        <Image
          {...image}
          context={'profile'}
          className={'card-img-top'}
          onClick={toggle}
        />
      )}
      <CardBody
        onClick={toggle}
        className={
          description || email || phone
            ? `toggle${isOpen ? ' toggled' : ''}`
            : ''
        }
      >
        {name && <CardTitle tag={'h3'}>{name}</CardTitle>}
        {profession && <CardSubtitle tag={'h4'}>{profession}</CardSubtitle>}
      </CardBody>
      <Collapse isOpen={isOpen} tag={CardBody}>
        {description && <CardText>{description}</CardText>}
        <CardFooter>
          {/* {phone && (
            <a href={`tel:${phone}`} title={'Contacteer via telefoon'}>
              <FontAwesomeIcon icon={'phone'} />{' '}
              <span className={'info'}>{phone}</span>
            </a>
          )}{' '} */}
          {email && (
            <a href={`mailto:${email}`} title={'Contacteer via e-mail'}>
              <FontAwesomeIcon icon={'paper-plane'} />{' '}
              <span className={'info'}>{email}</span>
            </a>
          )}
        </CardFooter>
      </Collapse>
    </Card>
  )
}

export default ({ members = [] }) => {
  return (
    <ul className={'list__team'}>
      {members.map((member, key) => (
        <TeamListItem key={key} {...member} />
      ))}
    </ul>
  )
}
