import React from 'react'
import MarkdownIt from 'markdown-it'
import mila from 'markdown-it-link-attributes'
import MarkdownItAttrs from 'markdown-it-attrs'
import MarkdownItVideo from 'markdown-it-video'

const md = new MarkdownIt({ html: true, linkify: true, typographer: true })
  .use(mila, {
    pattern: /^https:/,
    attrs: {
      target: '_blank',
      rel: 'noopener'
    }
  })
  .use(MarkdownItAttrs)
  .use(MarkdownItVideo)

const render = (content = '', options = {}) => {
  const { Tag = 'div', className = '', ...props } = options

  return (
    <Tag
      {...props}
      className={className + ' markdown'}
      dangerouslySetInnerHTML={{ __html: md.render(content) }}
    />
  )
}

export { md, render }
