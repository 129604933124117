import { gql } from 'apollo-boost'

const DELETE_SECTION = gql`
  mutation DeleteSection($path: String!) {
    deleteSection(path: $path) {
      success
      message
      section {
        path
      }
    }
  }
`

const CREATE_SECTION = gql`
  mutation CreateSection($SectionInput: SectionInput!) {
    createSection(section: $SectionInput) {
      success
      message
      section {
        path
      }
    }
  }
`

const UPDATE_SECTION = gql`
  mutation UpdateSection($UpdateSectionInput: UpdateSectionInput!) {
    updateSection(section: $UpdateSectionInput) {
      success
      message
      section {
        path
        name
      }
    }
  }
`

export { CREATE_SECTION, DELETE_SECTION, UPDATE_SECTION }
