import { gql } from 'apollo-boost'

import { sectionParts } from '../../_shared/fragments'

const SECTION = gql`
  query Section($path: String!) {
    section(path: $path) {
      ...SectionParts
      classroom
      createdAt
    }
  }
  ${sectionParts}
`

export { SECTION }
