import React from 'react'
import { Fade, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Container } from '../_layout/Containers'

/** @function Loading
 *
 * Display a spinner to notify the user something is loading.
 *
 * @param {string} color - change the color of the spinner (defaults to 'light')
 * @param {string} icon - icon shown instead of spinner
 * @param {node} children - Message shown below spinner or icon
 */

export const Loading = ({ color = 'light', children, icon }) => (
  <Fade>
    <Container vertical width={'phone'} className={'text-center'}>
      {icon ? (
        <span className={`text-${color} h1`}>
          <FontAwesomeIcon icon={icon} />
        </span>
      ) : (
        <Spinner color={color} size={'lg'} />
      )}
      <hr />
      <div className={'lead text-center'}>{children}</div>
    </Container>
  </Fade>
)

export default Loading
