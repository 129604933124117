import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { render } from '../../../_helpers/markdown'
import { uniSlash } from '../../../_helpers/url'
import { BlockList, BlockListItem } from '../../_layout/BlockList'

const SectionList = withRouter(({ sections, small, match: { url } }) => {
  // Sort by date
  sections = sections.sort(function compare (a, b) {
    return (
      new Date(a.classroom && a.classroom.startdate) -
      new Date(b.classroom && b.classroom.startdate)
    )
  })
  let foundNext = false
  // add status based on current date
  sections.map(section => {
    if (section.classroom && section.classroom.startdate) {
      if (new Date(section.classroom.startdate) > new Date()) {
        if (!foundNext) {
          foundNext = true
          section.status = 'next'
        } else section.status = 'future'
      } else {
        section.status = 'past'
      }
    }
    return section
  })
  return (
    <BlockList small={!!small}>
      {sections.map(({ path, name, image, info, status, classroom }, key) => {
        const [show, toggleShow] = useState(status === 'next' ? true : false)
        let readableDate =
          (classroom &&
            classroom.startdate &&
            classroom.startdate.split('T')[0].split('-')) ||
          null
        if (classroom && readableDate && readableDate.length)
          readableDate = `${readableDate[2]}/${readableDate[1]}/${readableDate[0]}`
        return (
          <BlockListItem
            key={key}
            color={status === 'past' ? 'disabled' : 'primary'}
            image={image}
            header={
              <h3>
                <small
                  className={
                    status === 'past' ? 'float-left disabled' : 'float-left'
                  }
                  onClick={() => toggleShow(!show)}
                >
                  {status !== 'next' && <FontAwesomeIcon icon={'caret-down'} />}{' '}
                </small>
                <Link
                  to={uniSlash(`${url}/section/${path}`)} // url can be '/' or '/section/:path'
                >
                  <span className={status === 'past' ? 'disabled' : null}>
                    {name}
                    {readableDate ? (
                      <small className={'float-right small'}>
                        {readableDate}
                      </small>
                    ) : null}
                  </span>
                </Link>
              </h3>
            }
          >
            {info && show ? <span>{render(info)}</span> : null}
          </BlockListItem>
        )
      })}
    </BlockList>
  )
})

export default SectionList
