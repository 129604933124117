import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

import { useQuery } from '@apollo/react-hooks'
import { COURSENAME, SECTIONNAME, MODULENAME, PAGENAME } from './queries'

import './breadcrumbs.scss'

const getQuery = type =>
  ({
    course: COURSENAME,
    section: SECTIONNAME,
    module: MODULENAME,
    page: PAGENAME
  }[type] || false)

const BreadcrumbWithData = ({ type, path, query, ...crumb }) => {
  const { loading, error, data } = useQuery(query, {
    variables: { path }
  })
  if (loading || error) {
    return <BreadcrumbItem {...crumb}>...</BreadcrumbItem>
  }
  // Unpack data
  let result = { name: '...' }
  if (data) {
    result = data.course || data.section || data.module || data.page || '...'
  }
  return <BreadcrumbItem {...crumb}>{result.name}</BreadcrumbItem>
}

const BreadCrumbs = ({ match: { params, url } }) => {
  const crumbler = ['course', 'tab', 'module', 'page']
  const isActive = path => path === url.split('/').pop()
  let to = ''
  const crumbs = crumbler.map(path => {
    let pathValue = params[path + 'Path']
    const type = (path === 'tab' && params['tabType']) || path
    if (!pathValue) {
      if (path === type) return null
      pathValue = type
    }
    const active = isActive(pathValue)
    const tag = (!active && Link) || undefined
    to += `/${type}/${pathValue}`
    const query = getQuery(type)
    // Hard escape null value
    if (!pathValue) return []
    return { tag, to, active, path: pathValue, type, query }
  })

  return (
    <Breadcrumb className={'breadcrumbs'}>
      <BreadcrumbItem tag={Link} to={'/'}>
        home
      </BreadcrumbItem>
      {crumbs.map((crumb, key) => {
        if (!crumb) return null
        const Component = crumb.query ? BreadcrumbWithData : BreadcrumbItem
        return <Component key={key} {...crumb} />
      })}
    </Breadcrumb>
  )
}

export default withRouter(BreadCrumbs)
