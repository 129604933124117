import React from 'react'
import { Container } from '../Containers'
import BreadCrumbs from '../../_shared/Breadcrumbs'

import './heading.scss'

const Heading = ({ children, tag, color, breadcrumbs, ...props }) => {
  return (
    <Container {...props} vertical tag={tag || 'header'} className={'heading'}>
      {children}
      {breadcrumbs && <BreadCrumbs />}
    </Container>
  )
}

Heading.propTypes = {}

export default Heading
