import { gql } from 'apollo-boost'

const DELETE_PAGE = gql`
  mutation DeletePage($path: String!) {
    deletePage(path: $path) {
      success
      message
      page {
        path
      }
    }
  }
`

const CREATE_PAGE = gql`
  mutation CreatePage($PageInput: PageInput!) {
    createPage(page: $PageInput) {
      success
      message
      page {
        path
      }
    }
  }
`

const UPDATE_PAGE = gql`
  mutation UpdatePage($UpdatePageInput: UpdatePageInput!) {
    updatePage(page: $UpdatePageInput) {
      success
      message
      page {
        path
        name
      }
    }
  }
`

export { CREATE_PAGE, DELETE_PAGE, UPDATE_PAGE }
