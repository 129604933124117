import React, { useState } from 'react'
import PropTypes from 'prop-types'

import UserListModal from './UserListModal'
import UserListItem from './UserListItem'

import './userlist.scss'

const maxDisplay = 10

const UserList = ({ users, modal, ...props }) => {
  const [isOpen, setOpen] = useState()
  const toggle = () => setOpen(!isOpen)
  return (
    <div
      className={`list__user${modal ? ' list__user-modal' : ''}`}
      onClick={toggle}
    >
      {users.slice(0, maxDisplay).map((user, key) => (
        <UserListItem key={key} {...user} />
      ))}
      {users.length > maxDisplay && (
        <UserListItem key={maxDisplay} overflow={users.length - maxDisplay} />
      )}
      {modal && (
        <UserListModal
          toggle={toggle}
          isOpen={isOpen}
          users={users}
          title={modal}
          fields={props.fields || []}
        />
      )}
    </div>
  )
}

UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  modal: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.string)
}

export default UserList
