export const InteractiveVideoForm = [
  {
    name: 'interactiveVideo.show',
    key: 'interactiveVideo',
    type: 'checkbox',
    label: 'Interactieve video toevoegen?'
  },
  {
    name: 'interactiveVideo.url',
    label: 'Vimeo url',
    type: 'text',
    dependent: {
      on: 'interactiveVideo.show',
      operator: 'is',
      values: true
    }
  },
  {
    name: 'interactiveVideo.videoTime',
    label: 'Video Length in minutes',
    type: 'text',
    schema: {
      description:
        'time user needs to spend on video in minutes (default: 4 minutes)'
    }
  },
  {
    name: 'interactiveVideo.timestamps',
    type: 'subform',
    dependent: {
      on: 'interactiveVideo.show',
      operator: 'is',
      values: true
    },
    attributes: {
      className: 'timestampSubform',
      columns: [
        { name: 'hh', label: 'HH:' },
        { name: 'mm', label: 'mm:' },
        { name: 'ss', label: 'ss' },
        { name: 'references', label: 'gelinkte elementen' }
      ]
    },
    elements: [
      {
        name: 'hh',
        type: 'number',
        label: 'HH:',
        layout: { col: { sm: 4, xs: 12 } },
        attributes: {
          min: 0
        }
      },
      {
        name: 'mm',
        type: 'number',
        label: 'mm:',
        layout: { col: { sm: 4, xs: 12 } },
        attributes: {
          min: 0,
          max: 59
        }
      },
      {
        name: 'ss',
        type: 'number',
        label: 'ss',
        layout: { col: { sm: 4, xs: 12 } },
        attributes: {
          min: 0,
          max: 59
        }
      },
      {
        name: 'references',
        type: 'subform',
        label: 'Linked page elements',
        layout: { col: { sm: 12, xs: 12 } },
        attributes: {
          className: 'referencesSubform'
        },
        schema: {
          description:
            'You can edit the order of the linked page elements by using the arrows here.'
        },
        elements: [
          {
            name: 'reference',
            label: 'reference',
            schema: {
              description:
                'Enter the reference of the page-element here to link it to the timestamp'
            },
            type: 'text'
          }
        ]
      }
    ]
  }
]
