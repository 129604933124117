import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import { sortTimestamps } from '../../../_helpers/timestamps'
import { Form } from '../../_shared/Form/Uniligual'

const InteractiveVideoPage = ({ page, result, saveResult }) => {
  // Let's talk this through
  // Order timestamps --> done
  // Initial load: video --> done
  // Reach earliest timestamp? State change to show form with content of linked element --> done
  // Dynamically build elements for form --> done
  // Continue video until next timestamp --> done
  // State change to show form with new elements --> done
  // On complete, state change to hide form --> done
  // Expand to allow multiple forms for multiple page elements (array of references) --> done
  // TODO: remember which ones have been handled, start up with correct nextTimestamp (progress)

  // Variables
  const { url, timestamps } = page.interactiveVideo
  const sortedTimestamps = sortTimestamps(timestamps)

  // Handlers
  let [firstPass, setFirstPass] = useState(true)
  let [timestamp, setTimestamp] = useState(sortedTimestamps[0])
  let [iterator, setIterator] = useState(0)
  let [playing, setPlaying] = useState(false)
  let [content, setContent] = useState(null)

  // Methods
  const getContent = () => {
    let result = []
    timestamp.references.map(({ reference }) => {
      return page.content.map(item => {
        if (item.name === reference) {
          return result.push(item)
        }
        return null
      })
    })
    setContent(result)
  }
  const setNextTimestamp = async previousTimestamp => {
    const nextTimestamp = sortedTimestamps[iterator + 1]
    setTimestamp(nextTimestamp)
    return timestamp
  }
  const handleProgress = async e => {
    // Round off current playtime and compare to active pending timestamp
    if (
      timestamp &&
      Math.round(e.playedSeconds) >= timestamp.timestampInSeconds
    ) {
      if (!firstPass) {
        setIterator(iterator + 1) // catch accidents early
      }
      setFirstPass(!firstPass)
      // Timestamp was reached
      setPlaying(false)
      getContent()
      await setNextTimestamp(timestamp.timestampInSeconds)
    }
  }

  // Video config
  const config = {
    id: 'interactiveVideoPlayer',
    url: url,
    playing: playing,
    onPlay: () => setPlaying(true),
    onPause: () => setPlaying(false),
    onProgress: e => handleProgress(e)
  }

  return (
    <>
      {/* Video container */}
      <>
        <ReactPlayer {...config} />
      </>
      {!playing && content ? (
        <Form
          key={page.path}
          elements={content}
          initialModel={result}
          onStateChange={saveResult}
        />
      ) : null}
    </>
  )
}

export default InteractiveVideoPage
