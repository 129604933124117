import React, { useEffect } from 'react'

const JitsiBox = ({ config }) => {
  const { jitsi, roomNumber } = config
  const startConference = () => {
    try {
      const domain = '8x8.vc'
      const options = {
        roomName: [jitsi, roomNumber].join('/'),
        width: 1100,
        height: 500,
        parentNode: document.getElementById('jitsi-container'),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false
        },
        configOverwrite: {
          disableSimulcast: false
        }
      }

      const api = new window.JitsiMeetExternalAPI(domain, options)
      api.addEventListener('videoConferenceJoined', () => {
        console.log('Local User Joined')
      })
    } catch (error) {
      console.error('Failed to load Jitsi API', error)
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) startConference()
    else alert('Jitsi Meet API script not loaded')
    // eslint-disable-next-line
  }, [])

  return <div id='jitsi-container' />
}

export default JitsiBox
