import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from '@lefapps/translations'
import { Container, Heading } from '../_layout/Containers'
import { Main } from '../_layout/Grid'

const Static = withRouter(
  ({
    match: {
      params: { pagePath }
    }
  }) => {
    return (
      <Main>
        <Heading>
          <h1>
            <Translate _id={`static/page/${pagePath}/title`}>
              {pagePath}
            </Translate>
          </h1>
        </Heading>
        <Container vertical width='tablet'>
          <Translate _id={`static/page/${pagePath}/content`} md>
            {pagePath}
          </Translate>
        </Container>
      </Main>
    )
  }
)

export default Static
