import sortBy from 'lodash/sortBy'
// This function is used to set up a new module for translation
// It checks if it has any pages and sets them up for translation
// All translations work based on their originId
const translateModulePages = ({
  newModule,
  originalModule,
  createPage,
  alert
}) => {
  // flip order to keep pageOrder intact after saving
  const reversedPageList = originalModule.pages.reverse()
  reversedPageList.map((page, i) => {
    // prepare page for translation
    let doc = page
    doc.parentType = 'module'
    doc.parentPath = newModule.path // new parent module
    doc.originId = doc.path
    delete doc.path // new page path on create
    doc.name = page.name + '-' + newModule.language // new name to base new path on

    // create the new page
    createPage({ variables: { PageInput: doc } })
    return alert.show(
      // 'preparing pages:',
      i + 1 + '/' + originalModule.pages.length
    )
  })
}

// Takes a list of modules or courses, checks for originId, adds labels, returns list
const sortTranslatedContent = contents => {
  contents = sortBy(contents, ['originId'])
  contents.map(content => {
    if (content.originId && content.originId !== content.path) {
      const original =
        contents.filter(obj => obj.path === content.originId) || {}
      content.class = 'translation'
      content.label = `Translation of ${original.name || content.originId}`
    }
    if (content.originId && content.originId === content.path) {
      content.label = 'Original'
    }
    return content
  })
  return contents
}

export { translateModulePages, sortTranslatedContent }
