import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Amplify from 'aws-amplify'
import config from './config'

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.Region,
    userPoolId: config.UserPoolId,
    identityPoolId: config.IdentityPoolId,
    userPoolWebClientId: config.UserPoolClientId
  },
  API: {
    graphql_endpoint: config.ServiceEndpoint,
    graphql_endpoint_iam_region: config.Region
  },
  Storage: {
    AWSS3: {
      bucket: config.ClientBucket,
      region: config.Region
    }
  }
})

ReactDOM.render(<App />, document.getElementById('root'))
