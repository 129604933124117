import React, { useState } from 'react'
import { Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@apollo/react-hooks'

import { Translate } from '@lefapps/translations'

const DownloadAction = ({ variables, onReady, query }) => {
  const { loading, error, data } = useQuery(query, { variables })
  const [isOpen, setOpen] = useState(true)
  const toggle = () => {
    const target = !isOpen
    if (!target) onReady()
    setOpen(target)
  }
  if (loading) return <Spinner />
  if (error) {
    console.error(`${query} Error:`, error)
    onReady()
  }
  const path = Object.keys(data)[0]
  const download = data[path] // should return url to generated csv on S3
  if (download) {
    const file = URL.createObjectURL(new Blob([download], { type: 'text/csv' }))
    const filename = path + '.csv'
    return (
      <Modal
        toggle={toggle}
        isOpen={isOpen}
        wrapClassName='admin-dashboard__style'
      >
        <ModalHeader toggle={toggle}>
          <Translate _id='button/download_results'>
            Download resultaten
          </Translate>
        </ModalHeader>
        <ModalBody>
          <a
            href={file}
            target='_blank'
            rel='noopener noreferrer'
            download={filename}
          >
            <Translate _id='button/download'>Download</Translate> {filename}
          </a>
        </ModalBody>
      </Modal>
    )
  }
  return null
}

export const Download = props => {
  const [clicked, setClicked] = useState()
  return (
    <button
      className='btn btn-lg'
      onClick={() => setClicked(true)}
      title='Download CSV'
    >
      {clicked ? (
        <DownloadAction {...props} onReady={() => setClicked(false)} />
      ) : (
        <FontAwesomeIcon icon='download' />
      )}
    </button>
  )
}
export default Download
