import ApolloClient, { InMemoryCache } from 'apollo-boost'
import config from '../config'
import { Auth } from 'aws-amplify'

const testing = process.env.REACT_APP_TEST

const apolloClient = new ApolloClient({
  uri: config.ServiceEndpoint + '/graphql',
  typeDefs: {},
  request: async operation => {
    if (operation.variables) {
      const omitTypename = (key, value) =>
        key === '__typename' ? undefined : value
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      )
    }
    const headers = {
      authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
    }
    if (testing) headers.user = (await Auth.currentAuthenticatedUser()).username
    operation.setContext({ headers })
  },
  cache: new InMemoryCache({
    dataIdFromObject: doc =>
      `${doc.__typename}:${doc.path ||
        doc._id ||
        doc.message ||
        doc.course ||
        doc.name}`
  })
})

export default apolloClient
