import React from 'react'
import { Button, Alert } from 'reactstrap'

import { useAlert } from 'react-alert'

import { useMutation } from '@apollo/react-hooks'
import { UPDATE_PAGE, CREATE_PAGE } from './mutations'
import { ADMIN_MODULE } from '../queries'

import { Form, FormEditor } from '../../../_shared/Form/Uniligual'
import { formElements as elements } from './FormElements'
import { InteractiveVideoForm } from './InteractiveVideoForm'
import { createTimestamps } from '../../../../_helpers/timestamps'

import { BoardBody } from '@lefapps/admin-dashboard/lib/AdminDashboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate } from '@lefapps/translations'

const PageForm = ({ page, modulePath, history }) => {
  let { content, interactiveVideo, ...pageModel } = page || {}
  const alert = useAlert()

  // store unsaved page setup
  const [setup, setSetup] = React.useState(content || [])

  // store unsaved interactive video
  const [video, setInteractiveVideo] = React.useState(interactiveVideo || {})

  // Page mutations
  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: ({ updatePage: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
  })

  const [createPage] = useMutation(CREATE_PAGE, {
    onCompleted: ({ createPage: { success, message, page } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
      history.push(`/admin/modules/${modulePath}/pages/${page.path}`)
    },
    update: (
      cache,
      {
        data: {
          createPage: { page }
        }
      }
    ) => {
      const variables = {
        path: modulePath
      }
      const clientData = cache.readQuery({
        query: ADMIN_MODULE,
        variables
      })
      clientData.module.pages = [...clientData.module.pages, page]
      cache.writeQuery({ query: ADMIN_MODULE, variables, data: clientData })
    }
  })

  // Methods
  const onSubmit = async ({ interactiveVideo, ...doc }) => {
    doc.content = setup
    // Catch interactive video for timestamp magic
    if (interactiveVideo) {
      if (interactiveVideo.timestamps) {
        createTimestamps(interactiveVideo.timestamps)
      }
      doc.interactiveVideo = interactiveVideo
    }
    if (page && page.path) {
      const UpdatePageInput = { path: page.path, doc }
      updatePage({
        variables: { UpdatePageInput }
      })
    } else {
      doc.parentType = 'module'
      doc.parentPath = modulePath
      createPage({ variables: { PageInput: doc } })
    }
  }
  return (
    <BoardBody>
      <Alert color={'info'}>
        <FontAwesomeIcon icon={'info-circle'} />
        <Translate _id='page/form/info_saving'>
          Druk na het toepassen van de pagina-elementen steeds nog eens op de
          knop Opslaan!
        </Translate>
      </Alert>
      <Form
        key={page.path}
        elements={elements.concat(InteractiveVideoForm)}
        initialModel={pageModel}
        onSubmit={onSubmit}
      >
        <Button type='submit' color='success'>
          <Translate _id='button/save'>Opslaan</Translate>
        </Button>
      </Form>
      <hr />
      {/* Page elements */}
      <h4>
        <Translate _id='page/elements'>Pagina-elementen:</Translate>
      </h4>
      <FormEditor
        onChange={setSetup}
        sortable
        initialModel={content || []}
        key={page.path + '-formEditor'}
        // buttonText={'Toepassen'}
        helpText={'Druk na het toepassen nog eens op de knop ‘Bewaren’!'}
      >
        <Button color={'success'} type={'submit'}>
          <Translate _id='button/apply'>Toepassen</Translate>
        </Button>
        <small>
          <Translate _id='page/form/info_save_short'>
            Bewaren doe je met de knop “Bewaren” bovenaan!
          </Translate>
        </small>
      </FormEditor>
      <hr />
      <h4>
        <Translate _id='page/form/example'>Voorbeeld</Translate>
      </h4>
      <Form elements={setup} />
      <hr />
      {/* Optional interactive video */}
      <Form
        elements={InteractiveVideoForm}
        initialModel={video}
        key={page.path + '-interactiveVideoForm'}
        onSubmit={e => {
          setInteractiveVideo(e)
        }}
      >
        {/* TODO: onStageChange instead of onSubmit for better UX
        <Button type='submit' color='secondary' data-cy='interactiveVideo_form'>
          Interactieve Video opslaan
        </Button>
        <br />
        <small>
          Vergeet niet om wanneer je klaar bent met aanpassingen om ook steeds
          bovenaan op 'Bewaren' te klikken
        </small> */}
      </Form>
    </BoardBody>
  )
}

export default PageForm
