import CertificateConfigForm from './_shared/certificateConfig/CertificateConfigForm'
import Course from './Courses/Course'
import CourseCertificates from './Courses/CourseCertificates/CourseCertificates'
import Courses from './Courses/Courses'
import File from './Files/File'
import Files from './Files/Files'
import LinkFiles from './Files/LinkFiles'
import LinkModules from './Modules/LinkModules'
import LinkTeachers from './Users/LinkTeachers'
import LinkUsers from './Users/LinkUsers'
import Module from './Modules/Module'
import Modules from './Modules/Modules'
import Page from './Modules/Page/Page'
import Pages from './Modules/Page/Pages'
import Result from './Modules/Result/Result'
import Results from './Modules/Result/Results'
import Role from './Roles/Role'
import Roles from './Roles/Roles'
import Section from './Sections/Section'
import Sections from './Sections/Sections'
import Translations from './Translations/List'
import User from './Users/User'
import Users from './Users/Users'

const paths = [
  {
    path: '/courses',
    component: Courses,
    label: 'Cursussen',
    views: [
      {
        path: '/new-course',
        component: Course,
        label: 'Nieuwe Cursus Maken'
      },
      {
        path: '/:coursePath',
        component: Course,
        label: 'Cursus Bewerken'
      }
    ]
  },
  {
    path: '/sections',
    component: Sections,
    excludeFromMenu: true,
    label: 'Secties',
    views: [
      {
        path: '/new',
        component: Section,
        label: 'Nieuwe Sectie Maken'
      },
      {
        path: '/:sectionPath',
        component: Section,
        label: 'Sectie Bewerken'
      }
    ]
  },
  {
    path: '/modules',
    component: Modules,
    label: 'Modules',
    views: [
      {
        path: '/link-existing',
        component: LinkModules,
        label: 'Bestaande Modules Koppelen'
      },
      {
        path: '/new',
        component: Module,
        label: 'Nieuwe Module Maken'
      },
      {
        path: '/:modulePath',
        component: Module,
        label: 'Module Bewerken'
      }
    ]
  },
  {
    path: '/pages',
    component: Pages,
    excludeFromMenu: true,
    label: `Pagina's`,
    views: [
      {
        path: '/new',
        component: Page,
        label: 'Nieuwe Pagina Maken'
      },
      {
        path: '/:pagePath',
        component: Page,
        label: 'Pagina Bewerken'
      }
    ]
  },
  {
    // Note: this route can only exist in /module/:modulePath/result
    path: '/results',
    component: Results,
    excludeFromMenu: true,
    label: 'Resultaten',
    views: [
      {
        path: '/:userId',
        component: Result,
        label: 'Individueel resultaat'
      }
    ]
  },
  {
    path: '/files',
    component: Files,
    label: 'Documenten',
    views: [
      {
        path: '/link-existing',
        component: LinkFiles,
        label: 'Documenten koppelen'
      },
      {
        path: '/new',
        component: File,
        label: 'Toevoegen'
      }
    ]
  },
  {
    path: '/translations',
    component: Translations,
    label: 'Teksten',
    views: []
  },
  {
    path: '/users',
    component: Users,
    label: 'Deelnemers',
    views: [
      {
        path: '/course-certificates',
        component: CourseCertificates,
        label: 'Toekenning Certificaten Beheren'
      },
      {
        path: '/link-existing',
        component: LinkUsers,
        label: 'Bestaande Modules Koppelen'
      },
      {
        path: '/new',
        component: User,
        label: 'Nieuwe Deelnemer Maken'
      },
      {
        path: '/:userId',
        component: User,
        label: 'Deelnemer Bewerken'
      }
    ]
  },
  {
    path: '/roles',
    component: Roles,
    label: 'Rollen',
    views: [
      {
        path: '/new-role',
        component: Role,
        label: 'Nieuwe Rol Maken'
      },
      {
        path: '/:roleName',
        component: Role,
        label: 'Rol Bewerken'
      }
    ]
  },
  {
    path: '/link-teachers',
    component: LinkTeachers,
    excludeFromMenu: true,
    label: ''
  },
  {
    path: '/certificate-config',
    excludeFromMenu: true,
    component: CertificateConfigForm,
    label: 'Certificaat bewerken'
  }
]

export default paths
