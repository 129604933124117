import { gql } from 'apollo-boost'
import { fileParts } from '../_shared/fragments'

const DELETE_FILE = gql`
  mutation DeleteFile($path: String!) {
    deleteFile(path: $path) {
      success
      message
      file {
        path
      }
    }
  }
`

const CREATE_FILE = gql`
  mutation CreateFile($FileInput: FileInput!) {
    createFile(file: $FileInput) {
      success
      message
      file {
        ...FileParts
      }
    }
  }
  ${fileParts}
`

const UPDATE_FILE = gql`
  mutation UpdateFile($UpdateFileInput: UpdateFileInput) {
    updateFile(file: $UpdateFileInput) {
      success
      message
      file {
        ...FileParts
      }
    }
  }
  ${fileParts}
`

export { CREATE_FILE, DELETE_FILE, UPDATE_FILE }
