import { gql } from 'apollo-boost'

export const fileParts = gql`
  fragment FileParts on File {
    path
    name
    type
    url
  }
`
export const imageParts = gql`
  fragment ImageParts on Image {
    name
    alt
  }
`
export const userParts = gql`
  fragment UserParts on User {
    _id
    email
    name
    language
    profession
    roles
    courses
    modules
  }
`

export const courseParts = gql`
  fragment CourseParts on Course {
    path
    name
    originId
    language
    info
    showUserList
    location
    startdate
    enddate
    teacher
    published
    courseCertificateConfig
    image {
      ...ImageParts
    }
  }
  ${imageParts}
`

export const sectionParts = gql`
  fragment SectionParts on Section {
    path
    name
    originId
    info
    date
    location
    teacher
    image {
      ...ImageParts
    }
  }
  ${imageParts}
`
export const moduleParts = gql`
  fragment ModuleParts on Module {
    path
    name
    originId
    info
    language
    published
    showCorrect
    test
    anonymousPages
    randomized
    gateProgress
    moduleCertificateConfig
    image {
      ...ImageParts
    }
  }
  ${imageParts}
`
export const pageParts = gql`
  fragment PageParts on Page {
    path
    name
    content
    interactiveVideo
  }
`
