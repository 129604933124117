import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Icon = ({ icon, right, color, tag, single, ...props }) => {
  const mapIcon = {
    course: ['lef', 'course'],
    section: 'puzzle-piece',
    module: 'book',
    page: 'book-open',
    file: 'file-alt',
    team: 'user-friends',
    markdown: 'file-alt',
    question: 'list-ul',
    scored: 'tasks',
    video: 'film',
    interactive: 'photo-video',
    locked: 'lock',
    result: 'flag-checkered'
  }

  const Tag = tag || 'small'

  const className = ['icon']
  if (right) className.push('float-right')
  else if (!single) className.push('mr-3')
  if (color) className.push(`text-${color}`)
  if (props.className) className.push(props.className)

  return (
    <Tag {...props} className={className.join(' ')}>
      <FontAwesomeIcon icon={mapIcon[icon] || icon} />
    </Tag>
  )
}
