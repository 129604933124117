const formElements = [
  {
    name: 'name',
    label: {
      nl: 'Naam',
      en: 'Name',
      fr: 'Nom'
    },
    type: 'text',
    required: true
  }
]

export { formElements }
