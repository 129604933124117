import config from '../config'

const layout = {
  col: {
    md: 12
  }
}

const userFormElements = [
  {
    name: 'name',
    label: {
      nl: 'Naam',
      en: 'Name',
      fr: 'Nom'
    },
    type: 'text',
    required: true,
    layout
  },
  {
    name: 'email',
    label: {
      nl: 'Email',
      en: 'Email',
      fr: 'Email'
    },
    type: 'text',
    required: true,
    attributes: {
      disabled: true
    }
  },
  {
    name: 'phone',
    label: {
      nl: 'Telefoonnummer',
      en: 'Phonenumber',
      fr: 'Numéro de téléphone'
    },
    type: 'text',
    layout
  },
  config &&
    config.signup &&
    config.signup.customFields &&
    config.signup.customFields.includes('profession') && {
      name: 'profession',
      label: {
        nl: 'Vakgebied',
        en: 'Profession',
        fr: 'Domaine de travail'
      },
      type: 'text',
      layout
    },
  config &&
    config.signup &&
    config.signup.customFields &&
    config.signup.customFields.includes('riziv') && {
      name: 'riziv',
      label: {
        nl: 'RIZIV nummer',
        en: 'RIZIV number',
        fr: 'RIZIV'
      },
      type: 'text',
      layout
    },
  {
    name: 'description',
    label: {
      nl: 'Korte bio',
      en: 'Short bio',
      fr: 'Brève biographie'
    },
    type: 'textarea',
    layout
  },
  {
    name: 'image',
    label: {
      nl: 'Profielfoto',
      en: 'Profile photo',
      fr: 'Photo de profil'
    },
    type: 'upload',
    attributes: {
      placeholder: 'Choose a foto'
    },
    schema: {
      description: {
        nl:
          'Een foto (bij voorkeur jpg) van jezelf. De foto wordt vierkant bijgesneden, zorg dat je centraal op de foto staat.',
        en:
          'A photo (preferably jpg) of yourself. The photo is cropped square, make sure you are in the centre of the photo.',
        fr:
          'Une photo (de préférence jpg) de vous-même. La photo sera coupée au carré, assurez-vous que vous êtes au centre de la photo.'
      }
    }
  },
  {
    name: 'fullPrivacy',
    label: {
      nl: 'Mijn emailadres en info niet tonen aan andere deelnemers',
      en: 'Do not show my emailadress and info to other participants',
      fr:
        'Ne pas montrer mon adresse électronique et mes informations aux autres participants.'
    },
    type: 'checkbox'
  }
]

export default userFormElements
