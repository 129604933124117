import config from '../config'

const { customFields = [] } = config.signup || {}

const customFieldsConfig = {
  profession: {
    label: 'Profession',
    key: 'profession',
    required: false,
    displayOrder: 10,
    type: 'string',
    custom: true
  },
  riziv: {
    label: 'RIZIV nummer',
    key: 'riziv',
    required: false,
    displayOrder: 11,
    type: 'string',
    custom: true
  }
}

export const signUpConfig = {
  defaultCountryCode: '32',
  signUpFields: [
    {
      label: 'Name',
      key: 'name',
      required: true,
      displayOrder: -10,
      type: 'string'
    },
    {
      label: 'Phone Number',
      key: 'phone_number',
      required: false,
      type: 'phone_number',
      displayOrder: -7
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: -5,
      type: 'email'
    }
  ].concat(customFields.map(key => customFieldsConfig[key]))
}
