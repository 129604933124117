import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAlert } from 'react-alert'

import cloneDeep from 'lodash/cloneDeep'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_COURSES } from './queries'
import { CURRENT_USER_ADMIN } from '../Users/queries'
import { DELETE_COURSE } from './mutations'

import { Translate } from '@lefapps/translations'
import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem,
  DashboardLink
} from '@lefapps/admin-dashboard'

import config from '../../config'

import guard from '../_shared/guard'
import { sortTranslatedContent } from '../_shared/TranslateContents'

const CourseItem = ({ user, course, index, history }) => {
  const alert = useAlert()
  // Mutations
  const [deleteCourse, { loading, error }] = useMutation(DELETE_COURSE, {
    onCompleted: ({ deleteCourse: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    },
    update: (
      cache,
      {
        data: {
          deleteCourse: {
            course: { path: deletedPath }
          }
        }
      }
    ) => {
      const data = cloneDeep(cache.readQuery({ query: ADMIN_COURSES }))
      data.courses = data.courses.filter(({ path }) => path !== deletedPath)
      cache.writeQuery({ query: ADMIN_COURSES, data })
    }
  })

  // Methods
  const remove = () => {
    deleteCourse({ variables: { path: course.path } })
  }

  const duplicate = () => {
    const initialModel = cloneDeep(course)
    initialModel.name = `Duplicate of ${course.name}`
    delete initialModel['path']
    history.push('courses/new-course', { initialModel })
  }

  // Components (with guard)
  let actions = {}
  if (guard({ user, content: course, action: 'edit' })) {
    actions.edit = { to: `/courses/${course.path}`, type: 'edit' }
  }
  if (guard({ user, action: 'create' })) {
    actions.duplicate = { onClick: duplicate, type: 'duplicate' }
  }
  if (guard({ user, content: course, action: 'delete' })) {
    actions.remove = { onClick: remove, loading, error }
  }

  // Render
  return (
    <BoardListItem label={index} actions={actions}>
      <span className={course.class}>
        {course.name}
        <br />
        {course.label && <small> {course.label}</small>}
      </span>
    </BoardListItem>
  )
}

const Courses = ({ history }) => {
  // Queries
  const queryVars = { onAdmin: true }

  const { loading, error, data } = useQuery(ADMIN_COURSES, {
    variables: queryVars
  })

  const { loading: userLoading, data: currentUserData } = useQuery(
    CURRENT_USER_ADMIN
  )

  // Early exit
  if (loading || userLoading) return <BoardBody loading />
  if (error) {
    console.error('useQuery', 'ADMIN_COURSES', error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_courses'>
          Er ging iets mis bij het laden van cursussen.
        </Translate>
      </BoardBody>
    )
  }

  let { courses } = data
  console.log(currentUserData)
  const { currentUserWithRules } = currentUserData || {}

  // Sort multi-lingual content if multi-lingual is active in client's config
  if (config && config.multilingual) {
    courses = sortTranslatedContent(courses)
  }

  // Components
  const actions = guard({ user: currentUserWithRules, action: 'create' }) && (
    <DashboardLink
      className='btn btn-lg'
      view='new-course'
      data-cy='new_course'
      title='Create new course'
    >
      <FontAwesomeIcon icon='plus' />
    </DashboardLink>
  )

  // Render
  return (
    <>
      <BoardHead title='Cursussen' actions={actions} />
      <BoardBody>
        {courses.length <= 0 ? (
          <Translate _id='courses/no_courses'>
            Er zijn geen cursussen.
          </Translate>
        ) : (
          <BoardList>
            {courses.map((course, key) => (
              <CourseItem
                key={key}
                user={currentUserWithRules}
                course={course}
                history={history}
              />
            ))}
          </BoardList>
        )}
      </BoardBody>
    </>
  )
}

export default Courses
