import React, { useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Translator } from '@lefapps/translations'
import { AdminAlerts } from '@lefapps/admin-dashboard'
import { positions } from 'react-alert'
import { Helmet } from 'react-helmet'
import { Auth, Hub } from 'aws-amplify'
import ReactGA from 'react-ga'

import apolloClient from './apolloClient'

import translatorConfig from './translatorConfig'
import config from './config'

import ScrollToTop from '../_helpers/scroll'
import { library } from '../_helpers/icons' // Makes sure icons are present
import { Matomo } from '../_helpers/tracking'

import Navigation, { getBranding } from './_shared/Navigation'
import Footer from './_shared/Footer'

import { ApplyBranding } from './_layout/_shared/ApplyBranding'
import { Container } from './_layout/Containers'
import { NavBar } from './_layout/NavBar'
import './_layout/_shared/app.scss'

import Admin from './Admin'
import Home from './Home'
import { LoginPage } from './Login'
import CourseRouter from './Course'
import ModuleRouter from './Module'
import UserRouter from './User'
import { Info } from './Info/Info'
import Breakoutroom from './Course/Section/Breakoutroom'
import Classroom from './Course/Section/Classroom'
import Static from './Static/Static'

const { name = '', colors = {}, description = '' } = config

const Unauthenticated = () => (
  <>
    <NavBar title={getBranding()} />
    <Container width={'full'} lean vertical tag={'main'} id={'main'}>
      <Switch>
        <Route path={'/login'} component={LoginPage} />
        <Route path={'/:path?'} component={Info} />
      </Switch>
    </Container>
  </>
)

const Authenticated = () => (
  <Switch>
    <Route path='/admin' component={Admin} />
    <Route path='/'>
      <Navigation />
      <Container width={'full'} lean id={'main'} tag={'main'}>
        <Switch>
          <Route
            path='/course/:coursePath/section/:sectionPath/classroom/:classroomName'
            component={Classroom}
          />
          <Route
            path='/course/:coursePath/section/:sectionPath/breakoutroom/:roomNumber'
            component={Breakoutroom}
          />
          <Route path='/course' component={CourseRouter} />
          <Route path='/module' component={ModuleRouter} />
          <Route path='/user' component={UserRouter} />
          <Route path='/certificates' component={UserRouter} />
          <Route path='/:pagePath' component={Static} />
          <Route path='/' exact component={Home} />
        </Switch>
      </Container>
    </Route>
  </Switch>
)

const App = () => {
  useEffect(() => {
    onLoad()
  })
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, userHasAuthenticated] = useState(false)

  const onLoad = async () => {
    try {
      await Auth.currentSession()
      userHasAuthenticated(true)
    } catch (e) {
      if (e !== 'No current user') {
        console.log(e)
      }
    }
    setIsAuthenticating(false)
  }

  Hub.listen('auth', data => {
    const { payload } = data
    if (payload.event === 'signIn') {
      onLoad()
    }
    if (payload.event === 'signOut') {
      userHasAuthenticated(false)
      onLoad()
    }
  })

  // Google analytics
  if (config.GA) {
    ReactGA.initialize(config.GA)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  return (
    <Translator {...translatorConfig}>
      <AdminAlerts position={positions.TOP_CENTER}>
        <Helmet titleTemplate={`${name} :: %s`} defaultTitle={name}>
          <meta content={colors.primary || ''} name='theme-color' />
          <meta content={description} />
        </Helmet>
        <ApplyBranding {...config} />
        <ApolloProvider client={apolloClient}>
          <Matomo optIn={true}>
            <BrowserRouter>
              <ScrollToTop />
              {(isAuthenticating || !library) && 'loading'}
              {!isAuthenticated && <Unauthenticated />}
              {isAuthenticated && <Authenticated />}
              <Footer />
            </BrowserRouter>
          </Matomo>
        </ApolloProvider>
      </AdminAlerts>
    </Translator>
  )
}

export default App
