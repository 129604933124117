import { gql } from 'apollo-boost'

const ADMIN_ROLE = gql`
  query Role($name: String!) {
    role(name: $name) {
      _id
      name
      actions
    }
  }
`

const ADMIN_ROLES = gql`
  query Roles {
    roles {
      name
    }
  }
`

export { ADMIN_ROLE, ADMIN_ROLES }
