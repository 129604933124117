import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { detect } from 'detect-browser'

import { Translate } from '@lefapps/translations'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_LOG } from '../../mutations'
import { SECTION } from './queries'
import { CURRENT_USER } from '../../User/queries'

import config from '../../config'

import { Container } from '../../_layout/Containers'
import { InfoBox } from '../../_layout/InfoBox'
import { Grid, Main } from '../../_layout/Grid'
import { Icon } from '../../_layout/Bits'
import { Loading } from '../../_shared/Loading'

// import ZoomBox from './ZoomBox'
// import JitsiBox from './JitsiBox'

const Classroom = ({
  match: {
    params: { coursePath, sectionPath, classroomName }
  }
}) => {
  // Updating log
  const [updatedLog, setUpdatedLog] = useState(false)
  useEffect(() => {
    setUpdatedLog(false)
  }, [classroomName])

  // Queries
  const { loading, error, data } = useQuery(SECTION, {
    variables: { path: sectionPath }
  })
  const { loading: userLoading, error: userError, data: userData } = useQuery(
    CURRENT_USER
  )
  // Mutations
  const [updateLog] = useMutation(UPDATE_LOG)

  // Early exit
  if (loading || userLoading) return <Loading />
  if (error || userError) {
    return (
      <Loading color={'danger'}>
        <Translate _id='error/loading_classroom'>
          Er ging iets mis bij het laden van deze classroom.
        </Translate>
      </Loading>
    )
  }

  const browser = detect()

  const { section } = data
  const classroom = section.classroom
  const { currentUser } = userData || []

  const { meetingId, pwd, type } = classroom

  const getRole = () => {
    if (currentUser.roles.includes('teacher')) {
      return 1
    } else if (currentUser.roles.includes('admin')) {
      return 1
    } else return 0
  }

  let API_KEYS
  let zoomConfig

  // TODO: get from somewhere secret
  if (type === 'zoom') {
    API_KEYS = {
      apiKey: config.ZOOM_API_KEY,
      apiSecret: config.ZOOM_API_SECRET
    }
    zoomConfig = {
      apiKey: API_KEYS.apiKey || null,
      apiSecret: API_KEYS.apiSecret || null,
      meetingNumber: meetingId || null,
      webEndpoint: config.ZOOM_PREFIX || null,
      leaveUrl: `/course/${coursePath}/section/${sectionPath}`,
      userName: currentUser.name || currentUser.email || 'onbekend', // (required)
      userEmail: currentUser.email || 'email_onbekend', // (optional) must be the attendee email address
      passWord: pwd || null,
      role: getRole() // 0 for guest, 1 for host
    }
  }

  // Update userlog on entering classroom
  if (classroomName && !updatedLog) {
    setUpdatedLog(true)
    const doc = {
      tracking: {
        type: 'classrooms',
        path: sectionPath + '/' + classroomName,
        lastSeen: new Date()
      }
    }
    const UpdateLogInput = { doc }
    updateLog({ variables: { UpdateLogInput } })
  }

  return (
    <Grid collapse={'tablet'}>
      <Main>
        <Container order={0} tag={'h2'} hide>
          <Link to={`/course/${coursePath}/section/${sectionPath}`}>
            <Icon icon='arrow-left' />
          </Link>
          <br />
          <Icon icon={'chalkboard-teacher'} />
          {classroomName || 'Classroom'}
        </Container>
        {classroom.type === 'zoom' ? (
          // {/* Zoom */}
          // {/* Firefox gets the better fix, it is what it is ¯\_(ツ)_/¯ */}
          browser && browser.name === 'firefox' ? (
            <Container order={1} className='iframeContainer'>
              <iframe
                title={sectionPath + classroomName}
                allow='microphone; camera'
                src={`https://zoom.us/wc/${meetingId}/join?prefer=1&un=${btoa(
                  zoomConfig.userName
                )}&pwd=${pwd}`}
                frameBorder='0'
                sandbox='allow-forms allow-scripts allow-same-origin'
              />
            </Container>
          ) : (
            <div id="zoom_not_supported">Not supported.</div>
            // <ZoomBox meetConfig={zoomConfig} />
          )
        ) : (
          // Jitsi
          <div id="jitsi_not_supported">Not supported.</div>
          // <JitsiBox config={classroom} />
        )}
        {classroom.extraInfo ? (
          <Container>
            <InfoBox
              title={'Extra informatie:'}
              icon={'info'}
              content={classroom.extraInfo}
            />
          </Container>
        ) : null}
      </Main>
    </Grid>
  )
}

export default Classroom
