import { gql } from 'apollo-boost'

const SAVE_RESULT = gql`
  mutation CreateResult($ResultInput: ResultInput!) {
    saveResult(result: $ResultInput) {
      success
      message
      result {
        result
      }
    }
  }
`

export { SAVE_RESULT }
