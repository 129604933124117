import { gql } from 'apollo-boost'
import { fileParts } from '../_shared/fragments'

const ADMIN_FILES = gql`
  query Files($course: String, $section: String, $filterByParent: String) {
    files(course: $course, section: $section, filterByParent: $filterByParent) {
      ...FileParts
    }
    course(path: $course) {
      path
      name
      files {
        ...FileParts
      }
    }
    section(path: $section) {
      path
      name
      files {
        ...FileParts
      }
    }
  }
  ${fileParts}
`

export { ADMIN_FILES }
