import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Translate } from '@lefapps/translations'

import { useMutation } from '@apollo/react-hooks'
import { UPDATE_LOG } from '../../mutations'

import config from '../../config'

import { Container } from '../../_layout/Containers'
import { InfoBox } from '../../_layout/InfoBox'
import { Grid, Main, Aside } from '../../_layout/Grid'
import { Icon } from '../../_layout/Bits'

import { ModuleList } from '../../Module/Modules'
import { FileList } from '../Files'

const ClassroomView = ({ classroom, path, updateLog }) => {
  return (
    <>
      <Container>
        {/* Classroom */}
        <h3>
          <Icon icon={'chalkboard-teacher'} /> Classroom
        </h3>
        <Link
          to={`${path}/classroom/${classroom.name || 'default'}`}
          className='btn btn-lg btn-light main-classroom-link'
        >
          {classroom.name || 'Main Classroom'}
        </Link>
        {classroom.type &&
        classroom.type === 'zoom' &&
        classroom.meetingId &&
        classroom.meetingId.length &&
        classroom.pwd &&
        classroom.pwd.length ? (
          <>
            | of open in de{' '}
            <a
              href={
                config && config.ZOOM_PREFIX
                  ? `https://${config.ZOOM_PREFIX}zoom.us/j/${classroom.meetingId}?pwd=${classroom.pwd}`
                  : `https://zoom.us/j/${classroom.meetingId}?pwd=${classroom.pwd}`
              }
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => {
                // log visits to external service
                const doc = {
                  tracking: {
                    type: 'classrooms',
                    path: path + '/' + classroom.name,
                    lastSeen: new Date()
                  }
                }
                const UpdateLogInput = { doc }
                updateLog({ variables: { UpdateLogInput } })
              }}
            >
              <Translate _id='section/zoomLink'>
                Zoom desktop applicatie
              </Translate>
            </a>
          </>
        ) : null}
      </Container>
      {/* Breakout rooms */}
      {classroom.breakoutrooms && classroom.breakoutrooms.length ? (
        <Container>
          <h4>
            <Icon icon={'chalkboard-teacher'} type='small' /> Breakoutrooms
          </h4>
          {classroom.breakoutrooms.map((room, i) => {
            return (
              <span key={room.roomNumber + i}>
                <Link
                  to={`${path}/breakoutroom/${room.roomNumber}`}
                  className='btn btn-lg btn-light secondary-classroom-link'
                >
                  {room.name || i + 1}
                </Link>{' '}
              </span>
            )
          })}
          {classroom.breakoutroomInfo && (
            <>
              <hr />
              <InfoBox content={classroom.breakoutroomInfo} />
            </>
          )}
        </Container>
      ) : null}
    </>
  )
}

const RecordingsView = ({ classroom, path, updateLog }) => {
  return (
    <Container>
      <InfoBox>
        <p>
          <Translate _id='section/live_session_over'>
            Het live gedeelte van deze les is voorbij.
          </Translate>
        </p>
        {classroom.recordings && (
          <div>
            <Translate _id='section/recorings'>Herbekijk de sessie:</Translate>
            <br />
            {classroom.recordings.map((recording, i) => {
              return (
                <a
                  key={i}
                  href={recording && recording.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => {
                    // log visits to external service
                    const doc = {
                      tracking: {
                        type: 'recordings',
                        path: path + '/' + classroom.name + '/' + recording.url,
                        lastSeen: new Date()
                      }
                    }
                    const UpdateLogInput = { doc }
                    updateLog({ variables: { UpdateLogInput } })
                  }}
                >
                  {recording.title || (i === 0 && 'here') || i + 1}
                </a>
              )
            })}
          </div>
        )}
      </InfoBox>
    </Container>
  )
}

export const Section = ({
  path,
  name,
  info,
  modules,
  files,
  classroom,
  ...props
}) => {
  // Updating log
  const [updatedLog, setUpdatedLog] = useState(false)
  useEffect(() => {
    setUpdatedLog(false)
  }, [path])
  // Mutations
  const [updateLog] = useMutation(UPDATE_LOG)
  // Update userlog on entering course
  if (path && !updatedLog) {
    setUpdatedLog(true)
    const doc = {
      tracking: { type: 'sections', path: path, lastSeen: new Date() }
    }
    const UpdateLogInput = { doc }
    updateLog({ variables: { UpdateLogInput } })
  }

  return (
    <div className='sectionview'>
      <Grid collapse={'tablet'}>
        <Main>
          <Container order={1} lean vertical>
            <Grid collapse={'desktop'}>
              <Main>
                {/* Toggle classroom or recording */}
                {classroom ? (
                  !classroom.enddate ||
                  new Date(classroom.enddate) >= new Date() ? (
                    <ClassroomView
                      classroom={classroom}
                      path={path}
                      updateLog={updateLog}
                    />
                  ) : (
                    <RecordingsView
                      classroom={classroom}
                      path={path}
                      updateLog={updateLog}
                    />
                  )
                ) : null}

                {modules && !!modules.length && (
                  <Container vertical>
                    <h3>Modules</h3>
                    <ModuleList modules={modules} small />
                  </Container>
                )}

                {files && !!files.length && (
                  <Container vertical>
                    <h3>
                      <Translate _id='section/files'>Documenten</Translate>
                    </h3>
                    <FileList files={files} small />
                  </Container>
                )}
              </Main>
            </Grid>
          </Container>
        </Main>
        <Aside>
          <Container order={0} tag={'h2'} hide>
            <Icon icon={'section'} />
            {name}
          </Container>
          {info && (
            <Container order={1}>
              <InfoBox
                color={'primary'}
                title={'Info'}
                icon={'info'}
                content={info}
              />
            </Container>
          )}
          {props.date && (
            <Container>
              <InfoBox
                color={'primary'}
                title={'Les-Data'}
                icon={'calendar-alt'}
                content={props.date}
              />
            </Container>
          )}
          {props.teacher && (
            <Container>
              <InfoBox
                color={'primary'}
                title={'Leraar/Verantwoordelijke'}
                icon={'user-graduate'}
                content={props.teacher}
              />
            </Container>
          )}
          {props.location && (
            <Container>
              <InfoBox
                color={'primary'}
                title={'Locatie'}
                icon={'location-arrow'}
                content={props.location}
              />
            </Container>
          )}
        </Aside>
      </Grid>
    </div>
  )
}
