import { gql } from 'apollo-boost'
import { moduleParts } from '../_shared/fragments'

const MODULE = gql`
  query Module($path: String!) {
    module(path: $path) {
      ...ModuleParts
      certificate
      pages {
        path
        name
        content
        result
        type
        completed
        estimatedTime
      }
    }
  }
  ${moduleParts}
`

const MODULES = gql`
  query Modules($noParents: Boolean, $published: Boolean, $onHome: Boolean) {
    modules(noParents: $noParents, published: $published, onHome: $onHome) {
      ...ModuleParts
    }
  }
  ${moduleParts}
`

const CERTIFICATE = gql`
  query Certificate($modulePath: String!) {
    moduleCertificate(path: $modulePath)
  }
`

const MODULES_BY_ORIGINID = gql`
  query ModuleTranslationsPaths($path: String) {
    moduleTranslationsPaths(path: $path) {
      path
      language
      originId
    }
  }
`

const CHECK_CERTIFICATE_STATUS = gql`
  query CheckCertificateStatus($path: String) {
    checkCertificateStatus(path: $path)
  }
`

export {
  MODULE,
  MODULES,
  CERTIFICATE,
  MODULES_BY_ORIGINID,
  CHECK_CERTIFICATE_STATUS
}
