import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { SimpleList, SimpleListItem } from '../SimpleList'
import { initials } from '../../../_helpers/users'

export const UserListModal = ({ toggle, isOpen, title, users, fields }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title || 'Deelnemers'}</ModalHeader>
      <ModalBody>
        <SimpleList>
          {users.map(({ name, email, ...user }, key) => (
            <SimpleListItem key={key} label={initials(name)}>
              <p>
                {fields.includes('name') && <strong>{name}</strong>}
                <br />
                {fields.includes('email') && <small>{email}</small>}
                <dt>
                  {fields.map(f => {
                    if (['name', 'email'].includes(f)) return null
                    return (
                      <>
                        <dt>{f}</dt>
                        <dd>{user[f] || '-'}</dd>
                      </>
                    )
                  })}
                </dt>
              </p>
            </SimpleListItem>
          ))}
        </SimpleList>
      </ModalBody>
    </Modal>
  )
}

export default UserListModal
