import React from 'react'
import PropTypes from 'prop-types'

const Aside = ({ children }) => (
  <aside className={`grid__aside`}>{children}</aside>
)

Aside.propTypes = {
  children: PropTypes.node
}

export default Aside
