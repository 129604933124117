import { gql } from 'apollo-boost'

const DELETE_ROLE = gql`
  mutation DeleteRole($name: String!) {
    deleteByName(name: $name) {
      success
      message
      role {
        name
      }
    }
  }
`

const CREATE_ROLE = gql`
  mutation CreateRole($RoleInput: RoleInput!) {
    createRole(role: $RoleInput) {
      success
      message
      role {
        name
        actions
      }
    }
  }
`

const UPDATE_ROLE = gql`
  mutation UpdateRole($UpdateRoleInput: UpdateRoleInput!) {
    updateRole(role: $UpdateRoleInput) {
      success
      message
      role {
        name
        actions
      }
    }
  }
`

export { DELETE_ROLE, CREATE_ROLE, UPDATE_ROLE }
