import React from 'react'

import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { Translate } from '@lefapps/translations'

import { LoginMenu } from '../Login'

const UserMenu = () => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle nav caret data-cy='userui_dropdown'>
        <FontAwesomeIcon icon='user' />
      </DropdownToggle>
      <DropdownMenu right>
        <div className={'dropdown-header'}>
          <LoginMenu />
        </div>
        <Link to='/user' className='dropdown-item text-body'>
          <Translate _id='userMenu/edit_profile'>Profile</Translate>
        </Link>
        <Link to='/user/certificates' className='dropwdown-item text-body'>
          <Translate _id='userMenu/certificates'>Certificates</Translate>
        </Link>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserMenu
