import React from 'react'
import { EasyForm } from '@lefapps/forms'
import { useTranslator, Translate } from '@lefapps/translations'

import { uploadComponent } from './custom/Upload'

import './questionnaire.scss'
import './adminswitches.scss'

const MyForm = new EasyForm()

MyForm.addComponent('upload', uploadComponent)

/** @func Form
 *
 * Form for normal use.
 * This includes an upload component to upload
 * files and images to config.storage.assets.
 *
 */

// Custom withTranslator for form use with (newer) @lefapps/translations@^4.0.0
const withTranslator = WrappedComponent => {
  return xprops => {
    const { languages, language } = useTranslator()
    const translator = {
      languages,
      currentLanguage: language,
      component: Translate
    }
    return <WrappedComponent translator={translator} {...xprops} />
  }
}

const Form = withTranslator(MyForm.instance())
Form.displayName = 'TranslatedEasyForm'

const FormEditor = withTranslator(MyForm.editor())
FormEditor.displayName = 'TranslatedEasyFormEditor'

export default Form
export { Form, FormEditor }
