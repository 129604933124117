import React from 'react'

import { BoardHead, BoardBody } from '@lefapps/admin-dashboard'

const Home = () => {
  return (
    <>
      <BoardHead title={'Welkom'} />
      <BoardBody>
        <p>
          Dit is het <em>admin</em>gedeelte van je e-learningplatform. Hier kan
          je je lessen en deelnemers beheren.
        </p>
      </BoardBody>
    </>
  )
}

export default Home
