import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, ModalBody } from 'reactstrap'

import { Translate } from '@lefapps/translations'

import { useQuery } from '@apollo/react-hooks'
import { CHECK_CERTIFICATE_STATUS } from '../../Module/queries'

import { Icon } from '../../_layout/Bits'
import { FormResult } from '../Form/Uniligual'
import { Loading } from '../Loading'

import checkCompletionStatus from '../CompletionStatus'
import FeedbackForm from './FeedbackForm'

import './moduleResult.scss'

const ModuleResult = ({
  user,
  module: { pages, test, anonymousPages, path },
  Wrapper = 'div',
  skipTypes = [],
  isAdmin,
  isTeacher,
  suspended,
  history,
  coursePath
}) => {
  const totals = [0, 0]
  const fullAccess = isAdmin || isTeacher
  const modulePath = path

  const [openedPage, setOpenedPage] = useState(null)
  const [closeModal, setCloseModal] = useState(false)

  // New: automatically generate certificate when if parent course && course complete or if !parent && module complete
  // 1. check for parent
  let { loading, data } = useQuery(CHECK_CERTIFICATE_STATUS, {
    variables: { path: path }
  })

  if (loading || !user) return <Loading />

  if (openedPage && openedPage !== null) {
    return (
      <FeedbackForm
        user={user._id}
        module={path}
        pagePath={openedPage.path}
        page={openedPage.content}
        result={openedPage.result}
        setOpenedPage={setOpenedPage}
      />
    )
  } else {
    return (
      <>
        <Modal
          isOpen={!closeModal && data && data.checkCertificateStatus}
          toggle={() => {
            setCloseModal(true)
          }}
        >
          <ModalBody>
            <Translate _id='result/certificate_message'>
              Congratulations, you received a certificate for completing this
              course. Return to the overview of this course to download your
              certificate.
            </Translate>
            <br />
            <Link
              className='btn btn-primary'
              to={`/course/${coursePath}/courseCertificate`}
            >
              <Translate _id='result/certificate_link'>
                Course overview
              </Translate>
            </Link>
          </ModalBody>
        </Modal>
        <Wrapper
          className={
            'moduleResult' +
            (fullAccess ? ' moduleResult__admin' : '') +
            (suspended ? '' : ' moduleResult__giveaway')
          }
        >
          {pages.map(({ name, type, content, result, completed, path }, pi) => {
            if (completed) completed = checkCompletionStatus(content, result)
            const { __scoring = {} } = result || {}
            totals[0] += __scoring.__score
            totals[1] += __scoring.__maxScore
            return (
              <div
                className='mb-4 pageLink'
                key={pi}
                onClick={() => {
                  if (!fullAccess) {
                    history.push(`/module/${modulePath}/page/${path}`)
                  } else {
                    setOpenedPage({ path, content, result })
                  }
                }}
              >
                <h4 className='bg-light text-primary p-3'>
                  <Icon icon={type} /> {pi + 1}.
                  {anonymousPages && !fullAccess ? null : name}
                  {!suspended && fullAccess && __scoring.__maxScore > 0 && (
                    <span className='float-right'>
                      {__scoring.__score}{' '}
                      <small>/ {__scoring.__maxScore}</small>
                    </span>
                  )}
                  {completed && (
                    <FontAwesomeIcon icon='check' className='float-right' />
                  )}
                </h4>
                {(result && !test) || (result && fullAccess) ? (
                  <FormResult
                    elements={content.filter(
                      ({ type }) => !skipTypes.includes(type)
                    )}
                    initialModel={result}
                  />
                ) : null}
              </div>
            )
          })}
          {!suspended && fullAccess && !!totals[1] && (
            <h3 className='bg-primary text-white p-3'>
              <small>
                <Translate _id='label/total'>Totaal:</Translate>
              </small>
              <span className='float-right'>
                {Math.round((100 * totals[0]) / totals[1])} %
              </span>
            </h3>
          )}
        </Wrapper>
      </>
    )
  }
}

export default withRouter(ModuleResult)
