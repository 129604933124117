import { gql } from 'apollo-boost'

const ADD_CERTIFICATE_TO_USER = gql`
  mutation addCertificateToUser(
    $_id: ID!
    $CourseCertificateInput: CourseCertificateInput!
  ) {
    addCertificateToUser(
      CourseCertificateInput: $CourseCertificateInput
      _id: $_id
    ) {
      success
      message
      user {
        _id
        email
        name
        courses
        courseCertificates {
          course
          path
          valid
        }
      }
    }
  }
`
const REMOVE_CERTIFICATE_FROM_USER = gql`
  mutation removeCertificateFromUser(
    $_id: ID!
    $CourseCertificateInput: CourseCertificateInput!
  ) {
    removeCertificateFromUser(
      CourseCertificateInput: $CourseCertificateInput
      _id: $_id
    ) {
      success
      message
      user {
        _id
        email
        name
        courses
        courseCertificates {
          course
          path
          valid
        }
      }
    }
  }
`

export { ADD_CERTIFICATE_TO_USER, REMOVE_CERTIFICATE_FROM_USER }
