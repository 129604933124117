import { gql } from 'apollo-boost'

const COURSENAME = gql`
  query CourseName($path: String!) {
    course(path: $path) {
      name
    }
  }
`

const SECTIONNAME = gql`
  query SectionName($path: String!) {
    section(path: $path) {
      name
    }
  }
`

const MODULENAME = gql`
  query ModuleName($path: String!) {
    module(path: $path) {
      name
    }
  }
`

const PAGENAME = gql`
  query PageName($path: String!) {
    page(path: $path) {
      name
    }
  }
`

export { COURSENAME, SECTIONNAME, MODULENAME, PAGENAME }
