import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BoardBody, BoardHead } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useQuery } from '@apollo/react-hooks'
import { ADMIN_MODULE_RESULT } from './queries'
import assignCertificate from './helpers/assignCertificate'

import ModuleResult from '../../../_shared/ModuleResult/index'

const Result = ({
  match: {
    params: { modulePath, userId }
  }
}) => {
  const { loading, error, data } = useQuery(ADMIN_MODULE_RESULT, {
    variables: { path: modulePath, user: userId }
  })
  const { isCertified, certifyResult } = assignCertificate({ ...data })

  if (loading) {
    return <BoardBody loading />
  }

  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='page/error_loading'>
          Er ging iets mis bij het laden van deze pagina.
        </Translate>
      </BoardBody>
    )
  }

  const { module, user } = data
  const { name, email, profession } = user

  // Actions
  const certifyAction = !isCertified && (
    <button
      className='btn btn-lg'
      onClick={certifyResult}
      title='Assign certificate'
    >
      <FontAwesomeIcon icon='award' />
    </button>
  )

  return (
    <>
      <BoardHead title={module.name} actions={certifyAction}>
        <em>
          Van {name}
          {profession && ` - ${profession}`} - <b>{email}</b>
        </em>
      </BoardHead>
      <BoardBody>
        <ModuleResult
          user={user}
          module={module}
          skipTypes={['markdown']}
          isAdmin
          isTeacher
        />
      </BoardBody>
    </>
  )
}

export default Result
