import { gql } from 'apollo-boost'
import { imageParts } from '../_shared/fragments'

const UPDATE_USER = gql`
  mutation UpdateUser($UpdateUserInput: UpdateUserInput!) {
    updateUser(user: $UpdateUserInput) {
      success
      message
      user {
        _id
        email
        name
        roles
        phone
        profession
        riziv
        description
        image {
          ...ImageParts
        }
      }
    }
  }
  ${imageParts}
`

export { UPDATE_USER }
