import get from 'lodash/get'

import markdownImage from '../../_shared/Form/custom/MarkdownImage'
import config from '../../config'
import { uploadHandler } from '../../../_helpers/thumbnails'

const formElements = [
  {
    name: 'name',
    label: {
      nl: 'Cursus titel',
      en: 'Course title',
      fr: 'Titre de cours'
    },
    type: 'text',
    required: true,
    layout: {
      col: {
        md: 12
      }
    }
  },
  config &&
    config.multilingual && {
      name: 'language',
      label: {
        nl: 'Taal',
        en: 'Language',
        fr: 'Langue'
      },
      type: 'radio',
      options: config.multilingual,
      required: true
    },
  {
    name: 'image',
    label: {
      nl: 'Foto',
      en: 'Image',
      fr: 'Image'
    },
    type: 'upload',
    host: get(config, 'storage.assets'),
    uploadHandler,
    attributes: {
      placeholder: 'Select an image'
    }
  },
  {
    name: 'published',
    label: {
      nl: 'Inhoud publiceren',
      en: 'Publish content',
      fr: 'Publier du contenu'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl: 'Ongepubliceerde inhoud wordt niet getoond aan deelnemers.',
        en: `Unpublished content isn't shown to particpants.`,
        fr: 'Le contenu non publié ne sera pas montré aux participants.'
      }
    }
  },
  {
    name: 'public',
    label: {
      nl: 'Openbare inhoud',
      en: 'Public content',
      fr: 'Contenu public'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl:
          'Openbare inhoud is zichtbaar voor alle geregistreerde deelnemers, ook zij die niet gekoppeld zijn aan deze inhoud.',
        en:
          'Public content is visible to all registered users, including those who are not linked to this content.',
        fr:
          'Le contenu public est visible par tous les participants inscrits, y compris ceux qui ne sont pas liés à ce contenu.'
      }
    }
  },
  {
    name: 'startdate',
    label: {
      nl: 'Startdatum',
      en: 'Startdate',
      fr: 'Date de début'
    },
    type: 'datetime-local',
    layout: {
      col: {
        md: 6
      }
    }
  },
  {
    name: 'enddate',
    label: {
      nl: 'Einddatum',
      en: 'Enddate',
      fr: 'Date de fin'
    },
    type: 'datetime-local',
    layout: {
      col: {
        md: 6
      }
    }
  },
  {
    name: 'teacher',
    label: {
      nl: 'Contact informatie leraar/verantwoordelijke',
      en: 'Contact information of teacher/administrator',
      fr: `Coordonnées de l'enseignant / responsable`
    },
    type: 'textarea',
    md: true,
    toolbar: [[markdownImage]],
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'location',
    label: {
      nl: 'Locatie',
      en: 'Location',
      fr: 'Location'
    },
    type: 'textarea',
    md: true,
    toolbar: [[markdownImage]],
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'info',
    label: {
      nl: 'Extra Informatie',
      en: 'Extra Information',
      fr: 'Informations supplémentaires'
    },
    type: 'textarea',
    md: true,
    toolbar: [[markdownImage]],
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'showUserList',
    label: {
      nl: 'Lijst deelnemers tonen in het overzicht?',
      en: 'Show list of participants in the overview?',
      fr: `Afficher la liste des participants dans la vue d'ensemble?`
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'showTeam',
    label: {
      nl: 'Lijst leerkrachten/admins tonen in het overzicht?',
      en: 'Show list of teachers/admins in the overview?',
      fr: `Afficher la liste des admins dans la vue d'ensemble?`
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    }
  }
]

export { formElements }
