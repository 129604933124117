import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './progress.scss'

const ProgressBar = ({ levelUp, ...props }) => (
  <Fragment>
    <Progress {...props} id='progress' color='secondary' />
    {levelUp && (
      <Link id='levelUp' to={levelUp}>
        <FontAwesomeIcon icon='arrow-left' />
      </Link>
    )}
  </Fragment>
)

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired // integer between 0..100 to indicate the current progress; OR supply a custom value together with a (min and) max value
}

export default ProgressBar
