import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cloneDeep from 'lodash/cloneDeep'

import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem,
  DashboardLink
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_ROLES } from './queries'
import { CURRENT_USER_ADMIN } from '../Users/queries'
import { DELETE_ROLE } from './mutations'

import guard from '../_shared/guard'

const RoleItem = ({ role, index, history, refetch, user }) => {
  // Mutations
  const [deleteRole, { loading, error }] = useMutation(DELETE_ROLE, {
    onCompleted: ({ deleteRole }) => {
      refetch()
    }
  })

  // Methods
  const remove = () => {
    if (
      window.confirm(
        'Ben je zeker dat je deze rol van het platform wenst te verwijderen? Gebruikers met deze rol kunnen zo toegang tot bepaalde acties verliezen.'
      )
    ) {
      deleteRole({ variables: { name: role.name } })
    }
  }

  const duplicate = () => {
    const initialModel = cloneDeep(role)
    initialModel.name = `Duplicaat van ${role.name}`
    history.push('roles/new-role', { initialModel })
  }

  // Components
  const actions = guard({ user, action: 'manage_roles' }) && {
    edit: { to: `/roles/${role.name}` },
    duplicate: {
      onClick: duplicate,
      type: 'duplicate'
    },
    remove: { onClick: remove, loading, error }
  }

  // Render
  return (
    <BoardListItem label={index} actions={actions}>
      {role.name}
    </BoardListItem>
  )
}

const Roles = ({ history }) => {
  // Queries
  const { loading, error, data, refetch } = useQuery(ADMIN_ROLES)

  const { loading: userLoading, data: currentUserData } = useQuery(
    CURRENT_USER_ADMIN
  )

  // Early exit
  if (loading || userLoading) return <BoardBody loading />
  if (error) {
    console.error('useQuery', 'ADMIN_ROLES', error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_roles'>
          Er ging iets mis bij het laden van rollen.
        </Translate>
      </BoardBody>
    )
  }

  const { roles } = data
  const { currentUserWithRules } = currentUserData || {}

  // Components
  const actions = guard({
    user: currentUserWithRules,
    action: 'manage_roles'
  }) && (
    <DashboardLink
      className='btn btn-lg'
      view='new-role'
      data-cy='new_role'
      title='Niewe rol aanmaken'
    >
      <FontAwesomeIcon icon='plus' />
    </DashboardLink>
  )

  // Render
  return (
    <>
      <BoardHead title='Rollen' actions={actions} />
      <BoardBody>
        <blockquote>
          <Translate _id='roles/admin_warning'>
            De rol 'admin' kan niet aangepast of verwijderd worden en heeft alle
            rechten.
          </Translate>
        </blockquote>
        {roles.length <= 0 ? (
          <Translate _id='roles/no_roles'>
            Er zijn nog geen rollen geconfigureerd
          </Translate>
        ) : (
          <BoardList>
            {roles.map((role, i) => (
              <RoleItem
                role={role}
                key={i + 1}
                history={history}
                refetch={refetch}
                user={currentUserWithRules}
              />
            ))}
          </BoardList>
        )}
      </BoardBody>
    </>
  )
}

export default Roles
