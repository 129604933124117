import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { md } from '../../../_helpers/markdown'

import './infobox.scss'

const InfoBox = ({
  title,
  icon,
  color = 'primary',
  content = '',
  children,
  ...props
}) => {
  const { className = '' } = props || {}
  return (
    <div className={'infobox ' + className}>
      <h4 className={`infobox__head bg-${color}`}>
        {icon ? <FontAwesomeIcon icon={icon} /> : null}
        {title}
      </h4>
      {content ? (
        <div
          className='infobox__body'
          dangerouslySetInnerHTML={{ __html: md.render(content) }}
        />
      ) : (
        <div className='infobox__body'>{children}</div>
      )}
    </div>
  )
}

InfoBox.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  content: PropTypes.string
}

export default InfoBox
