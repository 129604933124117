import { gql } from 'apollo-boost'
import { courseParts } from '../_shared/fragments'

const ADMIN_COURSE = gql`
  query Course($path: String!) {
    course(path: $path) {
      ...CourseParts
      public
      owners {
        _id
      }
      modules {
        path
        name
      }
      sections {
        path
        name
        owners {
          _id
        }
      }
      users {
        _id
        name
      }
    }
  }
  ${courseParts}
`

const ADMIN_COURSES = gql`
  query Courses($onAdmin: Boolean) {
    courses(onAdmin: $onAdmin) {
      ...CourseParts
      owners {
        _id
      }
    }
  }
  ${courseParts}
`

export { ADMIN_COURSE, ADMIN_COURSES }
