import React from 'react'
import { useAlert } from 'react-alert'
import { Button } from 'reactstrap'

import { BoardBody, BoardHead } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_COURSE } from '../../Courses/mutations'
import { ADMIN_COURSE } from '../../Courses/queries'
import { UPDATE_MODULE } from '../../Modules/mutations'
import { ADMIN_MODULE } from '../../Modules/queries'

import { Form } from '../../../_shared/Form/index'

import { formElements } from './FormElements'

const CertificateConfigForm = ({
  match: {
    params: { modulePath, coursePath }
  }
}) => {
  const alert = useAlert()

  let data

  // Queries
  const { loading, error, data: courseData } = useQuery(ADMIN_COURSE, {
    variables: { path: coursePath || '' }
  })

  const {
    loading: moduleLoading,
    error: moduleError,
    data: moduleData
  } = useQuery(ADMIN_MODULE, {
    variables: { path: modulePath || '' }
  })

  // Mutations
  const [updateCourse] = useMutation(UPDATE_COURSE, {
    onCompleted: ({ updateCourse: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
  })

  const [updateModule] = useMutation(UPDATE_MODULE, {
    onCompleted: ({ updateModule: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
  })

  // Loading data
  if (loading || moduleLoading)
    return <Translate _id='loading'>loading...</Translate>
  if (error || moduleError) {
    console.error(error || moduleError)
    return <Translate _id='error'>Er ging iets mis</Translate>
  }

  data = (courseData && courseData.course) || (moduleData && moduleData.module)

  // Methods
  const onSubmit = async doc => {
    delete doc['modules']
    delete doc['sections']
    delete doc['users']
    delete doc['owners']
    delete doc.pages
    if (coursePath) {
      doc.courseCertificateConfig = doc.certificateConfig
      delete doc.certificateConfig
      const UpdateCourseInput = { path: coursePath, doc }
      await updateCourse({ variables: { UpdateCourseInput } })
    } else {
      doc.moduleCertificateConfig = doc.certificateConfig
      delete doc.certificateConfig
      const UpdateModuleInput = { path: modulePath, doc }
      await updateModule({ variables: { UpdateModuleInput } })
    }
  }

  return (
    <>
      <BoardHead title={data.name || ''}>
        <em>
          <Translate _id='certificateConfigForm/title'>
            Certificaat bewerken
          </Translate>
        </em>
      </BoardHead>
      <BoardBody>
        <p>
          <Translate _id='certificateConfigForm/body'>
            Je kan hier de vereisten en instellingen van het certificaat voor
            deze inhoud beheren.
          </Translate>
          <br />
          <Translate _id='certificateConfigForm/body_detail'>
            Je kan een certificaat altijd manueel toekennen aan een deelnemer.
          </Translate>
          <br />
          {coursePath ? (
            <Translate _id='certificateConfigForm/howto_with_coursePath'>
              Om een certificaat manueel toe te kennen navigaar je eerst naar
              het tabblad deelnemers voor deze cursus en vervolgens naar
              'toekenning certificaten beheren'
            </Translate>
          ) : (
            <Translate _id='certificateConfigForm/howto_without_coursePath'>
              Om een certificaat manueel toe te kennen navigeer je naar het
              tabblad 'resultaten' voor deze module en klik je naast de naam van
              de deelnemer op het certificaat-icoon.
            </Translate>
          )}
        </p>
        <br />
        <Form
          data-cy='certificateConfigForm'
          elements={formElements}
          initialModel={data || {}}
          onSubmit={e => {
            onSubmit(e)
          }}
          key={data.path}
        >
          <Button type='submit' color='success'>
            <Translate _id='button/save'>Opslaan</Translate>
          </Button>
        </Form>
      </BoardBody>
    </>
  )
}

export default CertificateConfigForm
