
const config = JSON.parse(process.env.REACT_APP_CONFIG)

export default config

// const testing = process.env.REACT_APP_TEST
/**
export default require(testing === 'true'
  ? './serverless-output-test.json'
  : './serverless-output.json')
 */
