import { gql } from 'apollo-boost'
import { userParts } from '../_shared/fragments'

const DELETE_USER = gql`
  mutation DeleteUser($_id: String!) {
    deleteUser(_id: $_id) {
      success
      message
      user {
        _id
      }
    }
  }
`

const UPDATE_USER = gql`
  mutation UpdateUser($UpdateUserInput: UpdateUserInput!) {
    updateUser(user: $UpdateUserInput) {
      success
      message
      user {
        ...UserParts
      }
    }
  }
  ${userParts}
`

const ADD_ITEM_TO_USER = gql`
  mutation AddItemToUser($_id: ID!, $itemType: String!, $itemPath: String!) {
    addItemToUser(_id: $_id, itemType: $itemType, itemPath: $itemPath) {
      success
      message
      user {
        _id
        email
        name
        courses
        modules
      }
    }
  }
`

const REMOVE_ITEM_FROM_USER = gql`
  mutation RemoveItemFromUser(
    $_id: ID!
    $itemType: String!
    $itemPath: String!
  ) {
    removeItemFromUser(_id: $_id, itemType: $itemType, itemPath: $itemPath) {
      success
      message
      user {
        _id
        email
        name
        courses
        modules
      }
    }
  }
`

export { DELETE_USER, UPDATE_USER, ADD_ITEM_TO_USER, REMOVE_ITEM_FROM_USER }
