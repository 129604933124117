import { EasyForm } from '@lefapps/forms'
import { Components, Decorators } from '@lefapps/forms-pages'

// import { uploadComponent } from './custom/Upload'
// import markdownImage from './custom/MarkdownImage'

import './formEditor.scss'

const MyForm = new EasyForm()

MyForm.removeDecorator('layout')
MyForm.removeComponent('divider')
// uploadComponent.hideable = true
// MyForm.addComponent('upload', uploadComponent)
Object.keys(Components).forEach(component => {
  const Component = Components[component]
  // if (component === 'markdown') {
  //   const config = Component.config({})
  //   Component.config = () =>
  //     config.map(c => {
  //       if (c.type === 'textarea' && c.md) c.toolbar = [[markdownImage]]
  //       return c
  //     })
  // }
  MyForm.addComponent(component, Component)
})
Object.keys(Decorators).forEach(decorator =>
  MyForm.addDecorator(decorator, Decorators[decorator])
)
MyForm.removeComponent('buttons')

/** @function Form
 *
 * Form instance to use with page builder.
 * It is unilingual and contains components to render a page correctly
 */

const Form = MyForm.instance()
Form.displayName = 'EasyForm'

const FormEditor = MyForm.editor()
FormEditor.displayName = 'EasyFormEditor'

const FormResult = MyForm.results()
FormResult.displayName = 'EasyFormResult'

export default Form
export { Form, FormEditor, FormResult }
