import React from 'react'
import identity from 'lodash/identity'
import map from 'lodash/map'
import trim from 'lodash/trim'

const printCss = (suffix = '', convert = identity) => (value, property) =>
  `--${property}${suffix ? '-' + suffix : ''}: ${convert(value)};`

const rgbToHsl = (red, green, blue) => {
  const r = Number(trim(red)) / 255
  const g = Number(trim(green)) / 255
  const b = Number(trim(blue)) / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h,
    s,
    l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
      default:
        break
    }
    h /= 6
  }

  h = Math.round(360 * h)
  s = Math.round(100 * s)
  l = Math.round(100 * l)

  return [h, s, l]
}

const colorToHsl = color => {
  if (color.slice(0, 1) === '#') {
    let r,
      g,
      b = 0
    if (color.length === 4) {
      r = parseInt(color.substr(1, 1) + color.substr(1, 1), 16)
      g = parseInt(color.substr(2, 1) + color.substr(2, 1), 16)
      b = parseInt(color.substr(3, 1) + color.substr(3, 1), 16)
    } else {
      r = parseInt(color.substr(1, 2), 16)
      g = parseInt(color.substr(3, 2), 16)
      b = parseInt(color.substr(5, 2), 16)
    }
    return rgbToHsl(r, g, b)
  } else if (color.slice(0, 4) === 'rgba') {
    const [r, g, b] = color.slice(5, -1).split(',')
    return rgbToHsl(r, g, b).slice(0, 3)
  } else if (color.slice(0, 3) === 'rgb') {
    const [r, g, b] = color.slice(4, -1).split(',')
    return rgbToHsl(r, g, b)
  } else if (color.slice(0, 4) === 'hsla') {
    return color
      .slice(5, -1)
      .split(',')
      .slice(0, 3)
  } else if (color.slice(0, 3) === 'hsl') {
    return color.slice(4, -1).split(',')
  } else {
    // named color values are not yet supported
    console.error(
      'Named color values are not supported in the config. Convert it manually using this chart: https://htmlcolorcodes.com/color-names/'
    )
    return [0, 0, 16]
  }
}

export const ApplyBranding = ({ colors, storage, images, fonts }) => {
  return (
    <style>
      {':root {'}
      {colors &&
        map(
          colors,
          printCss('', color => {
            const hsl = colorToHsl(color)
            return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`
          })
        )}
      {colors &&
        map(
          colors,
          printCss('h', color => {
            const hsl = colorToHsl(color)
            return hsl[0]
          })
        )}
      {colors &&
        map(
          colors,
          printCss('s', color => {
            const hsl = colorToHsl(color)
            return `${hsl[1]}%`
          })
        )}
      {colors &&
        map(
          colors,
          printCss('l', color => {
            const hsl = colorToHsl(color)
            return `${hsl[2]}%`
          })
        )}
      {fonts && map(fonts, printCss('font'))}
      {storage && map(storage, printCss('storage'))}
      {images &&
        map(
          images,
          printCss('', v => v.join('.'))
        )}
      {images &&
        map(
          images,
          printCss('retina', v => v.join('@2x.'))
        )}
      {'}'}

      {map(fonts, font => {
        return `
        @font-face {
          font-family: "${font}";
          src: url("${storage.client}fonts/hinted-${font}-Regular.eot");
          src: url("${storage.client}fonts/hinted-${font}-Regular.eot?#iefix") format("embedded-opentype"),
            url("${storage.client}fonts/hinted-${font}-Regular.woff2") format("woff2"),
            url("${storage.client}fonts/hinted-${font}-Regular.woff") format("woff"),
            url("${storage.client}fonts/hinted-${font}-Regular.ttf") format("truetype"),
            url("${storage.client}fonts/hinted-${font}-Regular.svg#${font}-Regular") format("svg");
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: "${font}";
          src: url("${storage.client}fonts/hinted-${font}-Italic.eot");
          src: url("${storage.client}fonts/hinted-${font}-Italic.eot?#iefix") format("embedded-opentype"),
            url("${storage.client}fonts/hinted-${font}-Italic.woff2") format("woff2"),
            url("${storage.client}fonts/hinted-${font}-Italic.woff") format("woff"),
            url("${storage.client}fonts/hinted-${font}-Italic.ttf") format("truetype"),
            url("${storage.client}fonts/hinted-${font}-Italic.svg#${font}-Italic") format("svg");
          font-weight: normal;
          font-style: italic;
        }

        @font-face {
          font-family: "${font}";
          src: url("${storage.client}fonts/hinted-${font}-Bold.eot");
          src: url("${storage.client}fonts/hinted-${font}-Bold.eot?#iefix") format("embedded-opentype"),
            url("${storage.client}fonts/hinted-${font}-Bold.woff2") format("woff2"),
            url("${storage.client}fonts/hinted-${font}-Bold.woff") format("woff"),
            url("${storage.client}fonts/hinted-${font}-Bold.ttf") format("truetype"),
            url("${storage.client}fonts/hinted-${font}-Bold.svg#${font}-Bold") format("svg");
          font-weight: bold;
          font-style: normal;
        }

        @font-face {
          font-family: "${font}";
          src: url("${storage.client}fonts/hinted-${font}-BoldItalic.eot");
          src: url("${storage.client}fonts/hinted-${font}-BoldItalic.eot?#iefix") format("embedded-opentype"),
            url("${storage.client}fonts/hinted-${font}-BoldItalic.woff2") format("woff2"),
            url("${storage.client}fonts/hinted-${font}-BoldItalic.woff") format("woff"),
            url("${storage.client}fonts/hinted-${font}-BoldItalic.ttf") format("truetype"),
            url("${storage.client}fonts/hinted-${font}-BoldItalic.svg#${font}-BoldItalic") format("svg");
          font-weight: bold;
          font-style: italic;
        }

        `
      })}
    </style>
  )
}
