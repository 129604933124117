import React from 'react'
import { useAlert } from 'react-alert'

import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BoardBody, BoardHead, DashboardLink } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { CREATE_SECTION, UPDATE_SECTION } from './mutations'
import { ADMIN_SECTION } from './queries'
import { ADMIN_COURSE } from '../Courses/queries'
import { CURRENT_USER_ADMIN } from '../Users/queries'

import guard from '../_shared/guard'

import Form from '../../_shared/Form/index'
import { formElements as elements } from './FormElements'

const Section = ({
  match: {
    params: { coursePath, sectionPath }
  },
  location,
  history
}) => {
  const alert = useAlert()

  let _section

  // Queries
  const { loading, error, data } = useQuery(ADMIN_SECTION, {
    variables: { path: sectionPath },
    skip: !sectionPath
  })

  const { loading: userLoading, data: currentUserData } = useQuery(
    CURRENT_USER_ADMIN
  )

  // Mutations
  const [updateSection] = useMutation(UPDATE_SECTION, {
    onCompleted: ({ updateSection: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
  })

  const [createSection] = useMutation(CREATE_SECTION, {
    onCompleted: ({ createSection: { success, message, section } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
      // Reload form after creating new section to show edit form
      history.push(`/admin/courses/${coursePath}/sections/${section.path}`)
    },
    update: (
      cache,
      {
        data: {
          createSection: { section }
        }
      }
    ) => {
      const variables = {
        path: coursePath
      }
      const clientData = cache.readQuery({
        query: ADMIN_COURSE,
        variables
      })
      clientData.course.sections = [...clientData.course.sections, section]
      cache.writeQuery({ query: ADMIN_COURSE, variables, data: clientData })
    }
  })

  // Submit
  const onSubmit = doc => {
    delete doc.modules
    delete doc.owners
    if (_section.path) {
      const UpdateSectionInput = { path: _section.path, doc }
      updateSection({
        variables: { UpdateSectionInput }
      })
    } else {
      doc.parentType = 'course'
      doc.parentPath = coursePath
      createSection({ variables: { SectionInput: doc } })
    }
  }

  // Loading data
  if (loading || userLoading) return <BoardBody loading />
  if (error && sectionPath) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_section'>
          Er ging iets mis bij het laden van deze sectie
        </Translate>
      </BoardBody>
    )
  }

  // edit || duplicate || create
  _section =
    (data && data.section) ||
    (location && location.state && location.state.initialModel) ||
    {}

  if (!_section.path) {
    _section.published = true // set default for published to true when creating new section
  }

  const { currentUserWithRules } = currentUserData || {}

  // Methods
  const actions =
    sectionPath &&
    guard({
      user: currentUserWithRules,
      content: _section,
      action: sectionPath ? 'edit' : 'create'
    }) ? (
      <>
        {/* Admin can start timer here */}
        {_section &&
          _section.classroom &&
          _section.classroom.breakoutroomCountdown && (
            <DashboardLink
              title='Countdown starten'
              className='btn btn-lg'
              onClick={() => {
                let doc = _section
                delete doc.modules
                delete doc.owners
                doc.classroom.breakoutroomCountdownStarted = new Date()
                const UpdateSectionInput = { path: _section.path, doc }
                updateSection({
                  variables: { UpdateSectionInput }
                })
              }}
            >
              <FontAwesomeIcon icon={'stopwatch'} />
            </DashboardLink>
          )}
        <DashboardLink
          className='btn btn-lg'
          view='files'
          title='Bijlagen'
          data-cy='section__files'
        >
          <FontAwesomeIcon icon={'file-alt'} />
        </DashboardLink>
        <DashboardLink
          className='btn btn-lg'
          view='modules'
          title='Modules'
          data-cy='section__modules'
        >
          <FontAwesomeIcon icon={'book'} />
        </DashboardLink>
      </>
    ) : null
  return (
    <>
      <BoardHead title={_section.name || 'Nieuwe Sectie'} actions={actions}>
        <em>
          <Translate _id='section_title'>Sectie</Translate>
        </em>
      </BoardHead>
      <BoardBody>
        <Form
          elements={elements}
          initialModel={_section}
          onSubmit={e => {
            onSubmit(e)
          }}
          key={sectionPath}
        >
          {guard({
            user: currentUserWithRules,
            content: _section,
            action: sectionPath ? 'edit' : 'create'
          }) && (
            <Button type='submit' color='primary' data-cy='section_form'>
              <Translate _id='button/save'>Opslaan</Translate>
            </Button>
          )}
        </Form>
      </BoardBody>
    </>
  )
}

export default Section
