import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap'
import { Link } from 'react-router-dom'

import config from '../../config'

const { assets } = config.storage || {}

const BlockListItem = ({
  children,
  header,
  footer,
  link,
  to,
  color = 'dark',
  image,
  ...props
}) => {
  const className = [`list__block-item`]
  if (color) className.push(`list__block-item__${color}`)
  if (props.className) className.push(props.className)

  let backgroundImage
  if (image && image.name) {
    const [base, ext] = image.name.split('.')
    backgroundImage = `url(${assets}blocklist/${base}@2x.${ext})`
  }

  return (
    <Card
      {...props}
      tag={to || link ? Link : 'article'}
      to={to || link}
      className={className.join(' ')}
    >
      <CardHeader style={{ backgroundImage }}>{header}</CardHeader>
      <CardBody>{children}</CardBody>
      <CardFooter>{footer}</CardFooter>
    </Card>
  )
}

BlockListItem.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  to: PropTypes.string
}

export default BlockListItem
