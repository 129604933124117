import { gql } from 'apollo-boost'

import {
  courseParts,
  moduleParts,
  userParts,
  fileParts,
  sectionParts,
  imageParts
} from '../_shared/fragments'

// TODO: limit number of users returned https://stackoverflow.com/questions/60654123/max-number-of-results-from-graphql
const COURSE = gql`
  query Course($path: String!) {
    course(path: $path) {
      ...CourseParts
      showTeam
      modules {
        name
        info
        path
        image {
          _id
          name
          alt
        }
        entryPoint {
          type
          path
        }
      }
      users {
        ...UserParts
        fullPrivacy
      }
      owners {
        ...UserParts
        phone
        description
        profession
        image {
          ...ImageParts
        }
      }
      files {
        ...FileParts
      }
      sections {
        ...SectionParts
        classroom
        files {
          ...FileParts
        }
        modules {
          ...ModuleParts
        }
      }
    }
  }
  ${courseParts}
  ${moduleParts}
  ${userParts}
  ${fileParts}
  ${imageParts}
  ${sectionParts}
`

const USERS = gql`
  {
    users {
      ...UserParts
    }
  }
  ${userParts}
`

const COURSES = gql`
  query Courses($published: Boolean, $onHome: Boolean) {
    courses(published: $published, onHome: $onHome) {
      ...CourseParts
    }
  }
  ${courseParts}
`
const COURSE_CERTIFICATE = gql`
  query Certificate($coursePath: String!) {
    courseCertificate(path: $coursePath)
  }
`

const COURSES_BY_ORIGINID = gql`
  query CourseTranslationsPaths($path: String) {
    courseTranslationsPaths(path: $path) {
      path
      language
      originId
    }
  }
`

export { COURSE, COURSES, USERS, COURSE_CERTIFICATE, COURSES_BY_ORIGINID }
