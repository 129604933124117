import markdownImage from '../../_shared/Form/custom/MarkdownImage'

const formElements = [
  {
    name: 'name',
    label: {
      nl: 'Naam',
      en: 'Name',
      fr: 'Nom'
    },
    type: 'text',
    required: true
  },
  {
    name: 'image',
    label: {
      nl: 'Foto',
      en: 'Image',
      fr: 'Image'
    },
    type: 'upload',
    attributes: {
      placeholder: 'Choose image'
    }
  },
  {
    name: 'published',
    label: {
      nl: 'Inhoud publiceren',
      en: 'Publish content',
      fr: 'Publier du contenu'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl: 'Ongepubliceerde inhoud wordt niet getoond aan deelnemers.',
        en: `Unpublished content isn't shown to particpants.`,
        fr: 'Le contenu non publié ne sera pas montré aux participants.'
      }
    }
  },
  {
    name: 'date',
    label: {
      nl: 'Les-Data',
      en: 'Session dates',
      fr: 'Dates des cours'
    },
    type: 'textarea',
    md: true,
    toolbar: [[markdownImage]],
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'teacher',
    label: {
      nl: 'Info leraar/verantwoordelijke',
      en: 'Contact information of teacher/administrator',
      fr: `Coordonnées de l'enseignant / responsable`
    },
    type: 'textarea',
    md: true,
    toolbar: [[markdownImage]],
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'location',
    label: {
      nl: 'Locatie',
      en: 'Location',
      fr: 'Location'
    },
    type: 'textarea',
    md: true,
    toolbar: [[markdownImage]],
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'info',
    label: {
      nl: 'Informatie',
      en: 'Extra Information',
      fr: 'Informations supplémentaires'
    },
    type: 'textarea',
    md: true,
    toolbar: [[markdownImage]],
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'classroom.name',
    label: {
      nl: 'Classroom: Naam',
      en: 'Classroom: Name',
      fr: 'Classroom: Nom'
    },
    type: 'text',
    layout: {
      col: {
        md: 6
      }
    }
  },
  {
    name: 'classroom.type',
    label: {
      nl: 'Classroom: Type',
      en: 'Classroom: Type',
      fr: 'Classroom: Type'
    },
    type: 'radio',
    options: ['zoom', 'jitsi'],
    layout: {
      col: {
        md: 6
      }
    }
  },
  {
    name: 'classroom.startdate',
    label: {
      nl: 'Startdatum en tijd (GMT+1)',
      en: 'Startdate and time (GMT+1)',
      fr: 'Date et heure de début (GMT+1)'
    },
    type: 'datetime-local',
    layout: {
      col: {
        md: 6
      }
    }
  },
  {
    name: 'classroom.enddate',
    label: {
      nl: 'Einddatum en tijd (GMT+1)',
      en: 'Enddate and time (GMT+1)',
      fr: 'Date et heure de fin (GMT+1)'
    },
    type: 'datetime-local',
    layout: {
      col: {
        md: 6
      }
    },
    schema: {
      description: {
        nl:
          'Links naar classroom en breakoutrooms zijn niet langer zichtbaar na dit tijdstip.',
        en:
          'Links to classroom and breakout rooms are no longer visible after this time.',
        fr:
          'Les liens vers les salles de classe et les salles de réunion ne sont plus visibles après cette période.'
      }
    }
  },
  {
    name: 'classroom.meetingId',
    label: {
      nl: 'Zoom: meeting ID (zonder spatie)',
      en: 'Zoom: meeting ID (without whitespace)',
      fr: 'Zoom: meeting ID (sans espace)'
    },
    type: 'text',
    layout: {
      col: {
        md: 6
      }
    }
  },
  {
    name: 'classroom.pwd',
    label: {
      nl: 'Zoom: meeting password',
      en: 'Zoom: meeting password',
      fr: 'Zoom: meeting password'
    },
    type: 'password',
    layout: {
      col: {
        md: 6
      }
    }
  },
  {
    name: 'classroom.extraInfo',
    label: {
      nl: 'Classroom: Extra info',
      en: 'Classroom: Extra info',
      fr: 'Classroom: Extra info'
    },
    type: 'textarea',
    md: true,
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'classroom.recordings',
    label: {
      nl: 'Opname(s)',
      en: 'Recording(s)',
      fr: 'Recording(s)'
    },
    type: 'subform',
    layout: {
      col: {
        md: 12
      }
    },
    elements: [
      {
        name: 'url',
        label: {
          nl: 'URL',
          en: 'URL',
          fr: 'URL'
        },
        type: 'text',
        layout: {
          col: {
            md: 6
          }
        }
      },
      {
        name: 'titel',
        label: {
          nl: 'Titel (optioneel)',
          en: 'Title (optional)',
          fr: 'Titre (optionnel)'
        },
        type: 'text',
        layout: {
          col: {
            md: 6
          }
        }
      }
    ]
  },
  {
    name: 'classroom.breakoutrooms',
    label: {
      nl: 'Breakout rooms',
      en: 'Breakout rooms',
      fr: 'Breakout rooms'
    },
    type: 'subform',
    layout: {
      col: {
        md: 12
      }
    },
    attributes: {
      columns: ['name', 'startdate', 'jitsi']
    },
    elements: [
      {
        name: 'name',
        label: {
          nl: 'Naam (optioneel)',
          en: 'Name (optional)',
          fr: 'Nom (optionnel)'
        },
        type: 'text',
        layout: {
          col: {
            md: 6
          }
        }
      },
      {
        name: 'startdate',
        label: {
          nl: 'Startdatum en tijd (GMT+1)',
          en: 'Startdate and time (GMT+1)',
          fr: 'Date et heure de début (GMT+1)'
        },
        type: 'datetime-local',
        layout: {
          col: {
            md: 6
          }
        }
      },
      {
        name: 'jitsi',
        label: 'JAAS URL',
        required: true,
        type: 'text',
        layout: {
          col: {
            md: 12
          }
        }
      },
      {
        name: 'breakoutroomInteriorInfo',
        label: {
          nl: 'info onderaan breakoutroom',
          en: 'info shown below breakoutroom',
          fr: 'info sous breakoutroom'
        },
        type: 'textarea',
        layout: {
          col: {
            md: 12
          }
        }
      }
    ]
  },
  {
    name: 'classroom.breakoutroomCountdown',
    label: {
      nl:
        'Optioneel: duurtijd van countdown timer bovenaan pagina in minuten (start zodra eerste persoon kamer binnenkomt)',
      en:
        'Optional: duration of countdown timer displayed at top of page, in minutes (starts when first person enters room',
      fr:
        'Optional: duration of countdown timer displayed at top of page, in minutes (starts when first person enters room'
    },
    type: 'number',
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'classroom.breakoutroomInfo',
    label: {
      nl: 'Breakout rooms: Extra info',
      en: 'Breakout rooms: Extra info',
      fr: 'Breakout rooms: Extra info'
    },
    type: 'textarea',
    md: true,
    layout: {
      col: {
        md: 12
      }
    }
  }
]

export { formElements }
