import React from 'react'
import PropTypes from 'prop-types'

import './blocklist.scss'

// pass data to blocklist
const BlockList = ({ children, nested, small, ...props }) => {
  const className = ['list__block']
  if (nested) className.push('list__block__nested')
  if (small) className.push('list__block__small')
  if (props.className) className.push(props.className)

  return (
    <div {...props} className={className.join(' ')}>
      {children}
    </div>
  )
}

BlockList.propTypes = {
  children: PropTypes.node,
  rows: PropTypes.bool
}

export default BlockList
