import React, { useState, useEffect } from 'react'

import { Translate } from '@lefapps/translations'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { COURSE, COURSES_BY_ORIGINID } from './queries'
import { UPDATE_LOG } from '../mutations'
import { CURRENT_USER } from '../User/queries'

import CourseOverview from './CourseOverview'
import { Loading } from '../_shared/Loading'
import ContentLanguageSwitcher from '../_shared/ContentLanguageSwitcher'

import config from '../config'

const Course = ({
  match: {
    params: { coursePath: path }
  }
}) => {
  const [updatedLog, setUpdatedLog] = useState(false)
  useEffect(() => {
    setUpdatedLog(false)
  }, [path])

  // Queries
  const { loading, error, data } = useQuery(COURSE, {
    variables: { path }
  })
  const { loading: userLoading, error: userError, data: userData } = useQuery(
    CURRENT_USER
  )
  const sendForTranslations = (config.multilingual && path) || null
  const { loading: pathsLoading, data: translations } = useQuery(
    COURSES_BY_ORIGINID,
    { variables: { path: sendForTranslations } }
  )

  // Mutations
  const [updateLog] = useMutation(UPDATE_LOG)

  // Early exit
  if (loading || userLoading || pathsLoading) return <Loading />
  if (error || userError) {
    console.error(error)
    return (
      <Loading color={'danger'}>
        <Translate _id='error/loading_course'>
          Er ging iets mis bij het laden van deze cursus.
        </Translate>
      </Loading>
    )
  }

  const { course } = data
  const { currentUser } = userData || []
  // const { log } = logData || []
  let translationsPaths =
    (translations &&
      typeof translations === 'object' &&
      Object.entries(translations)[0]) ||
    [] // unwrapping paths
  if (translationsPaths.length) {
    translationsPaths = translationsPaths[1]
  }

  if (!course) {
    return (
      <Loading color={'warning'}>
        <Translate _id='course/no_course'>
          Er ging iets mis bij het laden van deze cursus. Oeps, deze cursus
          bestaat niet.
        </Translate>
      </Loading>
    )
  }

  // Update userlog on entering course
  if (course && !updatedLog) {
    setUpdatedLog(true)
    const doc = { tracking: { type: 'courses', path, lastSeen: new Date() } }
    const UpdateLogInput = { doc }
    updateLog({ variables: { UpdateLogInput } })
  }

  // See if user has been given a certificate
  const userHasCertificate = () => {
    let result = false
    if (currentUser && currentUser.courseCertificates) {
      currentUser.courseCertificates.map(certificate => {
        if (certificate.course === path) result = true
        return result
      })
    }
    return result
  }

  // Render
  return (
    <>
      {config.multilingual &&
      translationsPaths &&
      translationsPaths.length > 1 && ( // TODO: translate
          <ContentLanguageSwitcher
            translations={translationsPaths}
            path={path}
            type='course'
          />
        )}
      <CourseOverview
        course={course}
        certificate={userHasCertificate()}
        user={currentUser}
      />
    </>
  )
}

export default Course
