import React from 'react'
import { useAlert } from 'react-alert'

import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Translate } from '@lefapps/translations'

import { useMutation } from '@apollo/react-hooks'
import { UPDATE_RESULT } from './mutations'

import { BoardBody } from '@lefapps/admin-dashboard'
import { Form } from '../../_shared/Form/index'

const FeedbackForm = ({
  user,
  module,
  pagePath,
  page,
  result,
  setOpenedPage
}) => {
  const alert = useAlert()

  // Mutation
  const [updateResult] = useMutation(UPDATE_RESULT, {
    onCompleted: ({ updateResult: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    },
    // TODO: cache result on moduleResult/index
    update: (
      cache,
      {
        data: {
          updateResult: { result }
        }
      }
    ) => {
      // console.log('result')
    }
  })

  // Methods
  const onSubmit = async result => {
    // form sends string to __scoring, transform to int
    Object.keys(result.__scoring).forEach(function (el) {
      if (el !== 'questionType') {
        result.__scoring[el] = parseInt(result.__scoring[el])
      }
    })
    const UpdateResultInput = {
      user: user,
      module: module,
      page: pagePath,
      result: result
    }
    await updateResult({ variables: { UpdateResultInput } })
  }

  return (
    <BoardBody>
      <FontAwesomeIcon
        className='return__icon'
        onClick={() => setOpenedPage(null)}
        icon='arrow-left'
      />
      {/* For each question: show label and user's answer */}
      {/* Show score field */}
      {/* Show feedback field */}
      {page.map((item, i) => {
        if (
          item.type === 'question' &&
          item.questionType !== 'radio' &&
          item.questionType !== 'checkboxMC'
        ) {
          return (
            <div key={i}>
              <h3>
                {(item.label && item.label.default) || (
                  <Translate _id='forms/question_without_label'>
                    Vraag zonder label
                  </Translate>
                )}
              </h3>
              <p>
                {result[item.name].length ? (
                  <span>
                    <strong>
                      <Translate _id='answer'>Antwoord:</Translate>
                    </strong>
                    {result[item.name]}
                  </span>
                ) : (
                  <Translate _id='answer/no_answer'>
                    Deze gebruiker heeft geen antwoord gegeven op deze vraag
                  </Translate>
                )}
              </p>
              <Form
                elements={[
                  {
                    name: `__scoring[${item.name}]`,
                    label: 'score',
                    type: 'number'
                  },
                  {
                    name: `feedback[${item.name}]`,
                    label: 'feedback',
                    type: 'text'
                  }
                ]}
                initialModel={result}
                onSubmit={e => {
                  onSubmit(e)
                }}
              >
                <Button type='submit' color='success'>
                  <Translate _id='button/save'>Opslaan</Translate>
                </Button>
              </Form>
              <hr />
            </div>
          )
        } else return null
      })}
    </BoardBody>
  )
}

export default FeedbackForm
