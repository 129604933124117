import { gql } from 'apollo-boost'
import { pageParts } from '../../_shared/fragments'

// Querying module as admin
const ADMIN_PAGE = gql`
  query Page($path: String!) {
    page(path: $path) {
      ...PageParts
    }
  }
  ${pageParts}
`

export { ADMIN_PAGE }
