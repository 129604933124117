import React from 'react'
import omit from 'lodash/omit'
import { BoardBody, BoardHead, BoardList } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useQuery } from '@apollo/react-hooks'
import { ADMIN_FILES } from './queries'

import { FilesListItem } from './Files'
import { LinkItem } from '../_shared/LinkItem'

const LinkFiles = ({
  match: {
    params: { coursePath, sectionPath }
  }
}) => {
  const queryVars = {
    course: coursePath,
    section: sectionPath,
    filterByParent: (sectionPath && 'section') || (coursePath && 'course')
  }

  const updateClientQuery = mutationResult => (cache, result) => {
    const data = cache.readQuery({
      query: ADMIN_FILES,
      variables: queryVars
    })
    data.files = result.data[mutationResult].parent.files
    cache.writeQuery({ query: ADMIN_FILES, variables: queryVars, data })
  }

  const { loading, error, data } = useQuery(ADMIN_FILES, {
    variables: omit(queryVars, ['filterByParent'])
  })

  if (loading) {
    return <BoardBody loading />
  }
  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading'>
          Er ging iets mis bij het laden
        </Translate>
      </BoardBody>
    )
  }

  const { files, course, section } = data

  const parentType = (section && 'section') || (course && 'course')

  return (
    <>
      <BoardHead title={'Koppel Documenten'} />
      <BoardBody>
        {files.length <= 0 ? (
          <Translate _id='files/no_files'>Er zijn geen bijlagen</Translate>
        ) : (
          <BoardList>
            {files.map((file, i) => {
              file.file = file // critical fix to correctly pass the file down to FilesListItem
              file.parentPath = data[parentType].path
              file.parentType = parentType
              file.parent = data[parentType]
              file.onUpdate = updateClientQuery
              return (
                <LinkItem
                  key={i}
                  item={file}
                  itemType={'file'}
                  parent={data[parentType]}
                  parentType={parentType}
                  onUpdate={updateClientQuery}
                  ItemComponent={FilesListItem}
                />
              )
            })}
          </BoardList>
        )}
      </BoardBody>
    </>
  )
}

export default LinkFiles
