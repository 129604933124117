import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Container, Heading } from '../_layout/Containers'
import { Icon } from '../_layout/Bits'

import { View } from './View'
import { Section } from './Section/Section'
import ModuleRouter from '../Module'
import { Team } from './Team'
import CourseCertificate from './CourseCertificate'

const CourseOverviewRouter = withRouter(
  ({ course, certificate, user, match: { url, path } }) => {
    const tabs = [
      {
        to: url,
        label: (
          <>
            <Icon icon={'home'} /> Info
          </>
        ),
        component: <View {...course} certificate={certificate} url={url} />
      }
    ]

    if (certificate) {
      tabs.push({
        to: `${url}/courseCertificate`,
        label: (
          <>
            <Icon icon={'award'} /> Your Certificate
          </>
        ),
        component: <CourseCertificate {...course} />
      })
    }

    if (course.sections && !!course.sections.length) {
      course.sections.forEach(section => {
        if (section.published !== false) {
          tabs.push({
            to: `${url}/section/${section.path}`,
            label: (
              <>
                <Icon icon={'section'} /> {section.name}
              </>
            ),
            component: <Section {...section} />
          })
        }
      })
    }

    if (course.owners && !!course.owners.length) {
      tabs.push({
        to: `${url}/team`,
        label: (
          <>
            <Icon icon={'team'} /> Team
          </>
        ),
        component: <Team {...course} />
      })
    }

    return (
      <Switch>
        <Route path={`${path}/module`}>
          <ModuleRouter />
        </Route>
        <Route path={`${path}/:tabType/:tabPath/module`}>
          <ModuleRouter />
        </Route>
        <Route path={`${path}/:tabType?/:tabPath?`} exact>
          <Helmet>
            <title>{course.name}</title>
          </Helmet>
          <Heading breadcrumbs>
            <h1>
              <Icon icon={'course'} />
              {course.name}
            </h1>
          </Heading>
          {/* removed for new course overview */}
          {/* <Container force>
            <NavTab links={tabs} collapse={'tablet'} />
          </Container> */}
          <Container vertical>
            <Switch>
              {tabs.map(({ to, component }, key) => (
                <Route path={to} exact key={key}>
                  {component || null}
                </Route>
              ))}
            </Switch>
          </Container>
        </Route>
      </Switch>
    )
  }
)

export default withRouter(CourseOverviewRouter)
