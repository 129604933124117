import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAlert } from 'react-alert'
import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem,
  DashboardLink
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from 'react-sortable-hoc'

import cloneDeep from 'lodash/cloneDeep'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_MODULE } from '../queries'
import { DELETE_PAGE } from './mutations'
import { UPDATE_MODULE } from '../../Modules/mutations'

const SortableBoardListItem = SortableElement(BoardListItem)
const SortableBoardList = SortableContainer(BoardList)
const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={'grip-lines'} />)

const PageItem = ({ index, page, modulePath, history }) => {
  const alert = useAlert()

  // Mutations
  const [deletePage, { loading, error }] = useMutation(DELETE_PAGE, {
    onCompleted: ({ deletePage: { success, message } }) => {
      alert.show(message, { type: (success && 'success') || 'danger' })
    },
    update: (
      cache,
      {
        data: {
          deletePage: {
            page: { path: deletedPath }
          }
        }
      }
    ) => {
      const variables = {
        path: modulePath
      }
      const data = cloneDeep(
        cache.readQuery({ query: ADMIN_MODULE, variables })
      )
      data.module.pages = data.module.pages.filter(
        ({ path }) => path !== deletedPath
      )
      cache.writeQuery({ query: ADMIN_MODULE, variables, data })
    }
  })

  // Methods
  const remove = async () => {
    await deletePage({
      variables: { path: page.path }
    })
  }

  const duplicate = () => {
    const initialModel = cloneDeep(page)
    initialModel.name = `Duplicaat van ${page.name}`
    delete initialModel['path']
    history.push('pages/new', { initialModel })
  }

  // Components
  const actions = {
    edit: { to: `pages/${page.path}` },
    duplicate: { onClick: duplicate, type: 'duplicate' },
    remove: { onClick: remove, loading, error }
  }

  // Render
  return (
    <SortableBoardListItem
      index={index}
      label={<DragHandle />}
      actions={actions}
    >
      {page.name}
    </SortableBoardListItem>
  )
}

const Pages = ({
  match: {
    params: { modulePath }
  },
  history
}) => {
  // Mutations
  const [updateModule, { loading: updateLoading }] = useMutation(UPDATE_MODULE)

  // Queries
  const { loading, error, data } = useQuery(ADMIN_MODULE, {
    variables: { path: modulePath }
  })

  // Early exit
  if (loading || updateLoading) return <BoardBody loading />

  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='pages/error_loading'>
          Er ging iets mis bij het laden van de pagina’s.
        </Translate>
      </BoardBody>
    )
  }

  const { module = {} } = data
  const { pages = [] } = module

  // Methods
  const sortModulePages = async ({ oldIndex, newIndex }) => {
    const source = pages[oldIndex]
    pages.splice(oldIndex, 1)
    pages.splice(newIndex, 0, source)

    const doc = { pages: pages.map(page => page && page.path) }
    const UpdateModuleInput = { path: module.path, doc }

    await updateModule({
      variables: { UpdateModuleInput }
    })
  }

  // Components
  const actions = (
    <DashboardLink className='btn btn-lg' view='new' title='Create new page'>
      <FontAwesomeIcon icon='plus' />
    </DashboardLink>
  )

  const sortableProps = {
    onSortEnd: sortModulePages,
    axis: 'y',
    lockAxis: 'y',
    transitionDuration: 300,
    useDragHandle: true,
    lockToContainerEdges: true
  }

  // Render
  return (
    <>
      <BoardHead title={module.name} actions={actions}>
        <em>
          <Translate _id='pages/title'>Pagina’s</Translate>
        </em>
      </BoardHead>
      <BoardBody>
        {pages.length > 0 ? (
          <SortableBoardList {...sortableProps}>
            {pages.map((page, index) => {
              if (page) {
                return (
                  <PageItem
                    key={index}
                    index={index}
                    page={page}
                    modulePath={modulePath}
                    history={history}
                  />
                )
              } else return null
            })}
          </SortableBoardList>
        ) : (
          <Translate _id='pages/no_pages'>
            Deze module heeft nog geen pagina’s
          </Translate>
        )}
      </BoardBody>
    </>
  )
}

export default Pages
