import { gql } from 'apollo-boost'
import { moduleParts } from '../../_shared/fragments'

const ADMIN_MODULE_ACTIVE_USERS = gql`
  query ModuleActiveUsers($path: String!, $before: String, $after: String) {
    module(path: $path) {
      ...ModuleParts
      activeUsers(before: $before, after: $after) {
        _id
        name
        email
        certificates
      }
    }
  }
  ${moduleParts}
`

const ADMIN_MODULE_RESULT = gql`
  query ModuleResult($path: String!, $user: String!) {
    module(path: $path) {
      ...ModuleParts
      pages {
        path
        name
        content
        type
        result(user: $user)
      }
    }
    user(_id: $user) {
      _id
      name
      email
      profession
      certificates
    }
  }
  ${moduleParts}
`

const ADMIN_MODULE_RESULT_CSV = gql`
  query DownloadResult($path: String!, $before: String, $after: String) {
    resultCSV(path: $path, before: $before, after: $after)
  }
`

export {
  ADMIN_MODULE_ACTIVE_USERS,
  ADMIN_MODULE_RESULT,
  ADMIN_MODULE_RESULT_CSV
}
