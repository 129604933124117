import React from 'react'
import { useAlert } from 'react-alert'

import { useMutation } from '@apollo/react-hooks'
import { ADD_ITEM_TO_PARENT, REMOVE_ITEM_FROM_PARENT } from '../../mutations'
import { ADD_ITEM_TO_USER, REMOVE_ITEM_FROM_USER } from '../Users/mutations'

export const LinkItem = ({
  item,
  itemType,
  parent,
  parentType,
  onUpdate,
  ItemComponent
}) => {
  const itemId = item.path || item._id
  const alert = useAlert()

  const [addItemToParent, { loading: linkLoading }] = useMutation(
    ADD_ITEM_TO_PARENT,
    {
      onCompleted: ({ addItemToParent: { success, message } }) => {
        alert.show(message, { type: success ? 'success' : 'danger' })
      },
      update: onUpdate('addItemToParent')
    }
  )
  const [removeItemFromParent, { loading: unlinkLoading }] = useMutation(
    REMOVE_ITEM_FROM_PARENT,
    {
      onCompleted: ({ removeItemFromParent: { success, message } }) => {
        alert.show(message, { type: success ? 'success' : 'danger' })
      },
      update: onUpdate('removeItemFromParent')
    }
  )

  // New user access
  const [addItemToUser, { loading: userLinkLoading }] = useMutation(
    ADD_ITEM_TO_USER,
    {
      onCompleted: ({ addItemToUser: { success, message } }) => {
        alert.show(message, { type: success ? 'success' : 'danger' })
      },
      update: onUpdate('addItemToUser')
    }
  )
  const [removeItemFromUser, { loading: userUnlinkLoading }] = useMutation(
    REMOVE_ITEM_FROM_USER,
    {
      onCompleted: ({ removeItemFromUser: { success, message } }) => {
        alert.show(message, { type: success ? 'success' : 'danger' })
      },
      update: onUpdate('removeItemFromUser')
    }
  )

  let linked = []
  if (itemType === 'teacher') {
    if (parent.owners) {
      const owners = parent.owners.map(owner => owner['_id'])
      if (owners.includes(item._id)) linked = true
      else linked = false
    } else linked = false
  } else if (itemType === 'user') {
    if (
      item.modules.includes(parent.path) ||
      item.courses.includes(parent.path)
    ) {
      linked = true
    } else linked = false
  } else {
    linked = (parent[`${itemType}s`] || []).some(({ path, _id }) =>
      path ? itemId === path : itemId === _id
    )
  }

  let variables = {
    itemType,
    itemPath: itemId,
    parentType,
    parentPath: parent.path
  }

  const link = () => {
    if (itemType === 'teacher') {
      variables.itemType = 'owner'
      addItemToParent({ variables })
    } else if (itemType === 'user') {
      variables = {
        _id: itemId,
        itemType: parentType,
        itemPath: parent.path
      }
      addItemToUser({ variables })
    } else {
      addItemToParent({ variables })
    }
  }

  const unlink = () => {
    if (itemType === 'teacher') {
      variables.itemType = 'owner'
      removeItemFromParent({ variables })
    } else if (itemType === 'user') {
      variables = {
        _id: itemId,
        itemType: parentType,
        itemPath: parent.path
      }
      removeItemFromUser({ variables })
    } else {
      removeItemFromParent({ variables })
    }
  }

  let actions = []
  if (linked) {
    actions.unlink = {
      onClick: unlink,
      color: 'success',
      title: 'Remove link',
      loading: itemType !== 'user' ? unlinkLoading : userUnlinkLoading,
      'data-type': `${itemId}__linked`
    }
  } else {
    actions.link = {
      onClick: link,
      title: 'Link',
      loading: itemType !== 'user' ? linkLoading : userLinkLoading,
      'data-type': `${itemId}__unlinked`
    }
  }
  return <ItemComponent {...item} options={{ actions }} />
}
