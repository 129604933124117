import humanizeDuration from 'humanize-duration'

export default function GetReadableTime (time) {
  // time goes in in milliseconds, leaves in hours:minutes
  time = humanizeDuration(time * 1000, {
    language: 'nl',
    maxDecimalPoints: 0,
    units: ['h', 'm']
  }) // TODO: pass language for translation
  return time
}
