const formElements = [
  {
    name: 'certificateConfig.auto',
    label: {
      nl: 'Auto: Certificaat automatisch uitreiken',
      en: 'Auto: Automatically assign certificate',
      fr: 'Auto: Attribuer automatiquement le certificat'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl:
          'Vink dit aan indien je wenst dat deelnemers automatisch een certificaat ontvangen. Je kan hieronder de vereisten instellen.',
        en:
          'Check this if you want participants to automatically receive a certificate. You can set the requirements below.',
        fr:
          'Cochez cette option si vous souhaitez que les participants reçoivent automatiquement un certificat. Vous pouvez définir les conditions ci-dessous.'
      }
    }
  },
  {
    name: 'certificateConfig.complete',
    label: {
      nl: 'Auto: Compleet',
      en: 'Auto: Complete',
      fr: 'Auto: Achevée'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl: 'Alle vragen moeten beantwoord zijn',
        en: 'All questions must be answered',
        fr: 'Toutes les questions doivent recevoir une réponse'
      }
    }
  },
  {
    name: 'certificateConfig.correct',
    label: {
      nl: 'Auto: Correct',
      en: 'Auto: Correct',
      fr: 'Auto: Correct'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl: 'Vragen met een correcte oplossing moeten correct beantwoord zijn.',
        en: 'Questions with a correct solution must be answered correctly.',
        fr:
          'Les questions avec une solution correcte doivent recevoir une réponse correcte.'
      }
    }
  },
  {
    name: 'certificateConfig.checkFraud',
    label: {
      nl: 'Auto: Fraude voorkomen',
      en: 'Auto: Prevent fraud',
      fr: 'Auto: Prévenir la fraude'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl:
          'De tijdscodes voor alle handelingen worden nagekeken. Verdacht gedrag zorgt ervoor dat de deelnemer geen automatisch vertificaat kan krijgen.',
        en:
          'The timestamps for all activities is checked. Suspicious activity will block the automatic assignment of a certificate.',
        fr: `Les codes temporels de toutes les actions sont vérifiés. Un comportement suspect empêche le participant d'obtenir un certificat automatique.`
      }
    }
  },
  {
    name: 'lijn',
    type: 'divider',
    layout: { col: { xs: 12 } }
  },
  {
    name: 'certificateConfig.timed',
    label: {
      nl: 'Verval-termijn in jaren (optioneel)',
      en: 'Expiration-term in years (optional)',
      fr: `Délai d'expiration en années (facultatif)`
    },
    type: 'number',
    layout: { col: { sm: 6 } },
    schema: {
      description: {
        nl:
          'Indien gewenst kan u hier instellen hoe lang het certificaat geldig is na uitreiking. De gegeven termijn gaat in wanneer u manueel het certificaat toekent of wanneer de deelnemer aan de gestelde voorwaarden voldoet.',
        en:
          'If desired, you can define how long the certificate is valid after issue here. The given period starts when you manually assign the certificate or when the participant meets the set conditions.',
        fr:
          'Si vous le souhaitez, vous pouvez définir ici la durée de validité du certificat après son émission. La période donnée commence lorsque vous attribuez manuellement le certificat ou lorsque le participant remplit les conditions définies.'
      }
    }
  },
  {
    name: 'certificateConfig.content.header',
    label: 'Inhoud: Header',
    type: 'text'
  },
  {
    name: 'certificateConfig.content.intro',
    label: 'Inhoud: Intro',
    type: 'text'
  },
  {
    name: 'certificateConfig.content.body',
    label: 'Inhoud: Body',
    type: 'textarea',
    md: true
  },
  {
    name: 'certificateConfig.content.requireRiziv',
    label: 'Inhoud: Riziv nummer tonen (indien van toepassing)',
    type: 'checkbox'
  },
  {
    name: 'certificateConfig.content.courseDates',
    label: 'Inhoud: Lesdata',
    type: 'text'
  },
  {
    name: 'certificateConfig.content.teacherName',
    label: 'Inhoud: Naam Docent',
    type: 'text'
  },
  {
    name: 'certificateConfig.content.teacherInfo',
    label: 'Inhoud: Info Docent',
    type: 'text'
  },
  {
    name: 'certificateConfig.content.place',
    label: 'Inhoud: Plaats',
    type: 'text'
  },
  {
    name: 'certificateConfig.content.footer',
    label: 'Inhoud: Footer',
    type: 'text'
  }
]

export { formElements }
