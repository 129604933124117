import React, { useState } from 'react'
import { useAlert } from 'react-alert'

import { useQuery } from '@apollo/react-hooks'
import { ADMIN_MODULE_ACTIVE_USERS, ADMIN_MODULE_RESULT_CSV } from './queries'

import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { initials } from '../../../../_helpers/users'

import DownloadCSV from '../../../_shared/DownloadCSV'
import Search from '../../_shared/Search'
import assignCertificate from './helpers/assignCertificate'

const Result = ({ user, module }) => {
  const alert = useAlert()
  const { isCertified, certifyResult } = assignCertificate(
    { user, module },
    ({ message, success }) =>
      alert.show(message, { type: success ? 'success' : 'danger' })
  )
  const actions = userId => ({
    certificate: {
      onClick: !isCertified && certifyResult, //		Func	inline action method
      color: isCertified ? 'success' : 'warning', //		String	bootstrap color name
      icon: 'award', //		String	FontAwesome Icon
      loading: '', //		Bool	set to true while awaiting inline action response
      error: '',
      title: isCertified ? '' : 'Assign certificate',
      disabled: !!isCertified
    },
    view: {
      type: 'view',
      view: userId
    }
  })

  return (
    <BoardListItem label={initials(user.name)} actions={actions(user._id)}>
      <strong>{user.name}</strong>
      <br />
      <small className={'admin-board__small-body__hidden'}>{user.email}</small>
    </BoardListItem>
  )
}

const Results = ({
  match: {
    params: { modulePath }
  }
}) => {
  // Hooks
  const [getFilter, setFilter] = useState({})

  // Query
  const filter = {}
  if (getFilter.updatedAt__before) filter.before = getFilter.updatedAt__before
  if (getFilter.updatedAt__after) filter.after = getFilter.updatedAt__after
  const { loading, error, data } = useQuery(ADMIN_MODULE_ACTIVE_USERS, {
    variables: { path: modulePath, ...filter }
  })

  if (loading) return <BoardBody loading />
  if (error) {
    console.error('useQuery', 'ADMIN_MODULE_ACTIVE_USERS', error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_active_participants'>
          Er ging iets mis bij het laden van actieve deelnemers.
        </Translate>
      </BoardBody>
    )
  }

  const {
    module: { name, activeUsers }
  } = data

  const filterableFields = [
    {
      name: 'updatedAt',
      type: 'datetime-local',
      label: 'Datum resultaat'
    }
  ]

  return (
    <>
      <BoardHead
        title={name}
        actions={
          <DownloadCSV
            variables={{ path: modulePath, ...filter }}
            query={ADMIN_MODULE_RESULT_CSV}
          />
        }
      >
        <em>
          <Translate _id='results/title'>Resultaten</Translate>
        </em>
      </BoardHead>
      <BoardBody>
        <Search
          onSearch={setFilter}
          oldSearch={getFilter}
          schema={filterableFields}
        />
        {activeUsers.length <= 0 ? (
          <Translate _id='results/no_active_participants'>
            Er zijn geen actieve deelnemers.
          </Translate>
        ) : (
          <BoardList>
            {activeUsers.map((user, key) => (
              <Result user={user} module={{ path: modulePath }} key={key} />
            ))}
          </BoardList>
        )}
      </BoardBody>
    </>
  )
}

export default Results
