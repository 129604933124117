import React from 'react'
import omit from 'lodash/omit'
import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem,
  DashboardLink
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useQuery } from '@apollo/react-hooks'
import { ADMIN_MODULES } from './queries'

import { LinkItem } from '../_shared/LinkItem'

const LinkModules = ({
  match: {
    params: { coursePath, sectionPath }
  }
}) => {
  const queryVars = {
    course: coursePath,
    section: sectionPath,
    filterByParent: (sectionPath && 'section') || (coursePath && 'course')
  }

  const updateClientQuery = mutationResult => (cache, result) => {
    const data = cache.readQuery({
      query: ADMIN_MODULES,
      variables: queryVars
    })
    data.modules = result.data[mutationResult].parent.modules
    cache.writeQuery({ query: ADMIN_MODULES, variables: queryVars, data })
  }

  const { loading, error, data } = useQuery(ADMIN_MODULES, {
    variables: omit(queryVars, ['filterByParent'])
  })

  if (loading) {
    return <BoardBody loading />
  }
  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading'>
          Er ging iets mis bij het laden
        </Translate>
      </BoardBody>
    )
  }

  const { modules, course, section } = data

  const parentType = (section && 'section') || (course && 'course')

  const Module = ({ options = {}, index, ...module }) => (
    <BoardListItem label={index} actions={options.actions}>
      <DashboardLink view={module.path}>{module.name}</DashboardLink>
    </BoardListItem>
  )

  return (
    <>
      <BoardHead title={data[parentType].name}>
        <Translate _id='linkModules/modules/title'>
          Bestaande modules linken
        </Translate>
      </BoardHead>
      <BoardBody>
        {modules.length <= 0 ? (
          <Translate _id='linkModules/modules/no_modules'>
            'Er zijn geen modules'
          </Translate>
        ) : (
          <BoardList>
            {modules.map((module, i) => {
              return (
                <LinkItem
                  key={i}
                  item={module}
                  itemType={'module'}
                  parent={data[parentType]}
                  parentType={parentType}
                  onUpdate={updateClientQuery}
                  ItemComponent={Module}
                />
              )
            })}
          </BoardList>
        )}
      </BoardBody>
    </>
  )
}

export default LinkModules
