import React from 'react'
import { Translate, useTranslator } from '@lefapps/translations'

import { useQuery } from '@apollo/react-hooks'
import { CURRENT_USER } from './User/queries'
import { LATEST_ACTIVITY } from './queries'

import config from './config'

import { render } from '../_helpers/markdown'

import { Loading } from './_shared/Loading'
import { Image } from './_shared/Image'

import { Container, Heading } from './_layout/Containers'
import { Aside, Grid, Main } from './_layout/Grid'

import Courses from './Course/Courses'
import Modules from './Module/Modules'

const { copy = {}, name = '', storage = {}, images = {} } = config

const Home = () => {
  // Guards frontpage

  // Queries
  const { language } = useTranslator()
  const { loading, error, data } = useQuery(CURRENT_USER)
  const {
    loading: activityLoading,
    error: activityError,
    data: activityData
  } = useQuery(LATEST_ACTIVITY)

  // Early exit
  if (loading || activityLoading) return <Loading />
  if (error || activityError) {
    console.error(error)
    return (
      <Loading color={'danger'}>
        <Translate _id='error/loading_home'>
          Er ging iets mis bij het laden van je profiel.
        </Translate>
      </Loading>
    )
  }

  const currentUser = data.currentUser
  const latestActivity = activityData.latestActivity

  const { client } = storage
  const [filename, ext, alt] = images.intro || []

  // Render
  return (
    <>
      <Heading>
        <h1>{name}</h1>
      </Heading>
      <Grid collapse={'lg'}>
        <Main>
          <Container vertical order={2}>
            <Courses onHome />
          </Container>

          <Container vertical order={2}>
            <Modules noParents onHome />
          </Container>
        </Main>

        {/* TODO: show relevant content for currentUser */}
        {currentUser && (
          <Aside>
            {/* Iff active lesson: jump back in */}
            {!!latestActivity && (
              <Container vertical order={1}>
                <h4 data-cy='authenticated'>
                  <Translate _id='home/aside/recent'>
                    Jouw meest recente activiteit:
                  </Translate>
                </h4>
                <Modules singleModule={[latestActivity]} />
              </Container>
            )}
            <Container vertical order={0}>
              {/* Else this welcome text */}
              <h4 data-cy='authenticated'>
                <Translate _id='home/title'>Welkom!</Translate>
              </h4>
              {render(copy.intro[language] || copy.intro || '')}
              <br />
              {filename && (
                <Image
                  name={`${filename}.${ext}`}
                  alt={alt}
                  context='images'
                  host={client}
                />
              )}
            </Container>
          </Aside>
        )}
      </Grid>
    </>
  )
}

export default Home
