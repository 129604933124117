import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import Modules from './Modules'
import Module from './Module'

const ModuleRouter = ({ match: { path } }) => {
  return (
    <Switch>
      <Route path={`${path}/:modulePath`} component={Module} />
      <Route path={path} component={Modules} />
    </Switch>
  )
}

export default withRouter(ModuleRouter)
