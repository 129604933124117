import { useMutation } from '@apollo/react-hooks'

import { CERTIFY } from '../mutations'

export default ({ user, module }, callback) => {
  const [certify] = useMutation(CERTIFY, {
    onCompleted: ({ certify: { success, message } }) =>
      callback && callback({ success, message })
  })
  if (!user || !module)
    return {
      isCertified: false,
      certifyResult: () => false
    }
  const isCertified =
    user.certificates && user.certificates.includes(module.path)
  const certifyResult = () => {
    if (
      !window.confirm(
        `Are you certain you want to assign a certificate to ${user.name}?`
      )
    )
      return false
    certify({
      variables: { userId: user._id, modulePath: module.path }
    })
  }
  return {
    isCertified,
    certifyResult
  }
}
