import React, { Fragment, useEffect, useState } from 'react'
import { Switch, withRouter, Route, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Translate } from '@lefapps/translations'

import { useQuery } from '@apollo/react-hooks'
import { MODULE, MODULES_BY_ORIGINID } from './queries'
import { CURRENT_USER } from '../User/queries'

import { Container, Heading } from '../_layout/Containers'
import { Grid, Main } from '../_layout/Grid'
import { BlockList, BlockListItem } from '../_layout/BlockList'
import { PrevNext, PrevNextLink, Icon } from '../_layout/Bits'
import { Loading } from '../_shared/Loading'
import ModuleResult from '../_shared/ModuleResult/index'
import checkCompletionStatus from '../_shared/CompletionStatus'
import GetReadableTime from '../../_helpers/getReadableTime'
import getPageIcon from '../../_helpers/getPageIcon'
import { render } from '../../_helpers/markdown'
import ContentLanguageSwitcher from '../_shared/ContentLanguageSwitcher'

import config from '../config'

import Certificate from './Certificate'
import Page from './Page'

export default withRouter(
  ({
    match: {
      url,
      params: { coursePath, modulePath }
    },
    history
  }) => {
    // Queries: module
    const { loading, error, data } = useQuery(MODULE, {
      variables: { path: modulePath }
    })

    // Queries: role
    const {
      loading: roleLoading,
      error: roleError,
      data: currentUser
    } = useQuery(CURRENT_USER)

    // Queries: translated content
    const sendForTranslations = (config.multilingual && modulePath) || null
    const { loading: pathsLoading, data: translations } = useQuery(
      MODULES_BY_ORIGINID,
      { variables: { path: sendForTranslations } }
    )

    if (loading || roleLoading || pathsLoading) return <Loading />
    if (error || roleError) {
      console.error(error)
      return (
        <Loading color={'danger'}>
          <Translate _id='error/loading_module'>
            Er ging iets mis bij het laden van deze module.
          </Translate>
        </Loading>
      )
    }

    const { module } = data

    if (!module) {
      return (
        <Loading color={'warning'}>
          <Translate _id='module/no_module'>
            Oeps, deze module bestaat niet.
          </Translate>
        </Loading>
      )
    }

    const isAdmin = currentUser.currentUser.roles.includes('admin')
    const isTeacher = currentUser.currentUser.roles.includes('teacher')

    let translationsPaths =
      (translations &&
        typeof translations === 'object' &&
        Object.entries(translations)[0]) ||
      [] // unwrapping paths
    if (translationsPaths.length) {
      translationsPaths = translationsPaths[1]
    }

    // Render
    return (
      <>
        {config.multilingual &&
          translationsPaths &&
          translationsPaths.length > 1 && ( // TODO: translate
            <ContentLanguageSwitcher
              translations={translationsPaths}
              path={modulePath}
              type='module'
            />
          )}
        <ModuleContent
          module={module}
          url={url}
          coursePath={coursePath || null}
          modulePath={modulePath}
          isAdmin={isAdmin}
          isTeacher={isTeacher}
          history={history}
        />
      </>
    )
  }
)

const ModuleContent = ({
  module,
  url,
  modulePath,
  coursePath,
  isAdmin,
  isTeacher,
  history
}) => {
  const [pageList, setPageList] = useState([])
  let [moduleEstimatedTime, setModuleEstimatedTime] = useState(0)
  const getPageList = () => {
    // Set pagedata
    const pages = module.pages.map(({ completed, ...page }, index) => {
      const { path, name, type, content, estimatedTime, result } = page
      if (estimatedTime && parseInt(estimatedTime)) {
        setModuleEstimatedTime((moduleEstimatedTime += parseInt(estimatedTime)))
      }
      if (completed) completed = checkCompletionStatus(content, result)
      return {
        ...page,
        completed,
        estimatedTime: Math.round(estimatedTime / 60),
        to: module.gateProgress && !completed ? url : url + '/page/' + path,
        label: <Icon icon={type || 'markdown'} single tag='i' />,
        title: module.anonymousPages // Don't show page title when pages are anonymous
          ? index + 1
          : `${index + 1}. ${name}`,
        className: completed ? 'disabled' : ''
      }
    })
    pages.push({
      to: url + '/result',
      label: <Icon icon='result' single tag='i' />,
      title: 'Resultaat'
    })
    return pages
  }

  useEffect(() => {
    setPageList(getPageList())
    // eslint-disable-next-line
  }, [modulePath, module])

  return (
    <Switch>
      <Route path={url + '/page/:pagePath'} exact component={Page} />
      <Route
        path={url + '/result'}
        exact
        component={ModuleResults({
          module,
          isAdmin,
          isTeacher,
          history,
          url,
          coursePath
        })}
      />
      <Route
        path={url}
        exact
        component={ModuleInfo({ module, pageList, moduleEstimatedTime })}
      />
    </Switch>
  )
}

const ModuleInfo =
  ({ module, pageList, moduleEstimatedTime }) =>
  () =>
    (
      <Fragment>
        <Helmet>
          <title>{module.name}</title>
        </Helmet>
        <Heading>
          <h1 className='titleOverride float-center'>{module.name}</h1>
          <span className='pageInfo'>
            {GetReadableTime(moduleEstimatedTime)}
          </span>
        </Heading>
        <Grid>
          <Main>
            <Container vertical width='tablet' _id='ModuleInfo'>
              {render(module.info || '')}
            </Container>
            <Container vertical width='tablet'>
              {!pageList.length ? (
                <Loading />
              ) : (
                <BlockList>
                  <BlockListItem
                    color={'light'}
                    header={
                      <Fragment>
                        <h3>
                          <Translate _id='module/content_title'>
                            Inhoud
                          </Translate>
                        </h3>
                        {pageList.length > 1 && (
                          <small>
                            {pageList.length}{' '}
                            <Translate _id='module/pages_title'>
                              pagina’s
                            </Translate>
                          </small>
                        )}
                      </Fragment>
                    }
                  >
                    <Table>
                      <tbody>
                        {pageList.map(
                          (
                            { path, completed, to, title, estimatedTime, type },
                            index
                          ) => {
                            return (
                              <tr key={path || index}>
                                <td>
                                  {index !== pageList.length - 1 ? (
                                    <FontAwesomeIcon
                                      className='pageInfo__type'
                                      icon={getPageIcon(type, title)}
                                    />
                                  ) : null}
                                  <strong>
                                    <Link to={to}>{title}</Link>
                                  </strong>
                                </td>
                                <td className='row pageInfo'>
                                  <span>
                                    {estimatedTime >= 0
                                      ? estimatedTime === 1
                                        ? '1 minute '
                                        : estimatedTime < 1
                                        ? '1 minute '
                                        : `${estimatedTime} minutes `
                                      : ''}
                                    {completed && (
                                      <FontAwesomeIcon
                                        className='pageInfo__check'
                                        icon='check'
                                      />
                                    )}
                                  </span>
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </Table>
                  </BlockListItem>
                </BlockList>
              )}
            </Container>
          </Main>
        </Grid>
        <PrevNext>
          {pageList.length && (
            <PrevNextLink action={pageList[0].to}>Start</PrevNextLink>
          )}
        </PrevNext>
      </Fragment>
    )

const ModuleResults = ({
  module,
  isAdmin,
  isTeacher,
  history,
  url,
  coursePath
}) => {
  const { data } = useQuery(CURRENT_USER)
  const { currentUser } = data

  return () => (
    <Fragment>
      <Helmet>
        <title>{module.name}</title>
      </Helmet>
      <Heading>
        <h1>{module.name}</h1>
      </Heading>
      <Grid>
        <Main>
          <Container vertical width='desktop'>
            <h2>
              <Translate _id='module/your_result'>Jouw resultaat</Translate>
            </h2>

            <ModuleResult
              user={currentUser}
              module={module}
              // Wrapper={Container}
              isAdmin={isAdmin}
              isTeacher={isTeacher}
              history={history}
              suspended
              coursePath={coursePath}
            />
          </Container>

          {!!module.certificate && (
            <Fragment>
              <hr />
              <Container vertical width='phablet'>
                <Certificate module={module} />
              </Container>
            </Fragment>
          )}
        </Main>
      </Grid>
      <PrevNext>
        {/* If module has parent, show navigation option to return to course overview */}
        {coursePath ? <PrevNextLink action={url.split('/module')[0]} /> : null}
        {/* <PrevNextLink action={url} prev /> */}
      </PrevNext>
    </Fragment>
  )
}
