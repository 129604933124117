import React from 'react'
import { Link } from 'react-router-dom'

import { Translate } from '@lefapps/translations'

import { readableDate } from '../../_helpers/dates'

import { Image } from '../_shared/Image'

import { Container } from '../_layout/Containers'
import { InfoBox } from '../_layout/InfoBox'
import { UserList } from '../_layout/UserList'

export const SideBar = ({
  teacher,
  color,
  startdate,
  enddate,
  date,
  location,
  users,
  image,
  info,
  showUserList,
  showTeam,
  owners,
  certificate,
  url
}) => {
  // Transforming list of users for privacy concerns
  // TODO: translations for 'Anonymous User'
  users =
    users &&
    users.map(user => {
      if (user.fullPrivacy) {
        user.name = 'Anonymous User'
        user.email = 'n.v.t.'
      }
      return user
    })
  return (
    <>
      {image && (
        <Container vertical order={0}>
          <Image {...image} context={'aside'} />
        </Container>
      )}
      {certificate && (
        <Container order={2}>
          <InfoBox
            color={color}
            title={
              <Translate _id='sidebar/certificate_title'>Certificate</Translate>
            }
            icon={'award'}
          >
            <Link to={`${url}/courseCertificate`}>
              <Translate _id='sidebar/certificate'>Uw Certificaat</Translate>
            </Link>
          </InfoBox>
          <br />
        </Container>
      )}
      {info && (
        <Container order={2}>
          <InfoBox color={color} content={info} title={'Info'} icon={'info'} />{' '}
        </Container>
      )}
      {teacher && (
        <Container order={2}>
          <InfoBox
            color={color}
            content={teacher}
            title={'Contact'}
            icon={'user-graduate'}
          />
        </Container>
      )}
      {(startdate && enddate && (
        <Container order={2}>
          <InfoBox color={color} title={'Period'} icon={'calendar-alt'}>
            <dl>
              <dt>From</dt>
              <dd>{readableDate(startdate)}</dd>
              <dt>To</dt>
              <dd>{readableDate(enddate)}</dd>
            </dl>
          </InfoBox>
        </Container>
      )) ||
        (date && (
          <Container order={2}>
            <InfoBox
              color={color}
              title={'Dates'}
              icon={'calendar-alt'}
              content={date}
            />
          </Container>
        ))}
      {location && (
        <Container order={2}>
          <InfoBox
            color={color}
            content={location}
            title={'Location'}
            icon={'location-arrow'}
          />
        </Container>
      )}
      {owners && !!owners.length && showTeam && (
        <Container order={2}>
          <InfoBox color={color} title={'Team'} icon={'user-friends'}>
            <Link to={`${url}/team`}>
              <Translate _id='sidebar/about_team'>Over het team</Translate>
            </Link>
          </InfoBox>
        </Container>
      )}
      {showUserList && users && !!users.length && (
        <Container order={2}>
          <InfoBox
            color={color}
            title={`Deelnemers (${users.length})`}
            icon={'users'}
          >
            <UserList
              users={users}
              modal={`Deelnemers`}
              fields={['email', 'name']}
            />
          </InfoBox>
        </Container>
      )}
    </>
  )
}
