import React, { useState } from 'react'
import { Button, Alert, Collapse } from 'reactstrap'
import has from 'lodash/has'
import forEach from 'lodash/forEach'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Form from '../../_shared/Form'

const debugThis = true

const elementType = type => {
  const newTypes = {
    radio: 'checkbox-mc'
  }
  return newTypes[type] || type
}

const valueType = (query = {}, schema = []) => {
  const adjustments = {
    number: Number
  }
  forEach(adjustments, (conversion, type) => {
    schema
      .filter(s => s.type === type)
      .forEach(({ name }) => {
        if (has(query, name)) {
          query[name] = conversion(query[name])
        }
      })
  })
  return query
}

const convertToSearch = (searchable = []) =>
  searchable.reduce(
    (elements, { name, label, type, attributes, schema, ...options }) => {
      // skip types where search is currently n/a
      if (['infobox', 'divider', 'textarea'].includes(type)) return elements

      switch (type) {
        case 'text':
        case 'email':
          return [
            ...elements,
            {
              name: `${name}.$regex`,
              label,
              type,
              ...options,
              dependent: {
                on: 'searchSelector',
                operator: 'in',
                values: name
              }
            }
          ]

        case 'number':
          return [
            ...elements,
            {
              name: `${name}`,
              label,
              type,
              ...options,
              dependent: {
                on: 'searchSelector',
                operator: 'in',
                values: name
              }
            }
          ]

        case 'radio':
          return [
            ...elements,
            {
              name,
              label,
              type: 'checkbox-mc',
              ...options,
              dependent: {
                on: 'searchSelector',
                operator: 'in',
                values: name
              }
            }
          ]

        case 'checkbox-mc':
          return [
            ...elements,
            {
              name: `${name}.$in`,
              label,
              type,
              ...options,
              dependent: {
                on: 'searchSelector',
                operator: 'in',
                values: name
              }
            }
          ]

        case 'datetime-local':
          return [
            ...elements,
            {
              name: `${name}__after`,
              label: `${label}, vanaf`,
              type,
              ...options,
              layout: {
                col: { sx: 12, sm: 6 }
              },
              dependent: {
                on: 'searchSelector',
                operator: 'in',
                values: name
              }
            },
            {
              name: `${name}__before`,
              label: `${label}, tot`,
              type,
              ...options,
              layout: {
                col: { sx: 12, sm: 6 }
              },
              dependent: {
                on: 'searchSelector',
                operator: 'in',
                values: name
              }
            }
          ]

        default:
          return [
            ...elements,
            {
              name,
              label,
              type: elementType(type),
              ...options,
              dependent: {
                on: 'searchSelector',
                operator: 'in',
                values: name
              }
            }
          ]
      }
    },
    [
      {
        name: 'searchSelector',
        type: 'checkbox-mc',
        options: searchable.map(({ name, label }) => ({
          _id: name,
          default: label + ' ⌄'
        })),
        attributes: { inline: true }
      }
    ]
  )

export default ({ onSearch, oldSearch = {}, schema = [] }) => {
  // Hooks
  const [isOpen, setOpen] = useState()
  const toggle = () => setOpen(!isOpen)

  // define schema
  if (schema.length === 0) return null
  const elements = convertToSearch(schema)

  // initiate
  const initialModel = { ...oldSearch }
  if (Object.keys(oldSearch).length) {
    initialModel.searchSelector = Object.keys(oldSearch)
    if (!isOpen) setOpen(true)
  }

  // method
  const setSearch = ({ searchSelector, ...search }) => {
    const searcher = valueType(search, schema)
    if (debugThis) console.log(searcher)
    onSearch(searcher)
  }

  // render
  return (
    <Alert color={'info'} className='search'>
      <h4 onClick={toggle}>
        Zoeken
        <FontAwesomeIcon
          icon={isOpen ? 'caret-up' : 'caret-down'}
          className={'float-right'}
        />
      </h4>
      <Collapse isOpen={isOpen}>
        <Form
          elements={elements}
          onSubmit={setSearch}
          initialModel={initialModel}
        >
          <Button color={'success'} type={'submit'}>
            <FontAwesomeIcon icon={'search'} /> Zoek
          </Button>{' '}
          <Button color={'info'} type={'reset'} onClick={() => setSearch({})}>
            <FontAwesomeIcon icon={'undo-alt'} /> Toon alles
          </Button>
        </Form>
      </Collapse>
    </Alert>
  )
}
