import React from 'react'
import { Translate } from '@lefapps/translations'

export const ConfirmLink = ({ onStateChange, authState }) => {
  const hideLink = ['signedIn', 'confirmSignUp'].includes(authState)
  if (hideLink) return null
  return (
    <span
      className={'Section__sectionFooterSecondaryContent___Nj41Q-like'}
      onClick={() => onStateChange('confirmSignUp')}
    >
      <Translate _id='login/verify_button'>
        → Verify account with my code
      </Translate>
    </span>
  )
}
