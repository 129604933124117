import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap'

import { Container } from '../Containers'

import './navbar.scss'

const NavBar = ({ children, title, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Navbar expand={'phone'} id={'nav'}>
      <Container id={'nav-body'} width={'full'}>
        {title ? (
          <NavLink className='navbar-brand' to='/'>
            {title}
          </NavLink>
        ) : null}
        {children && (
          <NavbarToggler
            className={isOpen ? 'navbar-toggler__open' : ''}
            onClick={toggle}
          />
        )}
        <Collapse isOpen={isOpen} navbar>
          <Nav>{children}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar
