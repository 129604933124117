import { gql } from 'apollo-boost'
import { moduleParts } from '../_shared/fragments'

const DELETE_MODULE = gql`
  mutation DeleteModule($path: String!) {
    deleteModule(path: $path) {
      success
      message
      module {
        path
      }
    }
  }
`

const CREATE_MODULE = gql`
  mutation CreateModule($ModuleInput: ModuleInput!) {
    createModule(module: $ModuleInput) {
      success
      message
      module {
        ...ModuleParts
      }
    }
  }
  ${moduleParts}
`

const UPDATE_MODULE = gql`
  mutation UpdateModule($UpdateModuleInput: UpdateModuleInput) {
    updateModule(module: $UpdateModuleInput) {
      success
      message
      module {
        ...ModuleParts
      }
    }
  }
  ${moduleParts}
`

export { CREATE_MODULE, DELETE_MODULE, UPDATE_MODULE }
