import React from 'react'
import PropTypes from 'prop-types'

import './simplelist.scss'

const labels = 'abcdefghijklmnopqrstuvwxyz'

const SimpleList = ({ children, ordered }) => {
  const Tag = ordered ? 'ol' : 'ul'
  const label = index => {
    const order = index
    const result = []
    let pass = 0
    while (index >= 0) {
      const position = pass ? pass - 1 : index % labels.length
      result.push(labels.slice(position, position + 1))
      index -= pass ? labels.length : position + 1
      pass++
    }
    let rm = Math.floor(order / labels.length)
    while (rm > 1) result.splice(--rm, 1)
    return result.reverse().join('')
  }
  return (
    <Tag className={'list__simple'}>
      {React.Children.map(
        children,
        (element, index) =>
          element &&
          React.cloneElement(element, {
            label: element.props.label || (ordered ? index + 1 : label(index))
          })
      )}
    </Tag>
  )
}

SimpleList.propTypes = {
  children: PropTypes.node,
  ordered: PropTypes.bool
}

export default SimpleList
