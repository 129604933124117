import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { ADMIN_PAGE } from './queries'

import { BoardBody, BoardHead } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import PageForm from './PageForm'

const Page = ({
  match: {
    params: { modulePath, pagePath }
  },
  location,
  history
}) => {
  let _page

  // Queries
  const { loading, error, data } = useQuery(ADMIN_PAGE, {
    variables: { path: pagePath },
    skip: !pagePath
  })

  // Loading data
  if (loading) {
    return <BoardBody loading />
  }
  if (error && pagePath) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='page/error_loading'>
          Er ging iets mis bij het laden van deze pagina.
        </Translate>
      </BoardBody>
    )
  }

  // edit || duplicate || create
  _page =
    (data && data.page) ||
    (location && location.state && location.state.initialModel) ||
    {}

  return (
    <>
      <BoardHead title={_page && _page.name ? _page.name : 'Nieuwe pagina'}>
        <em>
          <Translate _id='page/title'>Pagina</Translate>
        </em>
      </BoardHead>
      <PageForm page={_page} modulePath={modulePath} history={history} />
    </>
  )
}

export default Page
