import React from 'react'
import { Helmet } from 'react-helmet'
import { Button } from 'reactstrap'
import { useAlert } from 'react-alert'
import pick from 'lodash/pick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Translate } from '@lefapps/translations'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { CURRENT_USER } from './queries'
import { UPDATE_USER } from './mutations'

import { Matomo } from '../../_helpers/tracking'
import { Container, Heading } from '../_layout/Containers'
import { InfoBox } from '../_layout/InfoBox'
import { Grid, Main, Aside } from '../_layout/Grid'
import Form from '../_shared/Form'
import { Loading } from '../_shared/Loading'
import userFormElements from './formElements'

const Profile = () => {
  const alert = useAlert()

  // Queries
  const { loading, error, data } = useQuery(CURRENT_USER)

  // Mutations
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: ({ updateUser: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
  })

  // Early exit
  if (loading) return <Loading />
  if (error) {
    console.error('Error loading user')
    return (
      <Loading color={'danger'}>
        <Translate _id='error/loading_profile'>
          Er ging iets mis bij het laden van je profiel.
        </Translate>
      </Loading>
    )
  }

  const user = data.currentUser

  // Methods
  const handleSubmit = doc => {
    if (!doc.image) doc.image = null // catch unset of image
    updateUser({ variables: { UpdateUserInput: { _id: user._id, doc } } })
  }

  // Render
  return (
    <>
      <Helmet>
        <title>{user.name}</title>
      </Helmet>
      <Heading>
        <h1>
          <FontAwesomeIcon icon='user' /> {user.name}
        </h1>
      </Heading>
      <Grid collapse={'tablet'}>
        <Main>
          {user.courseCertificates && user.courseCertificates.length ? (
            <Container>
              <h2>
                <Translate _id='profile/certificates/title'>
                  Your certificates
                </Translate>
              </h2>
              <ul>
                {user.courseCertificates.map(certif => {
                  return (
                    <li>
                      <a
                        href={certif.path}
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        {certif.path}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </Container>
          ) : null}
          <Container vertical order={3}>
            <h3>
              <Translate _id='profile/title'>Profiel aanpassen</Translate>
            </h3>
            <Form
              elements={userFormElements}
              initialModel={pick(user, [
                'name',
                'email',
                'phone',
                'profession',
                'riziv',
                'description',
                'image.alt',
                'image.name'
              ])}
              onSubmit={handleSubmit}
            >
              <Button type='submit' color='success'>
                <Translate _id='button/save'>Opslaan</Translate>
              </Button>
            </Form>
            <hr />
            <Matomo force />
          </Container>
        </Main>

        <Aside>
          <Container vertical order={2}>
            <InfoBox title='Help'>
              <Translate _id='profile/info'>
                Gebruik deze pagina om uw profiel aan te passen.
              </Translate>
            </InfoBox>
          </Container>
        </Aside>
      </Grid>
    </>
  )
}

export default Profile
