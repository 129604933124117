import React from 'react'
import PropTypes from 'prop-types'

const SimpleListItem = ({ children, label }) => {
  return (
    <li className={'list__simple-item'} data-label={label}>
      {children}
    </li>
  )
}

const SimpleListItemFooter = ({ children }) => (
  <div className={'list__simple-item__footer'}>{children}</div>
)

SimpleListItem.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SimpleListItemFooter.propTypes = {
  children: PropTypes.node
}

export default SimpleListItem
export { SimpleListItemFooter }
