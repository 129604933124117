import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import moment from 'moment'
import Countdown from 'react-countdown'

import { Translate } from '@lefapps/translations'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { SECTION } from './queries'
import { UPDATE_LOG } from '../../mutations'

import { Container } from '../../_layout/Containers'
import { Grid, Main } from '../../_layout/Grid'
import { Icon } from '../../_layout/Bits'
import { InfoBox } from '../../_layout/InfoBox'
import { Loading } from '../../_shared/Loading'

import JitsiBox from './JitsiBox'

const Breakoutroom = ({
  match: {
    params: { coursePath, sectionPath, roomNumber }
  }
}) => {
  // Updating log
  const [updatedLog, setUpdatedLog] = useState(false)
  useEffect(() => {
    setUpdatedLog(false)
  }, [roomNumber])

  // Queries
  const { loading, error, data } = useQuery(SECTION, {
    variables: { path: sectionPath }
  })
  // Mutations
  const [updateLog] = useMutation(UPDATE_LOG)

  // Early exit
  if (loading) return <Loading />
  if (error) {
    return (
      <Loading color={'danger'}>
        <Translate _id='error/loading_classroom'>
          Er ging iets mis bij het laden van deze classroom.
        </Translate>
      </Loading>
    )
  }

  const { section } = data
  const classroom = section.classroom

  const { breakoutrooms } = classroom
  let title
  let interiorInfo
  let jitsi
  breakoutrooms.map((room, i) => {
    if (room.roomNumber === roomNumber) {
      title = room.name || i + 1
      interiorInfo = room.breakoutroomInteriorInfo || null
      jitsi = room.jitsi
    }
    return title + interiorInfo
  })
  const config = {
    jitsi,
    name: title,
    roomNumber: roomNumber
  }

  // Log part 2 now that all data is present
  if (config.roomNumber && !updatedLog) {
    setUpdatedLog(true)
    const doc = {
      tracking: {
        type: 'breakoutrooms',
        path: section.path + '/' + roomNumber,
        lastSeen: new Date()
      }
    }
    const UpdateLogInput = { doc }
    updateLog({ variables: { UpdateLogInput } })
  }

  const displayCountdown = ({
    breakoutroomCountdown,
    breakoutroomCountdownStarted
  }) => {
    if (!breakoutroomCountdownStarted) return null
    const endtime = moment(breakoutroomCountdownStarted).add(
      parseInt(breakoutroomCountdown),
      'm'
    )
    return <Countdown className='right display-right' date={endtime} />
  }

  return (
    <Grid collapse={'tablet'}>
      <Main>
        <Container order={0} tag={'h2'} hide>
          <Link to={`/course/${coursePath}/section/${sectionPath}`}>
            <Icon icon='arrow-left' />
          </Link>
          <br />
          <Icon icon={'chalkboard-teacher'} />
          {title} - Breakoutroom{' '}
          {/* Check and display countdown timer as needed */}
          {section.classroom.breakoutroomCountdown &&
            displayCountdown(section.classroom)}
        </Container>
        <JitsiBox config={config} />
        <Container>
          {interiorInfo ? <InfoBox content={interiorInfo} /> : null}
        </Container>
      </Main>
    </Grid>
  )
}

export default Breakoutroom
