import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavItem } from 'reactstrap'
import { Translate } from '@lefapps/translations'

import { useQuery } from '@apollo/react-hooks'
import { CURRENT_USER } from '../User/queries'

import config from '../config'

import { NavBar } from '../_layout/NavBar'

import UserMenu from './UserMenu'
import { Image } from './Image'
import { StaticPageMenu } from './StaticPageMenu'

const {
  storage = {},
  images = {},
  name: appName = 'E-learning met LEF',
  staticPages
} = config

export const getBranding = () => {
  const { client } = storage
  const [name, ext, alt] = images.header || []
  if (name && ext) {
    return (
      <Image
        name={`${name}.${ext}`}
        alt={alt}
        context='images'
        host={client}
        width={197}
      />
    )
  } else return appName
}

const Navigation = () => {
  const { loading, error, data } = useQuery(CURRENT_USER)
  if (error) console.error(error)

  // Early exit
  if (loading)
    return (
      <NavBar title={getBranding()}>
        <NavItem>
          <NavLink tag={Link} to={'/'}>
            <FontAwesomeIcon icon={'home'} />
          </NavLink>
        </NavItem>
      </NavBar>
    )

  const { currentUser } = data || {}

  if (!currentUser) {
    return (
      <NavBar title={getBranding()}>
        <NavItem>
          <NavLink tag={Link} to={'/gdpr'}>
            <Translate _id='privacy'>Privacy</Translate>
          </NavLink>
        </NavItem>
      </NavBar>
    )
  }

  // Components
  const isAdmin = currentUser.roles.includes('admin')
  const isTeacher = currentUser.roles.includes('teacher')

  // Render
  return (
    <NavBar title={getBranding()}>
      <NavItem>
        <NavLink tag={Link} to={'/'}>
          <FontAwesomeIcon icon={'home'} />
        </NavLink>
      </NavItem>
      {!!staticPages ? <StaticPageMenu pages={staticPages} /> : null}
      {(isAdmin || isTeacher || !!currentUser.roles.length) && (
        <NavItem>
          <NavLink to='/admin' data-cy='link_admin_dashboard'>
            <FontAwesomeIcon icon='cogs' />
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <UserMenu />
      </NavItem>
    </NavBar>
  )
}

export default Navigation
