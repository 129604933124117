import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { useAlert } from 'react-alert'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_MODULE, CREATE_MODULE } from './mutations'
import { CREATE_PAGE } from './Page/mutations'
import { ADMIN_MODULE, ADMIN_MODULES } from './queries'
import { ADMIN_COURSE } from '../Courses/queries'
import { ADMIN_SECTION } from '../Sections/queries'
import { CURRENT_USER_ADMIN } from '../Users/queries'

import { BoardBody, BoardHead, DashboardLink } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import config from '../../config'

import guard from '../_shared/guard'
import { translateModulePages } from '../_shared/TranslateContents'

import { formElements as elements } from './FormElements'

import Form from '../../_shared/Form/index'

const Module = ({
  match: {
    params: { modulePath, sectionPath, coursePath }
  },
  location,
  history
}) => {
  const alert = useAlert()

  let _module

  // Queries
  const { loading, error, data } = useQuery(ADMIN_MODULE, {
    variables: { path: modulePath },
    skip: !modulePath
  })

  const { loading: userLoading, data: currentUserData } = useQuery(
    CURRENT_USER_ADMIN
  )

  // Mutations
  const [createPage] = useMutation(CREATE_PAGE, {
    onCompleted: ({ createPage: { success, message } }) => {
      // alert.show(message, { type: success ? 'page success' : 'page danger' })
    }
  })

  const [updateModule] = useMutation(UPDATE_MODULE, {
    onCompleted: ({ updateModule: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
  })

  const [createModule] = useMutation(CREATE_MODULE, {
    onCompleted: ({ createModule: { success, message, module } }) => {
      // Catching translations! Check if the original module has pages, clone these for the new module with originId and new path
      if (_module && _module.originId && _module.pages) {
        translateModulePages({
          newModule: module,
          originalModule: _module,
          createPage,
          alert
        })
        // Set-up complete. Reloading to avoid originId muddling and to add pages to cache without hacky workarounds.
        history.push(
          `/admin${coursePath ? `/courses/${coursePath}` : ''}${
            sectionPath ? `/sections/${sectionPath}` : ''
          }/modules/${module.path}`,
          { initialModel: null }
        )
        window.location.reload()
      } else {
        alert.show(message, { type: success ? 'success' : 'danger' })
        // Reload form after creating new module to show edit form
        history.push(
          `/admin${coursePath ? `/courses/${coursePath}` : ''}${
            sectionPath ? `/sections/${sectionPath}` : ''
          }/modules/${module.path}`,
          { initialModel: null }
        )
      }
    },
    update: (
      cache,
      {
        data: {
          createModule: { module }
        }
      }
    ) => {
      let variables = {}
      let clientData = {}
      if (sectionPath) {
        variables = {
          path: sectionPath
        }
        clientData = cache.readQuery({
          query: ADMIN_SECTION,
          variables
        })
        clientData.section.modules = [...clientData.section.modules, module]
        cache.writeQuery({ query: ADMIN_SECTION, variables, data: clientData })
      } else if (coursePath) {
        variables = {
          path: coursePath
        }
        clientData = cache.readQuery({
          query: ADMIN_COURSE,
          variables
        })
        clientData.course.modules = [...clientData.course.modules, module]
        cache.writeQuery({ query: ADMIN_COURSE, variables, data: clientData })
      } else {
        clientData = cache.readQuery({
          query: ADMIN_MODULES,
          variables
        })
        clientData.modules = [...clientData.modules, module]
        cache.writeQuery({ query: ADMIN_MODULES, variables, data: clientData })
      }
    }
  })

  // Submit
  const onSubmit = doc => {
    delete doc.pages // prevent typing errors when sending complex objects that aren't needed in the model
    if (
      !_module.path &&
      location &&
      location.state &&
      location.state.initialModel &&
      location.state.initialModel.originId
    ) {
      // Content is a translation, preparing ModuleInput
      doc.originId = location.state.initialModel.originId
      delete doc.owners
      createModule({ variables: { ModuleInput: doc } })
    } else {
      if (!doc.test) {
        doc.randomized = []
        doc.anonymousPages = false
      } // if not test, content never random or anonymous
      if (_module.path) {
        const UpdateModuleInput = { path: _module.path, doc }
        delete doc.owners
        updateModule({
          variables: { UpdateModuleInput }
        })
      } else {
        doc.parentType = (sectionPath && 'section') || (coursePath && 'course')
        doc.parentPath = sectionPath || coursePath
        createModule({ variables: { ModuleInput: doc } })
      }
    }
  }

  // Loading data
  if (loading || userLoading) return <BoardBody loading />
  if (error && modulePath) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_module'>
          Er ging iets mis bij het laden van deze module.
        </Translate>
      </BoardBody>
    )
  }

  // edit || duplicate || create || translate
  _module =
    (data && data.module) ||
    (location && location.state && location.state.initialModel) ||
    {}

  if (!_module.path) {
    _module.published = true // set default for published to true when creating new module
  }

  const { currentUserWithRules } = currentUserData || {}

  // Prepare for translation
  const sendToTranslation = () => {
    const initialModel = _module
    initialModel.name = `Vertaling van ${_module.name}` // TODO: translate
    // if we are starting from a translation of a translation, use the original's originId and not the translation's path
    initialModel.originId = _module.originId || _module.path
    delete initialModel['path']
    history.push('new-module', { initialModel })
  }

  // Methods
  const actions = guard({
    user: currentUserWithRules,
    content: _module,
    action: modulePath ? 'edit' : 'create'
  }) ? (
    <>
      {!coursePath ? (
        <DashboardLink
          className='btn btn-lg'
          view='certificate-config'
          title='manage certificate'
        >
          <FontAwesomeIcon icon={'award'} />
        </DashboardLink>
      ) : null}
      {config && config.multilingual && (
        <span
          className='btn btn-lg'
          title='Translate'
          onClick={() => sendToTranslation()}
        >
          <FontAwesomeIcon icon={'flag'} />
        </span>
      )}
      <DashboardLink className='btn btn-lg' view='pages' title='Pagina’s'>
        <FontAwesomeIcon icon={'book-open'} />
      </DashboardLink>
      <DashboardLink
        className='btn btn-lg'
        view='link-teachers'
        title='Administrators'
      >
        <FontAwesomeIcon icon={'chalkboard-teacher'} />
      </DashboardLink>
      {guard({ user: currentUserWithRules, action: 'assign_participants' }) && (
        <DashboardLink className='btn btn-lg' view='users' title='participants'>
          <FontAwesomeIcon icon='users' />
        </DashboardLink>
      )}
      <DashboardLink className='btn btn-lg' view='results' title='Results'>
        <FontAwesomeIcon icon={'poll-h'} />
      </DashboardLink>
    </>
  ) : null

  return (
    <>
      <BoardHead
        title={_module.name || 'New module'}
        actions={modulePath ? actions : null}
      >
        <em>Module</em>
      </BoardHead>
      <BoardBody>
        <Form
          elements={elements}
          initialModel={_module}
          onSubmit={e => {
            onSubmit(e) // TODO: show alert with result
          }}
          key={modulePath}
        >
          {guard({
            user: currentUserWithRules,
            content: _module,
            action: modulePath ? 'edit' : 'create'
          }) && (
            <Button type='submit' color='primary' data-cy='module_form'>
              <Translate _id='button/save'>Opslaan</Translate>
            </Button>
          )}
        </Form>
      </BoardBody>
    </>
  )
}

export default Module
