import React, { useState } from 'react'
import { render } from 'react-dom'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'

import { useTranslator } from '@lefapps/translations'

import { uploadHandler } from '../../../../_helpers/thumbnails'
import config from '../../../config'

import { Form } from '../index'

const { assets } = config.storage || {}

const uploader = [
  {
    name: 'image',
    label: { nl: 'Afbeelding', en: 'Image', fr: 'Image' },
    type: 'upload',
    required: true,
    attributes: {
      placeholder: 'Kies een foto'
    },
    host: assets,
    uploadHandler
  },
  {
    name: 'imageSize',
    label: {
      nl: 'Afmeting',
      en: 'Image Size',
      fr: `Dimension de l'image`
    },
    type: 'select',
    schema: {
      description: '(Optioneel) Kies de afmeting voor je afbeelding'
    },
    options: [
      { _id: 'small-image', nl: 'Klein', en: 'Small', fr: 'Petite' },
      { _id: 'medium-image', nl: 'Gemiddeld', en: 'Medium', fr: 'Moyenne' },
      { _id: 'large-image', nl: 'Groot', en: 'Large', fr: 'Grande' },
      { _id: 'banner-image', nl: 'Banner', en: 'Banner', fr: 'Banner' }
    ]
  }
]

const MarkdownImageModal = ({ handler }) => {
  const [isOpen, setOpen] = useState(true)
  const { language } = useTranslator()
  const toggle = () => setOpen(!isOpen)
  const handleSubmit = (image, imageSize) => {
    handler(image, imageSize)
    toggle()
  }
  const translations = {
    'form/add_image': {
      nl: 'Voeg een afbeelding toe.',
      fr: 'Ajouter une photo.',
      en: 'Add a picture.'
    },
    'button/add': {
      nl: 'Toevoegen',
      fr: 'Ajouter',
      en: 'Add'
    }
  }
  return (
    <Modal toggle={toggle} isOpen={isOpen} className={'admin-dashboard__style'}>
      <ModalHeader toggle={toggle}>
        {translations['form/add_image'][language]}
      </ModalHeader>
      <ModalBody>
        <Form elements={uploader} onSubmit={handleSubmit}>
          <Button type={'submit'}>
            {translations['button/add'][language]}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

const insertMdImage = (source, { name, alt }, [start, end], { imageSize }) => {
  let result = ''
  result += source.slice(0, start)
  result += '!['
  result += alt || name
  result += ']('
  result += assets + 'main/' + name
  result += ')'
  if (imageSize) result += `{.${imageSize}}` // Inject image size as className in markdown
  result += source.slice(end)
  return result
}

const markdownImage = ({ value, cursor }) =>
  new Promise((resolve, reject) => {
    const parentNode = document.createElement('div')
    document
      .querySelector('.admin-board__full .admin-board__body')
      .append(parentNode)
    const handler = ({ image, imageSize }) => {
      if (image) {
        let inc = value.length
        value = insertMdImage(value, image, cursor, { imageSize })
        inc = value.length - inc
        cursor[1] += inc
        return resolve({ value, cursor })
      } else return reject({ value, cursor })
    }
    render(<MarkdownImageModal handler={handler} />, parentNode)
  })

/** @member markdownImage
 *
 * Renders an upload button in the toolbar
 * of a textarea with the option {md:true}
 * assign this value to the key 'toolbar' `toolbar: [[ markdownImage ]]`
 *
 */

export default {
  icon: 'image',
  title: 'Image',
  prepend: '',
  append: '',
  middleware: markdownImage
}
