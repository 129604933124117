import React, { useState } from 'react'
import { withRouter, NavLink, Switch, Route } from 'react-router-dom'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { PropTypes } from 'prop-types'

import './navtab.scss'

const NavTabItem = ({ to, label, title, className }) => {
  return (
    <NavLink
      exact
      className={'navtab-item ' + (className || '')}
      to={to}
      title={title}
    >
      {label}
    </NavLink>
  )
}

const NavTabOption = ({ to, label, title, className }) => {
  return (
    <DropdownItem className={'navtab-item'}>
      <NavLink className={className} exact to={to} title={title}>
        {label}
      </NavLink>
    </DropdownItem>
  )
}

// TODO: specific to exams
const NavTabInfo = ({ links }) => {
  const totalPages = links.length - 1
  return <div className='navtab-item disabled'>{`${totalPages} pagina's`}</div>
}

const NavTab = ({
  links = [],
  collapse = 'xs',
  summary,
  summaryLabel,
  hideFull,
  history,
  match: { url }
}) => {
  const [isOpen, setOpen] = useState()
  const toggle = () => setOpen(!isOpen)

  return (
    <nav className={'navtab' + (summary ? ' navtab__summary' : '')}>
      <Dropdown
        isOpen={isOpen}
        toggle={toggle}
        className={`navtab-container__collapse d-flex ${
          summary ? '' : 'd-' + collapse + '-none'
        }`}
      >
        <DropdownToggle color={'light'} caret>
          {summaryLabel || (
            <Switch>
              {links.map(({ to, label, title }, key) => (
                <Route path={to} exact key={key}>
                  {label} {title}
                </Route>
              ))}
            </Switch>
          )}
        </DropdownToggle>
        <DropdownMenu>
          {links.map((link, key) => (
            <NavTabOption key={key} {...link} history={history} />
          ))}
        </DropdownMenu>
      </Dropdown>

      <div className={`navtab-container d-none d-${collapse}-flex`}>
        {!hideFull ? (
          links.map((link, key) => {
            return <NavTabItem key={key} {...link} />
          })
        ) : (
          <>
            <NavTabInfo links={links} />
            <NavTabItem
              key={links[links.length - 1]}
              {...links[links.length - 1]}
            />
          </>
        )}
      </div>
    </nav>
  )
}

NavTab.propTypes = {
  links: PropTypes.array.isRequired,
  current: PropTypes.number,
  collapse: PropTypes.string,
  summary: PropTypes.bool, // shows dropdown summary on all sizes
  summaryLabel: PropTypes.string // optional summary label
}

export default withRouter(NavTab)
