import React, { useEffect, useState } from 'react'
import { Button, Fade } from 'reactstrap'
import has from 'lodash/has'

import { Translate } from '@lefapps/translations'

import { Form } from '../../_shared/Form/Uniligual'

import { render } from '../../../_helpers/markdown'

export const Content = ({ content, context, states, callbacks }) => {
  // DATA EXTRACTION
  const { name, type, schema = {} } = content
  const { result = {} } = context
  const { score = 0 } = states
  const { saveResult } = callbacks

  const { autoShow } = schema

  // STATE & EFFECTS
  // Show answers based on number of attempts [ITG-376]
  const [currentAttempts, setCurrentAttempts] = useState(
    Math.max(score, has(result, name) ? 1 : 0)
  )

  // Give some slack when multiple answers are correct [#1]
  const correctAnswers = (content.options || []).filter(
    ({ score = 0 }) => score > 0
  ).length
  const maxAttempts = schema.attempts
    ? Number(schema.attempts) + Math.max(correctAnswers - 1, 0)
    : 0

  // Show answers
  const [showAnswers, setShowAnswers] = useState(false)

  // Hide answers until 'show answers' gets clicked on new pages
  useEffect(() => {
    setShowAnswers(false)
  }, [name])

  // Immediately show answers when (all) correct ones are checked [#1]
  useEffect(() => {
    if (currentAttempts > 0 && score === correctAnswers) {
      if (autoShow) setShowAnswers(true)
      if (maxAttempts > 0) setCurrentAttempts(maxAttempts)
    }
  }, [currentAttempts, autoShow, correctAnswers, maxAttempts, score])

  // METHODS
  const saveThisResult = doc => {
    if (!!maxAttempts) setCurrentAttempts(currentAttempts + 1)
    return saveResult(doc)
  }

  // RENDER
  return (
    <div className={showAnswers ? 'lefappsFormsShow' : ''}>
      <Form
        elements={[content]}
        initialModel={result}
        onStateChange={saveThisResult}
        // UX-wise not a fan of the soft-locking after maxAttempts
        readOnly={maxAttempts > 0 && currentAttempts >= maxAttempts}
      />
      {
        // alternative switch-case notation below o.O
        (
          {
            question: [
              () => null,
              () => (
                <div className='lefappsFormsShow__prompt'>
                  {!!maxAttempts && !showAnswers && (
                    <p>
                      <small>
                        <Translate _id='page/attempts'>Pogingen</Translate>:{' '}
                        {currentAttempts}/{maxAttempts}
                      </small>
                    </p>
                  )}
                  {!!maxAttempts && maxAttempts <= currentAttempts && (
                    <Fade>
                      {render(schema.info || '')}
                      {!showAnswers && setShowAnswers(true)}
                    </Fade>
                  )}
                </div>
              ),
              () => (
                <div className='lefappsFormsShow__prompt'>
                  {showAnswers ? (
                    <Fade>{render(schema.info || '')}</Fade>
                  ) : (
                    <Button
                      size='sm'
                      color='info'
                      outline
                      onClick={() => setShowAnswers(true)}
                    >
                      <Translate _id='page/show_answers'>
                        Juiste antwoord(en) tonen
                      </Translate>
                    </Button>
                  )}
                </div>
              )
            ][autoShow ? 1 : context.module.showCorrect ? 2 : 0]
          }[type] || (() => null)
        )()
      }
    </div>
  )
}

export default Content
