import React from 'react'

import config from '../config'

const { assets = '/' } = config.storage || {}

/** @func Image
 *
 * @param {string} name* - show an image with this filename
 * @param {string} context* - get a thumbnail size (full, main, aside, blocklist)
 * @param {string} alt - display an alt text (defaults to name without extension)
 * @param {string} host - change image host (defaults to config.storage.assets)
 * @param {string} props - props like `width`, `className` etc.
 */

export const Image = ({ name, alt = '', context, host = assets, ...props }) => {
  const [base, ext] = name.split('.')
  const format = context ? context + '/' : ''
  return (
    <img
      {...props}
      src={`${host}${format}${base}.${ext}`}
      srcSet={`${host}${format}${base}.${ext} 1x, ${host}${format}${base}@2x.${ext} 2x,`}
      alt={alt || base}
      onError={({ currentTarget }) => (currentTarget.style.display = 'none')}
      key={name}
    />
  )
}
