import React from 'react'
import { Link } from 'react-router-dom'
import { NavItem, NavLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminDashboard, { AdminTools } from '@lefapps/admin-dashboard'
import { PickLanguage } from '@lefapps/translations'

import config from '../config'
import paths from './routeConfig'

import UserMenu from '../_shared/UserMenu'

import Home from './Home'

const { storage = {}, images = {}, colors = {} } = config
const { client } = storage
const [logo, logoExt] = images.footer || []

const branding = {
  color: (colors && colors.primary) || '',
  logo: `${client}images/${logo}.${logoExt}`
}

const NotFound = () => <h1>404: Path not found</h1>

const Dashboard = () => {
  return (
    <>
      <AdminDashboard
        settings={paths}
        label={'Admin'}
        branding={branding}
        notFoundComponent={<NotFound />}
      >
        <Home />
      </AdminDashboard>
      <AdminTools>
        <NavItem>
          <NavLink tag={Link} to={'/'}>
            <FontAwesomeIcon icon={'home'} />
          </NavLink>
        </NavItem>
        <PickLanguage>
          <FontAwesomeIcon icon='flag' />
        </PickLanguage>
        <UserMenu />
      </AdminTools>
    </>
  )
}

export default Dashboard
