import React from 'react'
import { PickLanguage } from '@lefapps/translations'
import config from '../config'

import { Translate } from '@lefapps/translations'

import { FooterLayout } from '../_layout/Footer'

import { Image } from './Image'

const { storage = {}, images = {} } = config

const Footer = () => {
  const { client } = storage
  const [name, ext, alt] = images.footer || []

  return (
    <FooterLayout>
      <div className='row align-items-center'>
        <div className='col-tablet-4'>
          <Image
            name={`${name}.${ext}`}
            alt={alt}
            context='images'
            host={client}
            width={197}
          />
        </div>
        <Translate
          tag='div'
          className='col-tablet-6'
          _id='footer/content'
          md
        ></Translate>
        <PickLanguage showTitle className='col-tablet-2' />
      </div>
    </FooterLayout>
  )
}

export default Footer
