import markdownImage from '../../_shared/Form/custom/MarkdownImage'

import config from '../../config'

const formElements = [
  {
    name: 'name',
    label: {
      nl: 'Naam',
      en: 'Name',
      fr: 'Nom'
    },
    type: 'text',
    required: true
  },
  {
    name: 'language',
    label: {
      nl: 'Taal',
      en: 'Language',
      fr: 'Langue'
    },
    type: 'radio',
    options: (config && config.multilingual) || ['en', 'nl', 'fr'],
    required: true
  },
  {
    name: 'image',
    label: {
      nl: 'Foto',
      en: 'Image',
      fr: 'Image'
    },
    type: 'upload',
    attributes: {
      placeholder: 'Kies een foto'
    }
  },
  {
    name: 'published',
    label: {
      nl: 'Inhoud publiceren',
      en: 'Publish content',
      fr: 'Publier du contenu'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl: 'Ongepubliceerde inhoud wordt niet getoond aan deelnemers.',
        en: `Unpublished content isn't shown to particpants.`,
        fr: 'Le contenu non publié ne sera pas montré aux participants.'
      }
    }
  },
  {
    name: 'public',
    label: {
      nl: 'Openbare inhoud',
      en: 'Public content',
      fr: 'Contenu public'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl:
          'Openbare inhoud is zichtbaar voor alle geregistreerde deelnemers, ook zij die niet gekoppeld zijn aan deze inhoud.',
        en:
          'Public content is visible to all registered users, including those who are not linked to this content.',
        fr:
          'Le contenu public est visible par tous les participants inscrits, y compris ceux qui ne sont pas liés à ce contenu.'
      }
    }
  },
  {
    name: 'showCorrect',
    label: {
      nl: 'Juiste antwoord tonen',
      en: 'Show correct answer',
      fr: 'Montrer la réponse correcte'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl:
          'Gebruiker ziet meteen of het geselecteerde antwoord juist/fout is. Is nooit van toepassing op tests/examens.',
        en:
          'User can see if their selected answer is correct. This option is never applied to tests/exams',
        fr: `L'utilisateur voit immédiatement si la réponse choisie est bonne ou mauvaise. Ne s'applique jamais aux tests/examens.`
      }
    }
  },
  {
    name: 'gateProgress',
    label: {
      nl: 'Voortgang blokkeren',
      en: 'Block progress',
      fr: 'Blocage du progrès'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl:
          'De deelnemer kan pas naar de volgende pagina wanneer zij alle vragen op de huidige pagina beantwoord hebben. ! Kan niet toegepast worden op examen/test.',
        en:
          'The particpant is not allowed to progress to the next page until they have answered all questions on the current page. ! Can not be applied to an exam/test.',
        fr: `Le participant ne peut passer à la page suivante que lorsqu'il a répondu à toutes les questions de la page en cours. ! Ne peut pas être appliqué à un examen/test.`
      }
    },
    dependent: {
      on: 'test',
      operator: 'is',
      values: false
    }
  },
  {
    name: 'gateConfig',
    label: {
      nl: 'Extra opties voor het blokkeren van voortgang',
      en: 'Extra options for the blocking of progress',
      fr: 'Options supplémentaires pour bloquer la progression'
    },
    type: 'checkbox-mc',
    schema: {
      description: {
        nl:
          'All-correct: De deelnemer kan pas verder wanneer alle vragen correct zijn beantwoord. (Niet van toepassing op open vragen.)',
        en:
          'All-correct: The participant can only continue when all questions have been answered correctly. (Does not apply to open questions.)',
        fr:
          'Tout correct : le participant ne peut poursuivre que si toutes les questions ont reçu une réponse correcte. (Sans objet pour les questions ouvertes).'
      }
    },
    options: ['all-correct']
  },
  {
    name: 'test',
    label: {
      nl: 'Module is een examen/test',
      en: 'Module is an exam/test',
      fr: 'Module est un exam/test'
    },
    type: 'checkbox',
    schema: {
      description: {
        nl:
          'De module is een test of examen (extra opties weergeven). ! Kan niet gecombineerd worden met `Voortgang blokkeren`',
        en:
          'The module is an exam or a test - shows extra options. ! Can not be combined with `Block progress`',
        fr:
          'Le module est un test ou un examen (afficher les options supplémentaires). ! Ne peut pas être combiné avec `Blocage du progrès`.'
      }
    }
  },
  {
    name: 'anonymousPages',
    label: {
      nl: `Anonieme pagina's`,
      en: 'Anonymous pages',
      fr: 'Pages anonymes'
    },
    type: 'checkbox',
    dependent: {
      on: 'test',
      operator: 'is',
      values: true
    },
    schema: {
      description: {
        nl: `Als deze optie aan staat worden de namen van de pagina's nooit aan de deelnemer getoond.`,
        en:
          'When this option is applied the names of the pages are never shown to the participant',
        fr:
          'Si cette option est activée, les noms des pages ne seront jamais montrés au participant.'
      }
    }
  },
  {
    name: 'randomized',
    label: {
      nl: 'Extra opties examen/test',
      en: 'Extra options exam/test',
      fr: `Examen/test d'opacité supplémentaire`
    },
    type: 'checkbox-mc',
    dependent: {
      on: 'test',
      operator: 'is',
      values: true
    },
    schema: {
      description: {
        nl: `Indien nodig, vink hier de opties aan om de inhoud van de module te randomiseren: de volgorde van de pagina's (pages) (opmerking: de eerste pagina is steeds dezelfde), de vragen op de pagina's (questions) en/of de volgorde van de antwoorden van multiple choice vragen.`,
        en:
          'If needed, check the options to randomise the content of the module: the order of the pages (note: the first page remains the same), the questions and/or the order of the answers on multiple choice questions.',
        fr: `Si nécessaire, cochez les options permettant de rendre aléatoire le contenu du module : l'ordre des pages (attention : la première page est toujours la même), les questions des pages et/ou l'ordre des réponses des questions à choix multiples.`
      }
    },
    options: ['pages', 'questions', 'answers']
  },
  {
    name: 'info',
    label: {
      nl: 'Informatie',
      en: 'Information',
      fr: 'Info'
    },
    type: 'textarea',
    md: true,
    toolbar: [[markdownImage]],
    layout: {
      col: {
        md: 12
      }
    }
  }
]

export { formElements }
