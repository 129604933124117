import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'reactstrap'
import { Translate } from '@lefapps/translations'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_LOG } from '../mutations'
import { CURRENT_USER } from '../User/queries'

const CourseCertificate = course => {
  // Queries
  const { loading, error, data } = useQuery(CURRENT_USER)
  // Mutations
  const [updateLog] = useMutation(UPDATE_LOG)

  // Early exit
  if (error) console.error(error)

  let certificate
  if (data.currentUser && data.currentUser.courseCertificates) {
    data.currentUser.courseCertificates.map(item => {
      if (item.course === course.path) certificate = item
      return certificate
    })
  }

  return (
    <div className='text-center bg-light p-5'>
      {loading && <Spinner color='primary' />}
      {!loading && !error && certificate ? (
        <>
          <h3 className='text-center text-primary' style={{ fontSize: '4em' }}>
            <FontAwesomeIcon icon='award' />
          </h3>
          <p>
            <strong className='text-primary'>
              <Translate _id='courseCertificate/congratulations'>
                Gefeliciteerd
              </Translate>
            </strong>
            <br />
            <Translate _id='courseCertificate/message'>
              u behaalde een certificaat voor deze cursus!
            </Translate>
          </p>
          <br />
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={'/certificates/'+certificate.path.split('/').pop()}
            download={certificate.path.split('/').pop()}
            className='btn btn-sm btn-primary'
            onClick={() => {
              // log downloads
              const doc = {
                tracking: {
                  type: 'certificates',
                  path: course.path,
                  lastSeen: new Date()
                }
              }
              const UpdateLogInput = { doc }
              updateLog({ variables: { UpdateLogInput } })
            }}
          >
            <FontAwesomeIcon icon='download' /> Download…
          </a>
        </>
      ) : null}
    </div>
  )
}

export default CourseCertificate
