import React from 'react'
import { withRouter } from 'react-router-dom'
import truncate from 'lodash/truncate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@apollo/react-hooks'
import { MODULES } from './queries'
import { CURRENT_USER } from '../User/queries'

import { Translate, withTranslator } from '@lefapps/translations'

import config from '../config'
import { render } from '../../_helpers/markdown'
import { uniSlash } from '../../_helpers/url'
import { BlockList, BlockListItem } from '../_layout/BlockList'
import { Icon } from '../_layout/Bits'
import { Loading } from '../_shared/Loading'
import groupTranslations from '../_shared/groupTranslations'

const entryPointPath = (modulePath, entryPoint) => {
  const { type, path } = entryPoint || {}
  let link = ''
  if (type && path) {
    if (type !== 'module') link += '/' + type + '/' + path
  }
  return 'module/' + modulePath + link
}

export const ModuleList = withRouter(({ modules, small, match: { url } }) => (
  <BlockList small={!!small}>
    {modules &&
      modules.map((module, key) => {
        const { path, name, image, info, ongoing, completed, entryPoint } =
          module || {}
        const link = entryPointPath(path, entryPoint)
        return (
          <BlockListItem
            link={uniSlash(`${url}/${link}`)} // url can be '/' or '/section/:path'
            key={key}
            color={'secondary'}
            image={image}
            header={
              <h3>
                <Icon icon={'module'} />
                {name}
                <small className={'float-right'}>
                  {ongoing && <FontAwesomeIcon icon={'sync-alt'} />}
                  {completed && <FontAwesomeIcon icon={'check'} />}
                </small>
              </h3>
            }
          >
            {small && truncate(info, { length: 128 })}
            {!small && info && render(info)}
          </BlockListItem>
        )
      })}
  </BlockList>
))

const Modules = withTranslator(
  ({ match: { url }, noParents, onHome, singleModule, language }) => {
    // Queries
    const { loading, error, data } = useQuery(MODULES, {
      variables: { noParents, published: true, onHome }
    })
    const { loading: userLoading, data: userData } = useQuery(CURRENT_USER)

    // Early exit
    if (loading || userLoading) return <Loading />
    if (error) {
      return (
        <Loading color={'danger'}>
          <Translate _id='error/loading_modules'>
            Er ging iets mis bij het laden van modules.
          </Translate>
        </Loading>
      )
    }

    let modules = singleModule || data.modules // get all modules or a single one?
    const currentUser = userData.currentUser

    if (!modules) {
      return (
        <Loading color={'warning'} icon={'exclamation-circle'}>
          <Translate _id='nocontent/no_modules_found'>
            Geen modules gevonden
          </Translate>
        </Loading>
      )
    }

    if (modules && modules.length === 0) return null

    if (config.multilingual) {
      let currentLanguage = language || 'en'
      modules =
        groupTranslations(modules, currentUser.language, currentLanguage) ||
        modules
    }

    // Render
    return <ModuleList modules={modules} url={url} />
  }
)

export default withRouter(Modules)
