import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Profile from './Profile'
import Certificates from './Certificates'

const UserRouter = ({ match: { path } }) => {
  return (
    <Switch>
      <Route path={path + '/certificates'} component={Certificates} />
      <Route path={path + '/'} component={Profile} />
    </Switch>
  )
}

export default UserRouter
