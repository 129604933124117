const formElements = [
  {
    name: 'name',
    label: {
      nl: 'Naam',
      en: 'Name',
      fr: 'Nom'
    },
    type: 'text',
    required: true,
    layout: {
      col: {
        md: 12
      }
    }
  },
  {
    name: 'actions.view',
    label: {
      nl: 'View',
      en: 'View',
      fr: 'View'
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    },
    schema: {
      description: {
        nl:
          'De gebruiker kan alle content op de website bekijken. Als deze optie uit staat kan de gebruiker enkel content bekijken waar hij/zij beheerder van is.',
        en:
          'The user can view all content on the website. If this option is disabled, the user can only see the content for which they are an administrator.',
        fr: `L'utilisateur peut visualiser tout le contenu du site web. Si cette option est désactivée, l'utilisateur ne peut voir que le contenu pour lequel il est administrateur.`
      }
    }
  },
  {
    name: 'actions.create',
    label: {
      nl: 'Create',
      en: 'Create',
      fr: 'Create'
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    },
    schema: {
      description: {
        nl:
          'De gebruiker kan content aanmaken. Als deze optie uit staat kan de gebruiker geen nieuwe content aanmaken.',
        en:
          'The user can create content. If this option is off, the user will not be able to create new content.',
        fr: `L'utilisateur peut créer du contenu. Si cette option est désactivée, l'utilisateur ne peut pas créer de nouveau contenu.`
      }
    }
  },
  {
    name: 'actions.edit',
    label: {
      nl: 'Edit',
      en: 'Edit',
      fr: 'Edit'
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    },
    schema: {
      description: {
        nl:
          'De gebruiker kan alle content bewerken. Als deze optie uit staat kan de gebruiker enkel content bewerken waar hij/zij beheerder van is.',
        en:
          'The user can edit all content. If this option is off, the user can only edit content that he/she is administrator of.',
        fr: `L'utilisateur peut modifier tout le contenu. Si cette option est désactivée, l'utilisateur ne peut modifier que le contenu dont il est l'administrateur`
      }
    }
  },
  {
    name: 'actions.delete',
    label: {
      nl: 'Delete',
      en: 'Delete',
      fr: 'Delete'
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    },
    schema: {
      description: {
        nl:
          'De gebruiker kan alle content verwijderen. Als deze optie uit staat kan de gebruiker enkel content verwijderen waar hij/zij beheerder van is.',
        en:
          'The user can delete all content. If this option is off, the user can only delete content that he/she is administrator of.',
        fr: `L'utilisateur peut supprimer tout le contenu. Si cette option est désactivée, l'utilisateur ne peut supprimer que le contenu dont il est l'administrateur.`
      }
    }
  },
  {
    name: 'actions.assign_participants',
    label: {
      nl: 'Assign participants',
      en: 'Assign participants',
      fr: 'Assign participants'
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    },
    schema: {
      description: {
        nl:
          'De gebruiker kan gebruikers koppelen aan content. Als deze optie uit staat kan de gebruiker geen deelnemers koppelen aan content.',
        en:
          'The user can link users to content. If this option is off, the user cannot link participants to content.',
        fr: `L'utilisateur peut lier les utilisateurs au contenu. Si cette option est désactivée, l'utilisateur ne peut pas lier les participants au contenu`
      }
    }
  },
  {
    name: 'actions.assign_owners',
    label: {
      nl: 'Assign owners',
      en: 'Assign owners',
      fr: 'Assign owners'
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    },
    schema: {
      description: {
        nl:
          'De gebruiker kan andere gebruikers toevoegen als eigenaar aan content. Als deze optie uit staat kan de gebruiker geen eigenaars toewijzen aan content.',
        en:
          'The user can add other users as owners to content. If this option is off, the user cannot assign owners to content.',
        fr: `L'utilisateur peut ajouter d'autres utilisateurs comme propriétaires du contenu. Si cette option est désactivée, l'utilisateur ne peut pas attribuer de propriétaires au contenu.`
      }
    }
  },
  {
    name: 'actions.manage_roles',
    label: {
      nl: 'Manage Roles',
      en: 'Manage Roles',
      fr: 'Manage Roles'
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    },
    schema: {
      description: {
        nl:
          'De gebruiker kan rollen beheren en hier op deze pagina regels aan toevoegen/verwijderen. Als deze optie uit staat kan de gebruiker geen rollen beheren.',
        en:
          'The user can manage roles and add/delete rules to them on this page. If this option is off, the user cannot manage any roles.',
        fr: `L'utilisateur peut gérer les rôles et ajouter/supprimer des règles à ces rôles sur cette page. Si cette option est désactivée, l'utilisateur ne peut gérer aucun rôle.`
      }
    }
  },
  {
    name: 'actions.manage_users',
    label: {
      nl: 'Manage Users',
      en: 'Manage Users',
      fr: 'Manage Users'
    },
    type: 'checkbox',
    layout: {
      col: {
        md: 12
      }
    },
    schema: {
      description: {
        nl:
          'De gebruiker kan de algemene en tracking-gegevens van alle gebruikers bekijken en rollen toewijzen aan andere gebruikers. Als deze optie uit staat kan de gebruiker geen andere gebruikers beheren.',
        en:
          'The user can view the general and tracking data of all users and assign roles to other users. If this option is off, the user cannot manage other users.',
        fr: `L'utilisateur peut consulter les informations générales et de suivi de tous les utilisateurs et attribuer des rôles aux autres utilisateurs. Si cette option est désactivée, l'utilisateur ne peut pas gérer d'autres utilisateurs.`
      }
    }
  }
]

export { formElements }
