import React from 'react'
import { useAlert } from 'react-alert'
import pick from 'lodash/pick'

import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_COURSE, ADMIN_COURSES } from './queries'
import { CURRENT_USER_ADMIN } from '../Users/queries'
import { CREATE_COURSE, UPDATE_COURSE } from './mutations'
import { CREATE_SECTION } from '../Sections/mutations'

import { BoardBody, BoardHead, DashboardLink } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import config from '../../config'

import guard from '../_shared/guard'

import { validateDates } from '../../../_helpers/dates'
import { Form } from '../../_shared/Form/index'
import { formElements as elements } from './FormElements'

const Course = ({
  match: {
    params: { coursePath }
  },
  location,
  history
}) => {
  const alert = useAlert()

  let _course

  // Queries
  const { loading, error, data } = useQuery(ADMIN_COURSE, {
    variables: { path: coursePath },
    skip: !coursePath
  })
  const { loading: userLoading, data: currentUserData } = useQuery(
    CURRENT_USER_ADMIN
  )

  // Mutations
  const [createSection] = useMutation(CREATE_SECTION)

  const [updateCourse] = useMutation(UPDATE_COURSE, {
    onCompleted: ({ updateCourse: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
  })

  const [createCourse] = useMutation(CREATE_COURSE, {
    onCompleted: ({ createCourse: { success, message, course } }) => {
      // Catching translations! Check if the original course has sections and/or modules
      if (_course && _course.originId && _course.sections) {
        // create sections
        const reversedSectionList = _course.sections.reverse()
        reversedSectionList.map(section => {
          let doc = section
          doc.parentType = 'course'
          doc.parentPath = course.path // new parent course
          doc.originId = doc.path
          delete doc.path // new section on create
          delete doc.owners
          doc.name = section.name + '-' + course.language
          // move to createSection's onComplete call to finalize translation action of modules belonging to section
          return createSection({ variables: { SectionInput: doc } })
        })
        alert.show('creating sections')
        history.push(`/admin/courses/${course.path}`)
        window.location.reload()
      } else {
        alert.show(message, { type: success ? 'success' : 'danger' })
        // Reload form after creating new course to show edit form
        history.push(`/admin/courses/${course.path}`)
      }
    },
    update: (
      cache,
      {
        data: {
          createCourse: { course }
        }
      }
    ) => {
      const clientData = cache.readQuery({
        query: ADMIN_COURSES
      })
      clientData.courses = [...clientData.courses, course]
      cache.writeQuery({ query: ADMIN_COURSES, data: clientData })
    }
  })

  // Loading data
  if (loading || userLoading) return <BoardBody loading />
  if (error && coursePath) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_courses'>
          Er ging iets mis bij het laden van deze cursus.
        </Translate>
      </BoardBody>
    )
  }

  // edit || duplicate || create
  _course =
    (data && data.course) ||
    (location && location.state && location.state.initialModel) ||
    {}

  if (!_course.path) {
    _course.published = true // set default for published to true when creating new course
  }

  const { currentUserWithRules } = currentUserData || {}

  // Prepare for translation
  const sendToTranslation = () => {
    const initialModel = _course
    initialModel.name = `Translation of ${_course.name}` // TODO: translate
    // if we are starting from a translation of a translation, use the original's originId and not the translation's path
    initialModel.originId = _course.originId || _course.path
    delete initialModel['path']
    history.push('new-course', { initialModel })
  }

  // Methods
  const onSubmit = async doc => {
    delete doc.owners
    if (doc.image) {
      doc.image = pick(doc.image, ['alt', 'name'])
    }
    if (doc.startdate && doc.enddate) {
      if (!validateDates(doc.startdate, doc.enddate)) {
        return alert.show('The enddate cannot be earlier than the startdate.', {
          type: 'warning'
        })
      }
    }

    if (
      !_course.path &&
      location &&
      location.state &&
      location.state.initialModel &&
      location.state.initialModel.originId
    ) {
      // Content is a translation, preparing CourseInput
      doc.originId = location.state.initialModel.originId
      delete doc.owners
      delete doc['modules']
      delete doc['sections']
      delete doc['users']
      createCourse({ variables: { CourseInput: doc } })
    } else if (_course.path) {
      // Catches problem with backend saving objects where strings are expected
      delete doc['modules']
      delete doc['sections']
      delete doc['users']
      const UpdateCourseInput = { path: _course.path, doc }
      await updateCourse({ variables: { UpdateCourseInput } })
    } else {
      await createCourse({ variables: { CourseInput: doc } })
    }
  }

  const actions = guard({
    user: currentUserWithRules,
    content: _course,
    action: coursePath ? 'edit' : 'create'
  }) ? (
    <>
      <DashboardLink
        className='btn btn-lg'
        view='certificate-config'
        title='manage certificate'
        data-cy='certificate'
      >
        <FontAwesomeIcon icon={'award'} />
      </DashboardLink>
      {config && config.multilingual && (
        <span
          className='btn btn-lg'
          title='Translate'
          onClick={() => sendToTranslation()}
        >
          <FontAwesomeIcon icon={'flag'} />
        </span>
      )}
      <DashboardLink
        className='btn btn-lg'
        view='sections'
        title='sections'
        data-cy='sections'
      >
        <FontAwesomeIcon icon={'puzzle-piece'} />
      </DashboardLink>
      <DashboardLink
        className='btn btn-lg'
        view='modules'
        title='modules'
        data-cy='modules'
      >
        <FontAwesomeIcon icon={'book'} />
      </DashboardLink>
      <DashboardLink
        className='btn btn-lg'
        view='files'
        title='Files'
        data-cy='files'
      >
        <FontAwesomeIcon icon={'file-alt'} />
      </DashboardLink>
      {guard({ user: currentUserWithRules, action: 'assign_owners' }) && (
        <DashboardLink
          className='btn btn-lg'
          view='link-teachers'
          title='Administrators'
        >
          <FontAwesomeIcon icon={'chalkboard-teacher'} />
        </DashboardLink>
      )}
      {guard({ user: currentUserWithRules, action: 'assign_participants' }) && (
        <DashboardLink className='btn btn-lg' view='users' title='deelnemers'>
          <FontAwesomeIcon icon='users' />
        </DashboardLink>
      )}
    </>
  ) : null

  return (
    <>
      <BoardHead
        title={_course.name || 'New course'}
        actions={coursePath ? actions : null}
      >
        <em>
          <Translate _id='course/title'>Cursus</Translate>
        </em>
      </BoardHead>
      <BoardBody>
        <Form
          data-cy='courseForm'
          elements={elements}
          initialModel={_course}
          onSubmit={e => {
            onSubmit(e)
          }}
          key={coursePath}
        >
          {guard({
            user: currentUserWithRules,
            content: _course,
            action: coursePath ? 'edit' : 'create'
          }) && (
            <Button type='submit' color='success'>
              <Translate _id='button/save'>Opslaan</Translate>
            </Button>
          )}
        </Form>
      </BoardBody>
    </>
  )
}

export default Course
