import React from 'react'
import PropTypes from 'prop-types'

import BREAKPOINTS from '../_shared/breakpoints'

import './grid.scss'

const Grid = ({ children, collapse = 'tablet' }) => (
  <section
    className={`grid${
      collapse ? ' grid-collapse grid-collapse__' + collapse : ''
    }`}
  >
    {children}
  </section>
)

Grid.propTypes = {
  children: PropTypes.node,
  collapse: PropTypes.oneOf(Object.keys(BREAKPOINTS))
}

export default Grid
