import groupBy from 'lodash/groupBy'
// Takes a list of modules or courses, checks for originId, groups, returns list
const groupTranslations = (contents, userLanguage, currentLanguage) => {
  if (!currentLanguage || !userLanguage) return contents
  let result = []
  const groupedContents = Object.entries(groupBy(contents, 'originId'))
  groupedContents.map(group => {
    // groupedContents is an array containing objects where the second entry is an array of content
    if (group[1] && group[1].length) {
      if (group[1].length === 1) result.push(group[1][0])
      else if (group[0] === 'null') {
        group[1].map(item => result.push(item))
      } else {
        // return all where language === currentLanguage, if none in that group:
        // return all where language === userLanguage, if none in that group:
        const filtered1 = group[1].filter(
          ({ language }) => language === currentLanguage
        )
        if (filtered1) {
          const firstInFilter = filtered1[0]
          result.push(firstInFilter)
        } else {
          const filtered2 = group[1].filter(
            ({ language }) => language === userLanguage
          )
          if (filtered2) {
            const firstInFilter = filtered2[0]
            result.push(firstInFilter)
          } else {
            // return root content
            result.push(
              group[1].filter(({ path, originId }) => path === originId)[0]
            )
          }
        }
      }
    }
    return result
  })
  return result
}

export default groupTranslations
