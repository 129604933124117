import React from 'react'
import moment from 'moment'

import { render } from '../../_helpers/markdown'

import { useQuery } from '@apollo/react-hooks'
import { COURSES } from './queries'
import { CURRENT_USER } from '../User/queries'

import config from '../config'

import { Translate, withTranslator } from '@lefapps/translations'

import { BlockList, BlockListItem } from '../_layout/BlockList'
import { Icon } from '../_layout/Bits'
import { Loading } from '../_shared/Loading'
import groupTranslations from '../_shared/groupTranslations'

const Courses = withTranslator(({ onHome, language }) => {
  // Queries
  const { loading, error, data } = useQuery(COURSES, {
    variables: { published: true, onHome }
  })
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER)

  // Early exit
  if (loading || userLoading) return <Loading />
  if (error) {
    console.error(error)
    return (
      <Loading color={'danger'}>
        <Translate _id='error/loading_courses'>
          Er ging iets mis bij het laden van cursussen.
        </Translate>
      </Loading>
    )
  }

  let { courses } = data
  const currentUser = userData.currentUser

  if (!courses) {
    return (
      <Loading color={'warning'} icon={'exclamation-circle'}>
        <Translate _id='nocontent/no_courses_found'>
          Geen cursussen gevonden.
        </Translate>
      </Loading>
    )
  }

  if (courses && courses.length === 0) return null

  if (config.multilingual) {
    let currentLanguage = language || 'en'
    courses =
      groupTranslations(courses, currentUser.language, currentLanguage) ||
      courses
  }

  // TODO: why don't all things show up, why doesnt it parse?
  // Render
  return (
    <BlockList>
      {courses &&
        courses.map((course, index) => {
          const { path, name, info, startdate, enddate, image } = course || {}
          if (!path) return null
          const header = (
            <>
              <h3>
                <Icon icon={'course'} />
                {name}
              </h3>
              {startdate && enddate ? (
                <small>
                  {moment(startdate).format('DD/MM/YYYY')} -{' '}
                  {moment(enddate).format('DD/MM/YYYY')}
                </small>
              ) : null}
            </>
          )
          return (
            <BlockListItem
              link={`/course/${path}`}
              key={index}
              color={'primary'}
              image={image}
              header={header}
            >
              {info ? render(info) : null}
            </BlockListItem>
          )
        })}
    </BlockList>
  )
})

export default Courses
