import React from 'react'
import PropTypes from 'prop-types'

import { Container } from '../Containers'

import './footer.scss'

const FooterLayout = ({ children }) => {
  return (
    <footer id='footer'>
      <Container vertical width={'full'}>
        {children}
      </Container>
    </footer>
  )
}

FooterLayout.propTypes = {
  children: PropTypes.node
}

export default FooterLayout
