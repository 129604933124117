import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAlert } from 'react-alert'

import cloneDeep from 'lodash/cloneDeep'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_USERS, ADMIN_USERS_CSV, CURRENT_USER_ADMIN } from './queries'
import { DELETE_USER, REMOVE_ITEM_FROM_USER } from './mutations'

import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem,
  DashboardLink
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'
import { initials } from '../../../_helpers/users'
import DownloadCSV from '../../_shared/DownloadCSV'

import guard from '../_shared/guard'

const UserItem = ({ user, queryVars, refetch, currentUser }) => {
  const alert = useAlert()

  // Mutations
  const [deleteUser, { loading, error }] = useMutation(DELETE_USER, {
    onCompleted: ({ deleteUser: { success, message } }) => {
      alert.show({ type: success ? 'success' : 'danger' })
    },
    update: (
      cache,
      {
        data: {
          deleteUser: {
            user: { _id: deletedId }
          }
        }
      }
    ) => {
      const data = cloneDeep(cache.readQuery({ query: ADMIN_USERS }))
      data.users = data.users.filter(({ _id }) => _id !== deletedId)
      cache.writeQuery({ query: ADMIN_USERS, data })
    }
  })

  const [
    removeItemFromUser,
    { loading: userUnlinkLoading, error: userUnlinkError }
  ] = useMutation(REMOVE_ITEM_FROM_USER, {
    onCompleted: ({ removeItemFromUser: { success, message } }) => {
      alert.show(JSON.stringify(message), {
        type: success ? 'success' : 'danger'
      })
      refetch() // Too many variables to update cache; awaiting elegant solution
    }
  })

  // Methods
  const remove = () => {
    if (
      window.confirm(
        'Ben je zeker dat je deze gebruiker van het platform wenst te verwijderen? Deze actie kan niet ongedaan gemaakt worden.'
      )
    ) {
      deleteUser({ variables: { _id: user._id } })
    }
  }

  const unlink = () => {
    removeItemFromUser({
      variables: {
        _id: user._id,
        itemType: queryVars.getAccess,
        itemPath: queryVars.module || queryVars.course
      }
    })
  }

  // Components
  let actions = {}
  if (guard({ user: currentUser, action: 'manage_users' })) {
    actions.edit = { to: `/users/${user._id}` }
  }
  // Splitting actions for userfriendly order
  if (queryVars && (queryVars.module || queryVars.course)) {
    actions.unlink = {
      onClick: unlink,
      loading: userUnlinkLoading,
      error: userUnlinkError
    }
  }
  if (guard({ user: currentUser, action: 'manage_users' })) {
    actions.remove = { onClick: remove, loading, error }
  }

  // Render
  return (
    <BoardListItem label={initials(user.name)} actions={actions}>
      <strong>{user.name}</strong>
      <br />
      <small>{user.email}</small>
    </BoardListItem>
  )
}

const Users = ({
  match: {
    params: { coursePath, modulePath }
  }
}) => {
  // Queries
  const queryVars = {
    course: coursePath,
    module: modulePath,
    getAccess: (modulePath && 'module') || (coursePath && 'course')
  }

  const { loading, error, data, refetch } = useQuery(ADMIN_USERS, {
    variables: queryVars
  })

  const { loading: userLoading, data: currentUserData } =
    useQuery(CURRENT_USER_ADMIN)

  // Early exit
  if (loading || userLoading) return <BoardBody loading />
  if (error) {
    return (
      <BoardBody loading>
        <Translate _id='error/loading_users'>
          Er ging iets mis bij het laden van de gebruikers.
        </Translate>
      </BoardBody>
    )
  }

  // Data
  const { course, module, users } = data

  const parent = module || course

  const { currentUserWithRules } = currentUserData

  // Components
  const actions = (
    <>
      {parent && (
        <DashboardLink
          className='btn btn-lg'
          view='link-existing'
          title={`Add users to ${parent.name}`}
        >
          <FontAwesomeIcon icon='link' />
        </DashboardLink>
      )}
      {course && (
        <DashboardLink
          className='btn btn-lg'
          view='course-certificates'
          title={'Manage certificates for this course'}
        >
          <FontAwesomeIcon icon='award' />
        </DashboardLink>
      )}
      {guard({ user: currentUserWithRules, action: 'manage_users' }) && (
        <DownloadCSV query={ADMIN_USERS_CSV} />
      )}
    </>
  )

  // Render
  return (
    <>
      <BoardHead
        title={parent ? parent.name : 'All participants'}
        actions={actions}
      >
        <em>Deelnemers</em>
      </BoardHead>
      <BoardBody>
        {users.length <= 0 ? (
          <Translate _id='user/no_users'>Er zijn geen deelnemers</Translate>
        ) : (
          <BoardList>
            {users.map((user, key) => {
              return (
                <UserItem
                  key={key}
                  user={user}
                  currentUser={currentUserWithRules}
                  queryVars={queryVars}
                  refetch={refetch}
                />
              )
            })}
          </BoardList>
        )}
      </BoardBody>
    </>
  )
}

export default Users
