import { gql } from 'apollo-boost'
import { userParts, imageParts } from '../_shared/fragments'

const CURRENT_USER = gql`
  {
    currentUser {
      ...UserParts
      riziv
      roles
      phone
      profession
      description
      certificates
      language
      courseCertificates {
        path
        course
        valid
      }
      image {
        ...ImageParts
      }
    }
  }
  ${userParts}
  ${imageParts}
`

const CURRENT_USER_WITH_RULES = gql`
  {
    currentUserWithRules {
      ...UserParts
      riziv
      roles
      phone
      profession
      description
      certificates
      courseCertificates {
        path
        course
        valid
      }
      image {
        ...ImageParts
      }
      rules
    }
  }
  ${userParts}
  ${imageParts}
`

export { CURRENT_USER, CURRENT_USER_WITH_RULES }
