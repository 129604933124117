import toolbarImage from './_shared/Form/custom/MarkdownImage'
import stubFalse from 'lodash/stubFalse'

const translatorConfig = {
  languages: ['nl', 'fr', 'en'],
  language:
    typeof navigator !== 'undefined'
      ? (navigator.language || navigator.userLanguage).split('-')[0] || 'nl'
      : 'nl',
  toolbarImage,
  canEdit: stubFalse
}

export default translatorConfig
