import React from 'react'
import { Alert } from 'reactstrap'

import { Authenticator, ConfirmSignUp } from 'aws-amplify-react'
import { I18n } from 'aws-amplify'

import { Translate, withTranslator } from '@lefapps/translations'

import { Container } from '../_layout/Containers'

import { authDictionary } from './i18n'
import { signUpConfig } from './setup'
import { ConfirmLink } from './CustomComponents'
import config from '../config'
import { render } from '../../_helpers/markdown'
import { Aside, Grid, Main } from '../_layout/Grid'

I18n.putVocabularies(authDictionary)

const CustomConfirmSignUp = ({ authState }) => (
  <>
    {authState === 'confirmSignUp' && (
      <Alert color='secondary'>
        <Translate _id='login/info_registration'>
          Er werd een email verstuurd met de verificatiecode. Kopieer deze code
          in bovenstaand veld om uw registratie af te ronden.
        </Translate>
      </Alert>
    )}
    <ConfirmSignUp />
  </>
)

const authenticatorProps = {
  usernameAttributes: 'email',
  signUpConfig,
  children: [<CustomConfirmSignUp override='ConfirmSignUp' />, <ConfirmLink />]
}

export const LoginPage = withTranslator(({ language }) => {
  I18n.setLanguage(language || 'en')

  const getLoginPageCopy = () => {
    if (config && config.copy && config.copy.loginPage) {
      if (language && config.copy.loginPage[language]) {
        return config.copy.loginPage[language]
      } else return config.copy.loginPage.default
    } else return ''
  }
  return (
    <div data-cy='loginForm'>
      <Grid>
        <Main>
          <Container order={1} vertical>
            {render(getLoginPageCopy())}
          </Container>
        </Main>

        <Aside>
          <Container vertical>
            <Authenticator {...authenticatorProps} />
          </Container>
        </Aside>
      </Grid>
    </div>
  )
})

export const LoginMenu = () => {
  return (
    <div data-cy='loginForm'>
      <Authenticator {...authenticatorProps} />
    </div>
  )
}
