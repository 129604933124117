import { Storage } from 'aws-amplify'
import { customPrefix } from '../App/config'

export const sizes = [
  {
    label: 'full',
    info: 'volledige breedte',
    width: 1456,
    height: 2184,
    crop: false,
    retina: true,
    quality: 60
  },
  {
    label: 'main',
    info: 'brede kolom',
    width: 976,
    height: 1464,
    crop: false,
    retina: true,
    quality: 60
  },
  {
    label: 'aside',
    info: 'smalle kolom',
    width: 332,
    height: 498,
    crop: false,
    retina: true,
    quality: 75
  },
  {
    label: 'blocklist',
    info: 'sectie label',
    width: 178,
    height: 89,
    crop: true,
    retina: true,
    quality: 75
  },
  {
    label: 'profile',
    info: 'team',
    width: 180,
    height: 180,
    crop: true,
    retina: true,
    quality: 75
  }
]

const putOptions = {
  ACL: 'public-read',
  cacheControl: 'max-age=31536000',
  metadata: { 'cache-control': 'max-age=31536000' },
  customPrefix
}

export const uploadHandler = {
  send: (file, callback) => {
    Storage.put(file.name, file, putOptions)
      .then(({ key }) => callback(undefined, key))
      .catch(error => callback(error))
  }
}
