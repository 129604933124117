import React from 'react'
import { useAlert } from 'react-alert'
import moment from 'moment'

import { Button } from 'reactstrap'
import { BoardBody, BoardHead } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_USER } from './mutations'
import { ADMIN_USER, LOG } from './queries'
import { ADMIN_ROLES } from '../Roles/queries'

import { SimpleList, SimpleListItem } from '../../_layout/SimpleList'

import { Form } from '../../_shared/Form/index'
// import { formElements as elements } from './FormElements'

import { readableDate } from '../../../_helpers/dates'
import { Fragment } from 'react'

const dateFormat = 'ddd D MMM YYYY (HH:mm)'

// Someone should beautify this
const displaySequence = [
  'courses',
  'sections',
  'classrooms',
  'breakoutrooms',
  'recordings',
  'files',
  'certificates'
]
const DisplayItem = item => {
  return (
    <Fragment>
      <dt>{item.path}</dt>
      <dd>
        <small>
          first visited on{' '}
          <u>{moment(new Date(item.firstSeen)).format(dateFormat)}</u>
        </small>
        <br />
        <small>
          last visited on{' '}
          <u>{moment(new Date(item.lastSeen)).format(dateFormat)}</u>
        </small>
      </dd>
    </Fragment>
  )
}
const TrackingInfo = ({ tracking }) => {
  return (
    <div>
      <h3>
        <Translate _id='user/tracking/title'>
          Tracking of useractivity
        </Translate>
      </h3>
      {displaySequence.map(type => {
        return (
          <span key={type}>
            <h5>{type}</h5>
            <dl>
              {tracking[type] &&
                tracking[type].map((item, i) => (
                  <DisplayItem {...item} key={i} />
                ))}
            </dl>
          </span>
        )
      })}
      <hr />
      <p>
        <Translate _id='user/tracking/info'>
          Voor uitgebreide data uit de modules (incl. resultaten) navigeer naar
          Admin - Modules.
        </Translate>
      </p>
    </div>
  )
}

const User = ({
  match: {
    params: { userId }
  }
}) => {
  const alert = useAlert()

  let _user

  // Queries
  const { loading, error, data } = useQuery(ADMIN_USER, {
    variables: {
      _id: userId
    }
  })
  const { loading: logLoading, data: logData } = useQuery(LOG, {
    variables: {
      user: userId
    }
  })
  const { loading: rolesLoading, data: rolesData } = useQuery(ADMIN_ROLES)

  // Mutations
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: ({ updateUser: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
    // Update cache maybe?
  })

  // Loading data
  if (loading || logLoading || rolesLoading) return <BoardBody loading />
  if (error && userId) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_user'>
          Er ging iets mis bij het laden van deze deelnemer.
        </Translate>
      </BoardBody>
    )
  }

  // edit || fallback
  _user = data.user || {}
  const { tracking } = (logData && logData.log) || []
  const roles = (rolesData && rolesData.roles) || []

  // Always have admin as a role
  let options = ['admin']
  roles.map(role => {
    return options.push(role.name)
  })
  const elements = [
    {
      name: 'roles',
      label: {
        nl: 'Rollen',
        en: 'Roles',
        fr: 'Roles'
      },
      type: 'checkbox-mc',
      layout: {
        col: {
          md: 12
        }
      },
      options
    }
  ]

  // Methods
  const onSubmit = async doc => {
    // Catches problem with backend saving objects where strings are expected
    delete doc['modules']
    delete doc['courses']
    const UpdateUserInput = { _id: userId, doc }
    await updateUser({ variables: { UpdateUserInput } })
  }

  return (
    <div>
      <BoardHead title={_user.name || _user.email || 'Deelnemer'}>
        <em>
          <Translate _id='user/title'>Deelnemer</Translate>
        </em>
      </BoardHead>
      <BoardBody>
        <Form
          data-cy='userForm'
          elements={elements}
          initialModel={_user}
          onSubmit={e => {
            onSubmit(e)
          }}
          key={_user.email || _user.name || userId || 'Deelnemer'}
        >
          <Button type='submit' color='success'>
            <Translate _id='button/save'>Opslaan</Translate>
          </Button>
        </Form>
        <hr />
        <SimpleList>
          <SimpleListItem label='Naam'>
            {_user.name || 'The user has not provided a name'}
          </SimpleListItem>
          <SimpleListItem label='Email'>
            {_user.email || 'The user has not provided an email'}
          </SimpleListItem>
          <SimpleListItem label='Rollen'>
            {_user.roles
              ? _user.roles.length > 0
                ? _user.roles.map(role => {
                    return ` ${role} `
                  })
                : 'User'
              : 'The user has no assigned roles'}
          </SimpleListItem>
          <SimpleListItem label='Added'>
            {readableDate(_user.createdAt)}
          </SimpleListItem>
          {_user.profession ? (
            <SimpleListItem label='Profile'>
              {_user.profession ||
                'This user has not entered profile information'}
            </SimpleListItem>
          ) : null}
        </SimpleList>
        {/* Display tracked activity */}
        <hr />
        {/* TODO: put inside modal */}
        {tracking ? <TrackingInfo tracking={tracking} /> : null}
      </BoardBody>
    </div>
  )
}

export default User
