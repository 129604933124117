export const lefCourse = {
  prefix: 'lef',
  iconName: 'course',
  icon: [
    625,
    625,
    [],
    '',
    'M566.057 539.237l22.893-7.591c21.975-7.291 33.895-30.999 26.642-52.986L502.028 136.335c-7.607-21.759-31.244-33.412-53.136-26.195l-85.135 28.279c-.453.15-.895.318-1.339.482v-32.035c0-23.181-18.792-41.972-41.973-41.972H218.642c-23.181 0-41.972 18.792-41.972 41.972v52.932c-7.393-11.983-20.617-19.985-35.721-20.039H64c-23.123.082-41.823 18.85-41.823 41.972v352c.02 1.869.174 3.705.432 5.507H7.442v36.019h625.117v-36.019h-66.502zm-115.378-5.507a42.093 42.093 0 002.349 5.507h-91.059c.263-1.802.424-3.638.45-5.507V267.678l88.26 266.052z'
  ]
}

export const lefSection = {
  prefix: 'lef',
  iconName: 'section',
  icon: [
    618,
    720,
    [],
    '',
    'M591.436 454.076c-39.426-1.954-70.951-33.479-72.904-72.904-2.1-42.363 30.541-78.408 72.904-80.507h39.53l-.378-156.1c0-10.396-8.428-18.823-18.823-18.823H455.153V86.023c-2.1-42.363-38.144-75.004-80.507-72.905-39.427 1.954-70.952 33.478-72.905 72.905v40.282H144.565c-10.396 0-18.824 8.428-18.824 18.824v156.047H86.023c-42.363 2.1-75.004 38.144-72.905 80.507 1.954 39.427 33.478 70.951 72.905 72.904h40.282v157.177c0 10.396 8.428 18.823 18.824 18.823h157.958v-39.282c1.954-39.427 33.479-70.951 72.905-72.905 42.363-2.099 78.407 30.542 80.507 72.905v39.282h156.395c10.396 0 18.823-8.428 18.823-18.823l-.382-157.688h-39.899z'
  ]
}

export const lefModule = {
  prefix: 'lef',
  iconName: 'module',
  icon: [
    640,
    640,
    [],
    '',
    'M565.628 610.428c-15.502-.932-27.61-13.749-27.66-29.278-.155-14.721 10.593-27.291 25.159-29.425a22.378 22.378 0 003.678-11.918V22.069C566.805 9.881 556.924 0 544.735 0H134.841C100.96-.407 73.164 26.729 72.757 60.61l-.003.3v507.145a75.905 75.905 0 000 19.274C77.262 618 103.846 640.552 134.841 640h410.483a21.923 21.923 0 0020.304-13.683 21.189 21.189 0 000-15.889zm-82.406-14.292l-348.38-.273c-9.413.488-17.44-6.746-17.929-16.159-.01-.204-.018-.409-.021-.613v-5.297a17.214 17.214 0 0116.478-11.182h352.326a29.3 29.3 0 00-6.579 18.538 29.27 29.27 0 004.105 14.986z'
  ]
}

export const lefPage = {
  prefix: 'lef',
  iconName: 'page',
  icon: [
    625,
    625,
    [],
    '',
    'M476.279 99.87C406.921 99.87 320 140.949 320 190.363c0-49.414-86.921-90.493-156.279-90.493-69.358 0-156.279 41.079-156.279 90.493v357.209c0-47.33 87.665-89.303 156.279-89.303 68.614 0 156.279 47.182 156.279 89.303 0-47.33 87.665-89.303 156.279-89.303 68.613 0 156.279 47.182 156.279 89.303V190.363c.001-49.414-86.921-90.493-156.279-90.493z'
  ]
}

export const lefFile = {
  prefix: 'lef',
  iconName: 'file',
  icon: [
    493,
    720,
    [],
    '',
    'M467.055 182.91c-41.148 0-74.505-33.357-74.505-74.505V0H132.34C99.989.202 73.785 26.323 73.481 58.673v522.654c.304 32.35 26.508 58.471 58.859 58.673h375.507c32.361-.103 58.57-26.312 58.673-58.673V182.91h-99.465m.558-33.899h75.81L427.381 27.008v81.77c.397 22.053 18.18 39.835 40.232 40.233z'
  ]
}
