import React from 'react'
import omit from 'lodash/omit'
import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem,
  DashboardLink
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import cloneDeep from 'lodash/cloneDeep'

import { useQuery } from '@apollo/react-hooks'
import { ADMIN_USERS } from './queries'

import { LinkItem } from '../_shared/LinkItem'

const LinkUsers = ({
  match: {
    params: { coursePath, modulePath }
  }
}) => {
  const queryVars = {
    course: coursePath,
    module: modulePath,
    getAccess: (modulePath && 'module') || (coursePath && 'course')
  }

  const updateClientQuery = mutationResult => (cache, result) => {
    const clientData = cache.readQuery({
      query: ADMIN_USERS,
      variables: queryVars
    })
    const updatedUser = result.data[mutationResult].user
    if (mutationResult === 'addItemToUser') {
      clientData.users = [...clientData.users, updatedUser]
    }
    if (mutationResult === 'removeItemFromUser') {
      const data = cloneDeep(clientData)
      data.users = data.courses.filter(({ _id }) => _id !== updatedUser._id)
      clientData.users = data.users
    }
    cache.writeQuery({ query: ADMIN_USERS, clientData })
  }

  const { loading, error, data } = useQuery(ADMIN_USERS, {
    variables: omit(queryVars, ['getAccess'])
  })

  if (loading) {
    return <BoardBody loading />
  }
  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading'>
          Er ging iets mis bij het laden
        </Translate>
      </BoardBody>
    )
  }

  const { users, course, module } = data

  const parentType = (module && 'module') || (course && 'course')

  const User = ({ options = {}, index, ...user }) => (
    <BoardListItem label={index} actions={options.actions}>
      <DashboardLink view={user._id}>{user.name}</DashboardLink>
    </BoardListItem>
  )

  return (
    <>
      <BoardHead title={data[parentType].name}>
        <em>
          <Translate _id='linkUsers/title'>
            Bestaande deelnemers toevoegen
          </Translate>
        </em>
      </BoardHead>
      <BoardBody>
        {users.length <= 0 ? (
          <Translate _id='linkUsers/no_users'>
            Er zijn geen gebruikers
          </Translate>
        ) : (
          <BoardList>
            {users.map((user, i) => {
              return (
                <LinkItem
                  key={i}
                  item={user}
                  itemType={'user'}
                  parent={data[parentType]}
                  parentType={parentType}
                  onUpdate={updateClientQuery}
                  ItemComponent={User}
                />
              )
            })}
          </BoardList>
        )}
      </BoardBody>
    </>
  )
}

export default LinkUsers
