import { gql } from 'apollo-boost'

const parent = `{
  ... on Course {
    path
    sections {
      path
    }
    modules {
      path
    }
    users {
      _id
    }
    files {
      path
    }
  }
  ... on Section {
    path
    modules {
      path
    }
    files {
      path
    }
  }
  ... on Module {
    path
    pages {
      path
    }
    users {
      _id
    }
  }
}
`

const ADD_ITEM_TO_PARENT = gql`
  mutation AddItem(
    $itemType: String!
    $itemPath: String!
    $parentType: String!
    $parentPath: String!
  ) {
    addItemToParent(
      itemType: $itemType
      itemPath: $itemPath
      parentType: $parentType
      parentPath: $parentPath
    ) {
      success
      message
      parent ${parent}
    }
  }
`

const REMOVE_ITEM_FROM_PARENT = gql`
  mutation RemoveItem(
    $itemType: String!
    $itemPath: String!
    $parentType: String!
    $parentPath: String!
  ) {
    removeItemFromParent(
      itemType: $itemType
      itemPath: $itemPath
      parentType: $parentType
      parentPath: $parentPath
    ) {
      success
      message
      parent ${parent}
    }
  }
`

const UPDATE_LOG = gql`
  mutation UpdateLog($UpdateLogInput: UpdateLogInput!) {
    updateLog(log: $UpdateLogInput) {
      success
      message
    }
  }
`

export { ADD_ITEM_TO_PARENT, REMOVE_ITEM_FROM_PARENT, UPDATE_LOG }
