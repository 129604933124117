import React from 'react'
import { Helmet } from 'react-helmet'

import config from '../config'

import { render } from '../../_helpers/markdown'

import { Grid, Main, Aside } from '../_layout/Grid'
import { Container } from '../_layout/Containers'

import { LoginMenu, LoginPage } from '../Login'

const { copy = {}, name = '' } = config

const getContent = {
  main: copy.welcome || '',
  gdpr: copy.privacy || ''
}

export const Info = ({
  match: {
    params: { path = 'main' }
  }
}) => {
  const content = getContent[path]
  const title = path === 'main' ? name : path

  if (!content) return <LoginPage />

  return (
    <Grid collapse={'tablet'}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Main>
        <Container>
          <h1>{title}</h1>
          {render(content)}
        </Container>
      </Main>
      <Aside>
        <Container>
          <LoginMenu />
        </Container>
      </Aside>
    </Grid>
  )
}
