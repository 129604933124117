import React from 'react'

import { useAlert } from 'react-alert'

import { Button } from 'reactstrap'
import { BoardBody, BoardHead } from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { CREATE_ROLE, UPDATE_ROLE } from './mutations'
import { ADMIN_ROLE, ADMIN_ROLES } from './queries'

import { Form } from '../../_shared/Form/index'
import { formElements as elements } from './FormElements'

const Role = ({
  match: {
    params: { roleName }
  },
  location,
  history
}) => {
  const alert = useAlert()

  let _role

  // Queries
  const { loading, error, data } = useQuery(ADMIN_ROLE, {
    variables: { name: roleName },
    skip: !roleName
  })

  // Mutations
  const [updateRole] = useMutation(UPDATE_ROLE, {
    onCompleted: ({ updateRole: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
  })

  const [createRole] = useMutation(CREATE_ROLE, {
    onCompleted: ({ createRole: { success, message, role } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
      // Reload form after creating new role to show edit form
      history.push(`/admin/roles/${role.name}`)
    },
    update: (
      cache,
      {
        data: {
          createRole: { role }
        }
      }
    ) => {
      const clientData = cache.readQuery({
        query: ADMIN_ROLES
      })
      clientData.roles = [...clientData.roles, role]
      cache.writeQuery({ query: ADMIN_ROLES, data: clientData })
    }
  })

  // Loading data
  if (loading) return <BoardBody loading />
  if (error && roleName) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading_role'>
          Er ging iets mis bij het laden van deze rol.
        </Translate>
      </BoardBody>
    )
  }

  // edit || duplicate || create
  _role =
    (data && data.role) ||
    (location && location.state && location.state.initialModel) ||
    {}

  // Methods
  const onSubmit = async doc => {
    if (_role.name && roleName) {
      // if !roleName it's a new duplicate, skip update
      const UpdateRoleInput = { name: _role.name, doc }
      await updateRole({ variables: { UpdateRoleInput } })
    } else {
      delete doc._id // Delete _id on duplicate role
      await createRole({ variables: { RoleInput: doc } })
    }
  }

  return (
    <>
      <BoardHead title={(_role && _role.name) || 'Nieuwe rol'}>
        <em>
          <Translate _id='role/title'>Rol</Translate>
        </em>
      </BoardHead>
      <BoardBody>
        <Form
          data-cy='roleForm'
          elements={elements}
          initialModel={_role}
          onSubmit={e => {
            onSubmit(e)
          }}
          key={roleName}
        >
          <Button type='submit' color='success'>
            <Translate _id='button/save'>Opslaan</Translate>
          </Button>
        </Form>
      </BoardBody>
    </>
  )
}

export default Role
