import { sortBy } from 'lodash'

export const createTimestamps = timestamps => {
  timestamps.map(item => {
    const timestampInSeconds =
      (item.hh ? item.hh * 60 * 60 : 0) +
      (item.mm ? item.mm * 60 : 0) +
      (item.ss ? Number(item.ss) : 0)
    item.timestampInSeconds = timestampInSeconds
    return item
  })
  return timestamps
}

export const sortTimestamps = timestamps => {
  return sortBy(timestamps, ['timestampInSeconds'])
}
