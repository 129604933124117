import React from 'react'
import { Helmet } from 'react-helmet'
import { useAlert } from 'react-alert'

import { Translate } from '@lefapps/translations'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { CURRENT_USER } from './queries'
import { UPDATE_USER } from './mutations'

import { Container, Heading } from '../_layout/Containers'
import { Grid, Main } from '../_layout/Grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loading } from '../_shared/Loading'

const Certificates = () => {
  const alert = useAlert()

  // Queries
  const { loading, error, data } = useQuery(CURRENT_USER)

  // Mutations
  useMutation(UPDATE_USER, {
    onCompleted: ({ updateUser: { success, message } }) => {
      alert.show(message, { type: success ? 'success' : 'danger' })
    }
  })

  // Early exit
  if (loading) return <Loading />
  if (error) {
    console.error('Error loading user')
    return (
      <Loading color={'danger'}>
        <Translate _id='error/loading_profile'>
          Er ging iets mis bij het laden van je certificaten.
        </Translate>
      </Loading>
    )
  }

  const user = data.currentUser

  // Render
  return (
    <>
      <Helmet>
        <title>{user.name}</title>
      </Helmet>
      <Heading>
        <h1>
          <FontAwesomeIcon icon='user' /> {user.name}
        </h1>
      </Heading>
      <Grid collapse={'tablet'}>
        <Main>
          <Container>
            <h2>
              <Translate _id='profile/certificates/title'>
                Your certificates
              </Translate>
            </h2>
            {user.courseCertificates && user.courseCertificates.length ? (
              <ul>
                {user.courseCertificates.map(certif => {
                  return (
                    <li>
                      <a
                        href={certif.path}
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        {certif.path}
                      </a>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <p>
                <Translate _id='profile/no_certificates'>
                  You have no certificates
                </Translate>
              </p>
            )}
          </Container>
        </Main>
      </Grid>
    </>
  )
}

export default Certificates
