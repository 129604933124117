import { gql } from 'apollo-boost'
import { userParts } from '../_shared/fragments'

const ADMIN_USER = gql`
  query User($_id: String!) {
    user(_id: $_id) {
      ...UserParts
    }
  }
  ${userParts}
`

const ADMIN_USERS = gql`
  query Users($course: String, $module: String, $getAccess: String) {
    users(course: $course, module: $module, getAccess: $getAccess) {
      _id
      email
      name
      roles
      courses
      modules
    }
    course(path: $course) {
      path
      name
      owners {
        _id
      }
      users {
        _id
        email
        name
      }
    }
    module(path: $module) {
      path
      name
      owners {
        _id
      }
      users {
        _id
        email
        name
      }
    }
  }
`

const ADMIN_USERS_CSV = gql`
  query DownloadUsers {
    usersCSV
  }
`

const LOG = gql`
  query Log($user: String) {
    log(user: $user) {
      user
      tracking
    }
  }
`

const CURRENT_USER_ADMIN = gql`
  {
    currentUserWithRules {
      _id
      rules
      roles
    }
  }
`

export { ADMIN_USER, ADMIN_USERS, ADMIN_USERS_CSV, LOG, CURRENT_USER_ADMIN }
