import React from 'react'

import { Translate } from '@lefapps/translations'

import { Container } from '../_layout/Containers'
import { Grid, Main, Aside } from '../_layout/Grid'
import { Icon } from '../_layout/Bits'

import { SideBar } from './SideBar'
import SectionList from './Section/SectionList'
import { ModuleList } from '../Module/Modules'
import { FileList } from './Files'

export const View = ({ name, sections, modules, files, ...props }) => {
  return (
    <div className='courseview'>
      <Grid collapse={'tablet'}>
        <Main>
          {sections && !!sections.length && (
            <Container order={0} vertical>
              <h2 className='float-center'>
                <Icon icon={'section'} />
                <Translate _id='course/view/sections'>Secties</Translate>
              </h2>
              <div className='sectionlist'>
                <SectionList sections={sections} />
              </div>
            </Container>
          )}
          {(modules && !!modules.length && (
            <Container order={1} vertical>
              <h2>
                <Icon icon={'module'} />
                Modules
              </h2>
              <ModuleList modules={modules} small />
            </Container>
          )) ||
            null}
          {files && !!files.length && (
            <Container order={2} vertical>
              <h2>
                <Icon icon={'file'} />
                <Translate _id='course/view/files'>Documenten</Translate>
              </h2>
              <FileList files={files} compact />
            </Container>
          )}
        </Main>
        <Aside>
          <SideBar {...props} />
        </Aside>
      </Grid>
    </div>
  )
}
