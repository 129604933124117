import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BoardBody,
  BoardHead,
  BoardList,
  BoardListItem,
  DashboardLink
} from '@lefapps/admin-dashboard'
import { Translate } from '@lefapps/translations'

import { useQuery } from '@apollo/react-hooks'
import { ADMIN_USERS } from './queries'

import { LinkItem } from '../_shared/LinkItem'

const LinkTeachers = ({
  match: {
    params: { coursePath, modulePath }
  }
}) => {
  const queryVars = {
    course: coursePath,
    module: modulePath
  }

  const updateClientQuery = () => {
    refetch() // TODO: rewrite cache instead of refetching query
  }

  // loads all users
  const { loading, error, data, refetch } = useQuery(ADMIN_USERS, {
    variables: queryVars
  })

  if (loading) {
    return <BoardBody loading />
  }
  if (error) {
    console.error(error)
    return (
      <BoardBody loading>
        <Translate _id='error/loading'>
          Er ging iets mis bij het laden
        </Translate>
      </BoardBody>
    )
  }

  const { users, course, module } = data

  // TODO: improve loading times by doing something like ADMIN_TEACHERS
  let teachers = []
  users.map(user => {
    if (user.roles.includes('teacher')) return teachers.push(user)
    else return null
  })

  const parentType = (module && 'module') || (course && 'course')

  const Teacher = ({ options = {}, index, ...teacher }) => (
    <BoardListItem label={index} actions={options.actions}>
      <DashboardLink view={teacher._id}>{teacher.name}</DashboardLink>
    </BoardListItem>
  )

  const actions = (
    <>
      {(module || course) && (
        <DashboardLink
          className='btn btn-lg'
          view='link-teachers'
          title={`Assign administrators to ${(module && module.name) ||
            (course && course.name)}`}
        >
          <FontAwesomeIcon icon='link' />
        </DashboardLink>
      )}
    </>
  )

  return (
    <>
      <BoardHead title={data[parentType].name} actions={actions}>
        <em>
          <Translate _id='linkTeachers/title'>
            Beheerders koppelen/ontkoppelen
          </Translate>
        </em>
      </BoardHead>
      <BoardBody>
        {teachers.length <= 0 ? (
          <Translate _id='linkTeachers/no_teachers'>
            Er zijn geen beheerders op het platform
          </Translate>
        ) : (
          <BoardList>
            {teachers.map((teacher, i) => {
              return (
                <LinkItem
                  key={i}
                  item={teacher}
                  itemType={'teacher'}
                  parent={data[parentType]}
                  parentType={parentType}
                  onUpdate={updateClientQuery}
                  ItemComponent={Teacher}
                />
              )
            })}
          </BoardList>
        )}
      </BoardBody>
    </>
  )
}

export default LinkTeachers
